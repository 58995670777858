'use strict'

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Layout from '../layout/index'
import Button from 'material/src/button'

import iconPlay from '../icon/player/play.svg'
import iconPause from '../icon/player/pause.svg'

const defaults = {
  class: 'play',
  layout: [
    [Button, 'play', { icon: iconPlay }],
    [Button, 'pause', { icon: iconPause }]
  ],
  events: [
    ['ui.play.click', 'play'],
    ['ui.pause.click', 'pause']
  ]
}

/**
 * Base class for all ui components
 * @class
 * @param {Object} options - The component options
 * @return {Object} The class Instance
 */

/**
 * Class representing a UI Container. Can add components.
 *
 * @extends Component
 * @return {parent} The class instance
 * @example new Container({
 *   container: document.body
 * });
 */
class Play {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach)

    this.build()
    this.attach()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement('div')
    this.element.classList.add(this.options.class)

    this.layout = new Layout(this.options.layout, this.element)
    this.ui = this.layout.component

    this.ui.pause.element.classList.add('hide')

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    return this
  }

  play () {
    this.show('paused')
    this.emit('play')
  }

  pause () {
    this.show('play')
    this.emit('pause')
  }

  show (mode) {
    if (mode === 'play') {
      this.ui.play.element.classList.remove('hide')
      this.ui.pause.element.classList.add('hide')
    } else {
      this.ui.play.element.classList.add('hide')
      this.ui.pause.element.classList.remove('hide')
    }
  }
}

export default Play

// import components

import Element from 'material/src/element'
import Button from 'material/src/button'
import Drawer from 'material/src/drawer'

import Islands from '../menu/islands'
// import Inbox from '../control/inbox'

// appbar icons
import iconNavi from '../icon/navi.svg'
// import iconSide from './icon/side.svg'

// navi icons
import iconOnAir from '../icon/onair.svg'
import iconPosted from '../icon/inbox.svg'
import iconValidated from '../icon/check.svg'
import iconConfirmation from '../icon/confirmation.svg'
import iconEdit from '../icon/edit.svg'
import iconBroken from '../icon/problem.svg'
import iconSalvaged from '../icon/salvaged.svg'
import iconRejected from '../icon/close.svg'
import iconUploaded from '../icon/folder.svg'
import iconDeleted from '../icon/trash.svg'
import iconIsland from '../icon/islands.svg'
import iconFlower from '../icon/flower.svg'

import List from './list'
import Form from '../form/track'

const TITLE = 'Tracks'

/**
 * Application Layout
 */
export default [
  [Element, { class: 'appbar', color: 'primary' },
    [Button, 'menu-navi', { size: 'small', class: 'navi', icon: iconNavi, style: 'action' }],
    [Element, 'title', { class: 'title', text: TITLE }],
    [Element, 'divider', { class: 'divider' }]
  ],
  [Drawer, 'navi', { css: 'drawer-persistent', type: 'persistent', open: true },
    [Element, { class: 'group workflow' },
      [Button, 'onair', { icon: iconOnAir, label: 'On Air', name: 'onair', title: 'On Air' }],
      [Element, { class: 'divider' }],
      [Button, 'posted', { icon: iconPosted, label: 'Inbox', name: 'posted', title: 'Inbox' }],
      // [Element, { class: 'group inbox' },
      [Button, 'validated', { icon: iconValidated, label: 'Validated', name: 'validated', title: 'Validated' }],
      [Button, 'confirmation', { icon: iconConfirmation, label: 'Confirmation', name: 'confirmation', title: 'Need Confirmation' }],
      [Button, 'rework', { icon: iconEdit, label: 'Rework', name: 'rework', title: 'Need Rework' }],
      [Button, 'duplicate', { icon: iconNavi, label: 'Duplicates', name: 'duplicate', title: 'Duplicate Tracks' }],
      [Button, 'rejected', { icon: iconRejected, label: 'Rejected', name: 'rejected', title: 'Rejected' }],
      [Element, { class: 'divider' }],
      [Button, 'broken', { icon: iconBroken, label: 'Unplayable', name: 'broken', title: 'Unplayable' }],
      [Button, 'salvaged', { icon: iconSalvaged, label: 'Salvaged', name: 'salvaged', title: 'Salvaged' }],
      [Button, 'allduplicates', { icon: iconBroken, label: 'All Duplicates', name: 'allduplicates', title: 'All duplicates' }],
      [Button, 'deleted', { icon: iconDeleted, label: 'Deleted', name: 'deleted', title: 'Deleted' }],
      [Element, { class: 'divider' }],
      [Button, 'uploaded', { icon: iconUploaded, label: 'Uploads', name: 'uploaded', title: 'Uploaded' }],
      [Element, { class: 'divider' }],
      [Button, 'island', { icon: iconIsland, label: 'Island Only', name: 'island', title: 'Island Only' }],
      [Button, 'listties', { icon: iconFlower, label: 'List\'ties', name: 'listties', title: 'ListTies' }],
      [Button, 'correction', { icon: iconBroken, label: 'Correction', name: 'correction', title: 'Correction' }]

      // ]
    ]
    // [Islands, 'islands', { }]
  ],
  [List, 'list', { }],
  [Form, 'form', { }]
]

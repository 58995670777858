import Layout from '../layout/index'

export default {

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    // console.log('build', this.options.class, this.options.layout)
    this.container = this.options.container

    this.layout = new Layout(this.options.layout, this.container)
    this.ui = this.layout.component
  }
}

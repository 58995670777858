// base class
import EventEmitter from 'material/src/mixin/emitter'
// modules
import mediator from 'material/src/module/mediator'
import attach from 'material/src/module/attach'
import build from 'material/src/view/build'
import display from 'material/src/view/display'
// components
import Element from 'material/src/element'
import Button from 'material/src/button'
import Tabview from 'material/src/tabview'
// views
import Tracks from '../view/tracks'
import Liked from '../list/tracks'
import Awards from '../list/awards'
import Activity from '../list/activity'

class Contributor extends EventEmitter {
  static defaults = {
    base: 'view',
    class: 'contributor',
    tag: 'div',
    path: '/asset/image/user/medium/',
    isOpen: false,
    views: ['uploaded', 'awards'],
    format: 'small',
    layout: [
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title', text: 'Profile' }],
        [Element, 'divider', { class: 'divider' }]
      ],
      [Tabview, 'tabview', { class: 'body' },
        [Element, 'tabs', { class: 'tabs' },
          [Button, { class: 'tracks', text: 'tracks', title: 'Tracks', data: { view: 'tracks' } }],
          [Button, { class: 'liked', text: 'likes', title: 'Liked', data: { view: 'likes' } }],
          [Button, { class: 'awards', text: 'Awards', title: 'Awards', data: { view: 'awards' } }],
          [Button, { class: 'activity', text: 'Activity', title: 'activity', data: { view: 'activity' } }]
        ],
        [Element, 'indicator', { class: 'indicator' }],
        [Element, 'views', { class: 'views' },
          [Tracks, 'tracks', { class: 'upload', data: { view: 'tracks' } }],
          [Liked, 'likes', { route: { list: '/profile/likes/' }, title: 'Likes', data: { view: 'likes' } }],
          [Awards, 'awards', { class: 'awards', data: { view: 'awards' } }],
          [Activity, 'activity', { data: { view: 'activity' } }]
        ]
      ]
    ],
    events: [
      ['ui.tabview.ready', 'setup'],
      ['ui.summary.click', 'toggle'],
      ['ui.close.click', 'toggle'],
      ['ui.tabview.select', 'select']
    ]
  }

  constructor (options) {
    super()

    this.init(options)
    this.build()
    this.attach()
  }

  init (options) {
    this.options = Object.assign({}, Contributor.defaults, options || {})
    Object.assign(this, attach, build, display)

    console.log('this', this)

    mediator.installTo(this)

    this.subscribe('authenticated', (user) => {
      if (user) {
        this.currentProfileId = user.id
      }
    })

    if (this.options.player === 'sync') {
      this.subscribe('playerStart', this.set)
    }
  }

  setup () {
    // console.log('setup', this.ui.tabview)
    this.view = {}
    const views = this.ui.tabview.views

    for (let i = 0; i < views.length; i++) {
      // console.log('views', i, views[i])
      this.view[views[i]] = this.ui[views[i]]
    }

    this.current = this.view[views[0]]

    this.emit('ready')
  }

  select (view) {
    if (!this.view) return
    // console.log('select', view, this.view[view])
    //

    this.current = this.view[view]

    if (view === 'activity') {
      this.current.set(this.user)
    } else {
      this.current.set(this.id)
    }
  }

  update () {
    // console.log('update', this.id, this.current)

    if (this.current && this.current.set) {
      if (this.current.options.class === 'activity') {
        this.current.set(this.user)
      } else {
        this.current.set(this.id)
      }
    }
  }

  set (user) {
    // console.log('---- set', user)
    if (!user) return
    if (!user._id || this.id === user.user_id) return

    this.user = user
    this.id = user._id
    this.user_id = user.user_id

    this.update()
  }

  click (e) {
    e.stopPropagation()
  }
}

export default Contributor

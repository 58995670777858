import { create } from 'material/src/module/layout'

/**
 * Layout class for creating and managing UI components.
 */
class Layout {
  /**
   * Constructs a new Layout instance.
   *
   * @param {Array} schema - An array defining the structure and components of the layout.
   * @param {HTMLElement} container - The container in which the layout components are placed.
   * @param {boolean} [enableMemoization=false] - Flag to enable memoization (not implemented in current version).
   */
  constructor (schema, container) {
    this.components = []
    // const startTime = Date.now()
    this.layout = create(schema, container)

    // const endTime = Date.now()
    // console.log(`Execution time: ${endTime - startTime} ms`)
    return this.layout
  }
}

export default Layout

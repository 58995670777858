// module
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

// view
import display from 'material/src/mixin/display'

// ui
import Layout from '../layout/index'
import Element from 'material/src/element'
import Button from 'material/src/button'
import TabView from 'material/src/tabview'

// views
import Contact from '../form/contact'
import Profile from '../form/profile'
import User from '../form/user'
import Subscription from '../list/subscription'

const defaults = {
  class: 'account',
  tag: 'div',
  layout: [
    [Element, 'head', { class: 'head' },
      [Element, 'title', { class: 'title', text: 'Account' }],
      [Element, { class: 'divider' }],
      [Button, 'close', { type: 'action' }]
    ],
    [Element, 'body', { class: 'body' },
      // [Element, 'title', { tag: 'h3', class: 'title', text: 'User Information', color: 'primary' }],
      [TabView, 'tabview', { },
        [Element, 'tabs', { class: 'tabs' },
          [Button, 'user-tab', { text: 'User', data: { view: 'user' } }],
          [Button, 'profile-tab', { text: 'Profile', data: { view: 'profile' } }],
          [Button, 'contact-tab', { text: 'Contact', data: { view: 'contact' } }],
          [Button, 'subscription-tab', { text: 'Subscription', data: { view: 'subscription' } }]
        ],
        [Element, 'indicator', { class: 'indicator' }],
        [Element, 'views', { class: 'views' },
          [User, 'user', { data: { view: 'user' } }],
          [Profile, 'profile', { data: { view: 'profile' } }],
          [Contact, 'contact', { data: { view: 'contact' } }],
          [Subscription, 'subscription', { data: { view: 'subscription' } }]

        ]
      ]
    ]
  ],
  events: [
    ['ui.tabview.select', 'select'],
    ['ui.user.updated', 'updated'],
    ['ui.profile.updated', 'updated'],
    ['ui.contact.updated', 'updated']
  ]
}

class Forms {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach, display)

    this.build()
    this.setup()
    this.attach()
    // this.set(options)
    // console.log(map)

    return this
  }

  build () {
    // console.log('build', this.options.layout)
    this.element = document.createElement('div')
    this.element.classList.add('view')

    if (this.options.class !== 'view') {
      this.element.classList.add(this.options.class)
    }

    this.layout = new Layout(this.options.layout, this.element)
    this.ui = this.layout.component

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }
  }

  setup () {
    // console.log('setup', this.ui.tabview.view)
    this.view = this.ui.tabview.view
    this.views = this.ui.tabview.views
  }

  select (view) {
    // console.log('view', view, this.user)

    this.view = this.ui[view]

    if (!this.user) return

    if (this.ui[view].set) {
      this.ui[view].set(this.user)
    }
  }

  updated (data) {
    // console.log('updated', data)

    const info = {}

    if (data.user_id) {
      info._id = data.user_id
    } else {
      info._id = data._id
    }

    // console.log('info', info)
    this.emit('updated', info)
  }

  set (user) {
    // console.log('set', user)

    this.user = user

    this.view.set(user)
  }
}

export default Forms

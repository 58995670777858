// base class
import EventEmitter from 'material/src/mixin/emitter'
// modules
import attach from 'material/src/module/attach'
import build from 'material/src/mixin/build'
import display from 'material/src/mixin/display'
import render from 'material/src/list/render'
import Layout from '../layout/index'
// components
import Element from 'material/src/element'
import Button from 'material/src/button'
import Text from 'material/src/text'
import List from '../list/list'
import Link from 'material/src/link'
import DateHour from 'material/src/datehour'
import Image from '../field/image'
import Email from '../field/email'
import Country from '../field/country'
// import Ranking from '../field/ranking'

import iconClose from '../icon/close.svg'
// import hotkeys from 'hotkeys-js'

class Helper extends EventEmitter {
  static defaults = {
    base: 'view',
    class: 'list meta track helper',
    tag: 'div',
    pagination: true,
    // debug: true,
    list: {
      size: 25,
      page: 1
    },
    loading: 'dynamic',
    virtual: true,
    store: true,
    route: {
      list: '/track/rework',
      count: '/activity/count',
      search: '/activity'
    },
    image: {
      format: 'thumb/'
    },
    fields: ['pseudonym', 'username', 'enabled', 'created'],
    layout: [
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title', text: 'Helper' }],
        [Element, 'divider', { class: 'divider' }],
        [Button, 'close', { icon: iconClose, style: 'action', size: 'small' }]
      ],
      [Element, 'body', { class: 'body' }],
      [Element, 'foot', { class: 'foot' },
        [Element, 'count', { class: 'count' }]
      ]
    ],
    item: {
      shazam: [
        [Element, { class: 'head' },
          [Element, { class: 'source', text: 'Shazam' }],
          [Link, 'info.url', { text: 'link', class: 'link', target: '_blank' }],
          [Element, { class: 'separator' }],
          [Element, { class: 'country', text: 'ISRC:' }],
          [Element, 'info.isrc', { class: 'year' }]
        ],
        [Element, 'body', { class: 'body' },
          [Element, 'info.image', { tag: 'img', class: 'image' }],
          [Element, 'content', { class: 'content' },
            [Element, { class: 'group' },
              [Element, 'info.title', { class: 'title' }]
            ],
            [Element, { class: 'group' },
              [Element, 'info.artist', { class: 'artist' }],
              [Element, { class: 'separator', text: ' — ' }],
              [Element, 'info.album', { class: 'album' }]
            ]
          ]
        ]
      ],
      discogs: [
        [Element, { class: 'head' },
          [Element, { class: 'source', text: 'Discogs' }],
          [Link, 'info.url', { text: 'link', class: 'link', target: '_blank' }],
          [Element, { class: 'separator' }],
          [Country, 'info.country', { class: 'country' }],
          [Text, 'info.year', { class: 'year' }]
        ],
        [Element, 'body', { class: 'body' },
          [Element, 'info.image', { tag: 'img', class: 'image' }],
          [Element, 'content', { class: 'content' },
            [Element, { class: 'group' },
              [Element, 'info.artist', { class: 'artist' }],
              [Element, { class: 'separator', text: ' — ' }],
              [Element, 'info.album', { class: 'album' }]
            ],
            [Element, { class: '' },
              [Text, 'label', { class: 'label', text: 'Label: ' }],
              [List, 'info.label', { class: 'infolabel' }]

            ],
            [Element, { class: '' },
              [Text, 'label', { class: 'label', text: 'Styles: ' }],
              [List, 'info.style', { class: 'infostyles' }]
            ]
          ]
        ]
      ]
    },
    events: [
      ['ui.close.click', 'close']
      // ['ui.search-input.input', 'search'],
      // ['ui.load.click', 'load'],
      // ['ui.body.click', 'click']
    ]
  }

  constructor (options) {
    super()

    this.init(options)
    this.build()
    this.buildList()
    this.attach()

    if (this.options.id) {
      this.set(this.options.id)
    }
  }

  init (options) {
    // console.log('init', this.options)
    this.options = Object.assign({}, Helper.defaults, options || {})
    Object.assign(this, attach, build, display, render)

    this.page = this.options.page
  }

  buildList () {
    // console.log('buildlist')
  }

  set (id) {
    // console.log('set', id)
    this.fetch(id)
  }

  fetch (id) {
    // console.log('fetch', id)
    fetch('/track/rework/' + id, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }

    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((list) => {
      // console.log('list', list)
      this.renderList(list)
    })
  }

  renderList (list) {
    // console.log('renderList', list)
    this.ui.body.innerHTML = ''

    for (let i = 0; i < list.length; i++) {
      const track = list[i]

      if (track.shazam) {
        this.renderShazam(track.shazam)
      }

      if (track.discogs) {
        this.renderDiscogs(track.discogs)
      }
    }
  }

  renderDiscogs (discogs) {
    for (let i = 0; i < discogs.results.length; i++) {
      const info = discogs.results[i]
      // console.log('renderDiscogs', info)
      if (info.uri) {
        info.url = 'https://www.discogs.com' + info.uri
      }

      const title = info.title.split('-')
      info.album = title[1]
      info.artist = title[0]

      this.renderElement(info, 'discogs')
    }
  }

  renderShazam (info) {
    // console.log('renderShazam', info)
    this.renderElement(info, 'shazam')
  }

  /**
   * [add description]
   * @param {[type]} info    [description]
   * @param {[type]} context [description]
   */
  renderElement (info, type) {
    // console.log('renderItem', info)
    info = info || {}

    const id = this.options.dataId || '_id'

    const container = this.ui.body

    const element = new Element({
      tag: 'li',
      class: 'item'
    })

    let layout = null

    console.log('layout', element, type, this.options.item[type])

    layout = new Layout(this.options.item[type], element)

    console.log('layout', layout, info)

    this.renderInfo(layout, info)

    container.appendChild(element)

    if (info.image && info.image.cover) {
      // console.log('url cover', info.image.cover)
      layout.component['info.image'].src = info.image.cover
    }

    if (info.cover_image) {
      // console.log('url cover', info.cover_image)
      layout.component['info.image'].src = info.cover_image
    }

    return element
  }

  close () {
    // console.log('close')
    this.emit('close')
  }
}

export default Helper

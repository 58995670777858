
function abbrNum (n, d) {
  // console.log('abbrNum', n, d)
  // 2 decimal places => 100, 3 => 1000, etc
  d = Math.pow(10, d)

  // Enumerate n abbreviations
  const abbrev = ['k', 'm', 'b', 't']

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3)

    // If the n is bigger or equal do the abbreviation
    if (size <= n) {
      // Here, we multiply by d, round, and then divide by d.
      // This gives us nice rounding to a particular decimal place.
      n = Math.round(n * d / size) / d

      // Handle special case where we round up to the next abbreviation
      if ((n == 1000) && (i < abbrev.length - 1)) {
        n = 1
        i++
      }

      // Add the letter for the abbreviation
      n += abbrev[i]

      // We are done... stop
      break
    }
  }

  // console.log(n)

  return n
}

export default abbrNum

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Element from 'material/src/element'
import Button from 'material/src/button'
import Textfield from 'material/src/textfield'
import File from '../control/file'

import build from 'material/src/form/build'
import data from 'material/src/form/data'
import set from 'material/src/form/set'
import controller from 'material/src/form/controller'
import render from 'material/src/form/render'
import submit from 'material/src/form/submit'
import update from 'material/src/form/update'
import cancel from 'material/src/form/cancel'
import create from 'material/src/form/create'
import remove from 'material/src/form/delete'

const defaults = {
  class: 'medal',
  sysinfo: ['_id', 'uuid'],
  action: '/medal/',
  autocomplete: 'off',
  update: {
    method: 'put'
  },
  create: {
    method: 'post',
    info: {
      name: 'New Award'
    }
  },
  layout: [
    [Element, 'head', { class: 'head' },
      [Element, 'title', { class: 'title', text: 'Award' }],
      [Element, { class: 'divider' }],
      [Button, 'new', { flex: 'none', text: 'new' }]
    ],
    [Element, 'body', { class: 'body' },
      [Element, { class: 'group' },
        [Textfield, 'info.name', { name: 'name', label: 'Name', required: true }],
        [File, 'file.image', { label: 'Image file', accept: '.svg' }]
      ]
    ],
    [Element, 'foot', { class: 'foot' },
      [Button, 'delete', { flex: 'none', text: 'Delete', disabled: true }],
      [Element, { class: 'divider' }],
      [Button, 'reset', { text: 'Cancel', type: 'reset' }],
      [Button, 'submit', { text: 'Apply', type: 'submit', color: 'primary' }]
    ]
  ],
  events: [
    // form
    ['form.submit', 'submit'],
    ['form.reset', 'cancel'],

    // action
    ['ui.delete.click', 'del'],
    ['ui.new.click', 'create'],

    // mode
    ['mode', 'changeMode']

  ]
}

class Form {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach, create, remove,
      build, data, set, controller, render, submit, update, cancel
    )

    this.container = this.options.container
    this.info = this.options.info

    this.build()
    this.attach()

    this.initController()

    return this
  }
}

export default Form

import EventEmitter from 'material/src/mixin/emitter'
// modules
import attach from 'material/src/module/attach'
import mediator from 'material/src/module/mediator'
import build from 'material/src/view/build'
import display from 'material/src/mixin/display'
// components
import Button from 'material/src/button'
import Element from 'material/src/element'
import Dialog from 'material/src/dialog'

class Giftcards extends EventEmitter {
  static defaults = {
    base: 'view',
    class: 'sign giftcard',
    tag: 'div',
    autocomplete: 'on',
    method: 'post',
    action: '/giftcard',
    externalScript: 'https://js.stripe.com/v3/',
    transition: 200,
    layout: [
      [Element, 'form', { class: 'sign', tag: 'form' },
        [Button, 'information', { type: 'button', class: 'info', style: 'action' }],
        [Button, 'close', { type: 'button', class: 'close', style: 'action' }],
        [Element, 'head', { class: 'head' },
          [Element, 'icon', { class: 'icon' },
            [Element, 'gift', { class: 'gift' }]
          ],
          [Element, 'radiooooo', { class: 'logo' }],
          [Element, 'title', { class: 'title', text: 'Gift Radiooooo' }],
          [Element, 'sub', { class: 'sub' },
            [Element, { class: 'text', text: 'To someone you' }],
            [Element, { class: 'love', text: 'love' }],
            [Element, { class: 'text', text: '.' }]
          ]
        ],
        [Element, 'body', { class: 'body' },
          [Element, 'info', { class: 'info choose', text: 'Please choose a plan:' }],
          [Element, { class: 'gift' },
            [Element, 'plan3m', { tag: 'input', type: 'radio', id: '3m', value: '3m', name: 'plan' }],
            [Element, { tag: 'label', for: '3m', class: 'plan', text: 'Premium 3 month' }],
            [Element, 'price3m', { class: 'price', text: '15' }]
          ],
          [Element, { class: 'gift' },
            [Element, 'plan6m', { tag: 'input', type: 'radio', id: '6m', value: '6m', name: 'plan' }],
            [Element, { tag: 'label', for: '6m', class: 'plan', text: 'Premium 6 month' }],
            [Element, 'price6m', { class: 'price', text: '25' }]
          ],
          [Element, { class: 'gift' },
            [Element, 'plan1y', { tag: 'input', type: 'radio', id: '1y', value: '1y', name: 'plan', checked: 'checked' }],
            [Element, { tag: 'label', class: 'plan', for: '1y', text: 'Premium 1 year' }],
            [Element, 'price1y', { class: 'price', text: '50' }]
          ],
          [Element, { class: 'gift' },
            [Element, 'plan2y', { tag: 'input', type: 'radio', id: '2y', value: '2y', name: 'plan' }],
            [Element, { tag: 'label', class: 'plan', for: '2y', text: 'Premium 2 year' }],
            [Element, 'price2y', { class: 'price', text: '100' }]
          ],
          [Element, 'space', { class: 'space small' }],
          [Element, 'payment', { class: 'stripe' },
            [Button, 'subscribe', { id: 'submit', text: 'Create Gift Card', type: 'submit', color: 'primary' }]
          ],
          [Element, 'processing', { class: 'processing' },
            [Element, 'infoMessage', { class: 'message', text: 'Checking Card Information...' }],
            [Element, 'infoTitle', { class: 'loader', text: '' }]
          ]
        ]
      ]
    ],
    events: [
      ['ui.close.click', 'close'],
      ['ui.information.click', 'information'],
      ['ui.plan3m.click', 'choose'],
      ['ui.plan6m.click', 'choose'],
      ['ui.plan1y.click', 'choose'],
      ['ui.plan2y.click', 'choose'],
      // ['ui.gift.click', 'giftConfirmed'],
      ['ui.form.submit', 'submit']
    ]
  }

  constructor (options) {
    super()

    this.init(options)
    this.build()
    this.attach()
  }

  init (options) {
    this.options = Object.assign({}, Giftcards.defaults, options || {})
    Object.assign(this, attach, display, build)

    mediator.installTo(this)

    this.platform = 'web' // this.options.platform

    this.plan = '1y'
  }

  choose (ev) {
    // console.log('choose', ev)
    // console.log('plan', ev.target.id)

    this.plan = ev.target.id
  }

  submit (ev) {
    ev.preventDefault()

    fetch('/giftcard', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        plan: this.plan
      })
    }).then(r => r.json()).then(data => {
      // console.log(data)
      if (data.error) {
        window.alert(data.error)
      } else {
        this.emit('success')
        this.giftConfirmed(data)
        this.destroy()
      }
    })
  }

  giftConfirmed (data) {
    this.gift = new Dialog({
      class: 'gift',
      container: document.body,
      name: 'gift',
      layout: [
        [Element, { class: 'head' },
          [Element, { text: 'Gift Card', class: 'title' }],
          [Button, 'close', { class: 'close', style: 'action' }]
        ],
        [Element, { class: 'body' },
          [Element, { text: data.code, class: 'title' }]
        ],
        [Element, { class: 'foot' },
          [Element, { class: 'divider' }],
          [Element, 'download', { class: 'download', tag: 'a', href: data.link, target: '_blank', text: 'Download', color: 'primary' }],
          [Element, { class: 'divider' }]
        ]
      ]
    }).show()
  }

  information () {
    // console.log('info');
    this.publish('information', 'giftcards')
  }

  close () {
    this.emit('close')
    this.destroy()
  }
}

export default Giftcards

'use strict'

import emitter from 'material/src/module/emitter'

const defaults = {
  class: 'file-upload'
}

/**
 * Base class for all ui components
 * @class
 * @param {Object} options - The component options
 * @return {Object} The class Instance
 */

/**
 * Class representing a UI Container. Can add components.
 *
 * @extends Component
 * @return {parent} The class instance
 * @example new Container({
 *   container: document.body
 * });
 */
class File {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter)

    this.build()
    this.attach()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement('div')
    this.element.classList.add(this.options.class)

    if (this.options.class !== 'file') {
      this.element.classList.add('file')
    }

    this.label = document.createElement('label')
    this.label.classList.add('label')
    this.label.innerHTML = this.options.label
    this.element.appendChild(this.label)

    this.wrapper = document.createElement('div')
    this.wrapper.classList.add('field')
    this.element.appendChild(this.wrapper)

    this.input = document.createElement('input')
    this.input.setAttribute('type', 'file')

    if (this.options.accept) {
      this.input.setAttribute('accept', this.options.accept)
    }

    this.wrapper.appendChild(this.input)

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    return this
  }

  attach () {
    this.input.addEventListener('change', (e) => {
      this.emit('change', e)
    })

    this.input.addEventListener('focus', (e) => {
      this.element.classList.add('is-focused')
      this.emit('focus', e)
    })

    this.input.addEventListener('blur', (e) => {
      this.element.classList.remove('is-focused')
      this.emit('blur', e)
    })
  }

  reset () {
    // console.log('reset')
    this.input.value = ''
  }

  set (image) {
    console.log('set', image)
  }
}

export default File

// base class
import EventEmitter from 'material/src/mixin/emitter'
// modules
import mediator from 'material/src/module/mediator'
import attach from 'material/src/module/attach'
import build from 'material/src/module/build'
import display from 'material/src/mixin/display'
// components
import Element from 'material/src/element'
import Button from 'material/src/button'
// widget
import Registration from './widget/registration'
import Subscription from './widget/subscription'
// icons
import iconNext from '../icon/navigate/next.svg'
import iconBefore from '../icon/navigate/before.svg'

class Accounts extends EventEmitter {
  static defaults = {
    base: 'view',
    class: 'view',
    tag: 'div',
    layout: [
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title', text: 'Accounts' }],
        [Element, 'divider', { class: 'divider' }],
        [Button, 'previous', { class: 'previous', icon: iconBefore, style: 'action' }],
        [Button, 'next', { class: 'next', icon: iconNext, style: 'action' }],
        [Element, 'divider', { class: 'divider' }],
        [Element, 'dates', { tag: 'input', id: 'ranged', autocomplete: 'off', readonly: true }]
      ],
      [Element, 'body', { class: 'body' },
        [Registration, 'registration', { class: 'widget registration' }],
        [Subscription, 'subscription', { class: 'widget subscription' }]
      ]
    ],
    events: [
      ['ui.previous.click', 'previous'],
      ['ui.next.click', 'next']
    ]
  }

  constructor (options) {
    super()

    this.init(options)
    this.build()
    this.attach()

    this.loadDatepicker()
  }

  init (options) {
    this.options = Object.assign({}, Accounts.defaults, options || {})
    Object.assign(this, attach, build, display)

    mediator.installTo(this)
  }

  setup () {
    this.initDatpicker()
    this.initResize()
  }

  previous () {
    // console.log('previous');
    var date = this.datepicker.getDate()[0]
    const startDate = dayjs(date).subtract(7, 'day').format()
    // console.log('startDate', startDate);

    this.datepicker.removeDate(this.datepicker.getDate())

    // console.log('dates after remove', this.datepicker.getDate());

    const dates = []

    for (let i = 0; i < 7; i++) {
      // console.log('substract', day + i);
      var date = dayjs(startDate).add(i, 'day').format()
      dates.push(date)
    }

    // console.log('dates', dates);

    this.datepicker.addDate(dates)
  }

  next () {
    // console.log('previous');
    var date = this.datepicker.getDate()[0]
    const startDate = dayjs(date).add(7, 'day').format()
    // console.log('startDate', startDate);

    this.datepicker.removeDate(this.datepicker.getDate())

    // console.log('dates after remove', this.datepicker.getDate());

    const dates = []

    for (let i = 0; i < 7; i++) {
      // console.log('substract', day + i);
      var date = dayjs(startDate).add(i, 'day').format()
      dates.push(date)
    }

    // console.log('dates', dates);

    this.datepicker.addDate(dates)
  }

  initDatpicker (startDate) {
    let it
    // console.log('initDatpicker', startDate);
    this.datepicker = new Datepicker(this.ui.dates, {
      ranged: true,
      open: startDate,
      yearRange: 10,
      onChange: (i) => {
        // console.log('change', i.length);
        if (i.length > 1) {
          clearTimeout(it)
          it = setTimeout(() => {
            const from = dayjs(i[0]).format('YYYY-MM-DD') + ' 00:00:00'
            const to = dayjs(i[i.length - 1]).format('YYYY-MM-DD') + ' 23:59:59'

            // console.log('dates', from, to);
            this.ui.registration.update(from, to)
            this.ui.subscription.update(from, to)
          }, 200)
        }
      }
    })

    const day = dayjs().day()

    const dates = []

    for (let i = 0; i < 7; i++) {
      // console.log('substract', day + i);
      const date = dayjs().subtract(day + i + 1, 'day').format()
      dates.push(date)
    }

    this.datepicker.addDate(dates)
  }

  initResize () {
    let it
    window.addEventListener('resize', () => {
      // console.log('window resize')
      clearTimeout(it)
      it = setTimeout(() => {
        // console.log('window.resize')
        this.resize()
      }, 500)
    })
  }

  loadDatepicker () {
    // console.log('loadLeaflet...')

    if (this.loadingLeft) {
      return
    }

    this.loadingLeft = true

    const styles = document.createElement('link')
    styles.setAttribute('rel', 'stylesheet')
    styles.setAttribute('type', 'text/css')
    styles.setAttribute('href', '/dist/css/datepicker.material.css')

    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('src', './dist/datepicker.js')

    script.onload = () => {
      // console.log('leaflet loaded')
      this.setup()
    }
    document.getElementsByTagName('head')[0].appendChild(styles)
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  resize () {
    this.ui.registration.resize()
    this.ui.subscription.resize()
  }
}

export default Accounts

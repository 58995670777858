// module
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'
import mediator from 'material/src/module/mediator'

// list
import build from 'material/src/list/build'
import search from 'material/src/list/search'
import add from 'material/src/list/add'
import request from 'material/src/list/request'
import image from 'material/src/list/image'
import render from 'material/src/list/render'
import remove from 'material/src/list/remove'
import status from 'material/src/list/status'
import filter from 'material/src/list/update'
import select from 'material/src/list/select'
import click from 'material/src/list/click'
import empty from 'material/src/list/empty'

// component
import Dialog from 'material/src/dialog'
import Element from 'material/src/element'
import Button from 'material/src/button'
import Search from 'material/src/search'
import Image from '../field/image.js'
import Icon from '../control/icon'
import Countries from '../control/country'
import Year from '../control/year'
import Moods from '../control/moods'

// import File from 'material/src/file'
//

// icon
import iconSearch from '../icon/search.svg'
import iconFilter from '../icon/filter.svg'
import iconRemove from '../icon/remove.svg'
import iconAdd from '../icon/add.svg'
import iconDownload from '../icon/download.svg'

// import hotkeys from 'hotkeys-js'

const defaults = {
  class: 'track',
  pagination: true,
  preventSelectAgain: true,
  list: {
    size: 100,
    page: 1
  },
  // debug: true,
  count: true,
  loading: 'dynamic',
  updatePosition: true,
  item: {
    height: 60,
    new: {
      name: 'New Track'
    }
  },
  title: 'tracks',
  route: {
    search: '/track',
    list: '/track/list'
  },
  url: {
    addTrack: '/island/track'
  },
  layout: {
    main: [
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title', text: 'Tracks' }],
        [Element, 'divider', { class: 'divider' }],
        // [File, 'upload', { label: 'upload', size: 'small', color: 'primary', multiple: true, accept: 'audio/x-m4a,audio/*' }],
        [Button, 'search', { class: 'search', icon: iconSearch, size: 'small', style: 'action' }]
        // [Button, 'filter', { class: 'filter', icon: iconFilter, size: 'small', style: 'action' }]
      ],
      [Element, 'filter-input', { class: 'filter-input' },
        [Icon, { src: iconFilter }],
        [Countries, 'filter.country', { label: 'Country', class: 'country' }],
        [Year, 'filter.decade', { label: 'Decade', class: 'decade', decade: true }],
        [Moods, 'filter.mood', { label: 'Mood', class: 'moods' }],
        // [Textfield, 'info.label', { label: 'Label' }],
        [Element, { class: 'divider' }],
        [Button, 'filter-clear', { class: 'clear', icon: iconRemove }]
      ],
      [Search, 'search-input', { minChar: 3 }],
      [Element, 'search-list', { class: 'body search' }],
      [Element, 'body', { class: 'body' }],
      [Element, 'foot', { class: 'foot' },
        [Element, 'status', { class: 'status' },
          [Element, 'count', { class: 'count' }]
        ]
      ]
    ],
    item: [
      [Image, 'info.cover', { class: 'image', background: true, format: 'thumb', generic: '/img/generic/nocover.svg' },
        [Element, 'play', { class: 'play' }]
      ],
      [Element, 'content', { class: 'content' },
        [Element, 'info.title', { class: 'title' }],
        [Element, 'info.artist', { class: 'artist' }]
      ],
      [Element, 'action', { class: 'action' },
        [Button, 'download', { class: 'download', icon: iconDownload, style: 'action', data: { name: 'download' } }],
        [Button, 'add', { class: 'add', icon: iconAdd, style: 'action', data: { name: 'add' } }],
        [Button, 'remove', { class: 'remove', icon: iconRemove, style: 'action', data: { name: 'remove' } }]
      ],
      [Element, { class: 'info' },
        [Element, 'info.year', { class: 'year' }],
        [Element, 'info.country', { class: 'country' }]
      ]
    ],
    search: [
      [Image, 'image.image', { class: 'image' },
        [Element, 'play', { class: 'play' }]
      ],
      [Element, 'content', { class: 'content' },
        [Element, 'info', { class: 'info' },
          [Element, 'info.artist', { class: 'artist' }],
          [Element, 'info.country', { class: 'country' }]
        ],
        [Element, 'info', { class: 'info' },
          [Element, 'info.title', { class: 'title' }],
          [Element, 'info.year', { class: 'year' }]
        ]
      ]
    ]
  },
  events: [
    ['ui.delete.click', 'deleteTrack'],
    ['ui.search.click', 'toggleSearch'],
    ['ui.search-input.change', 'search'],
    ['ui.search-input.change', 'cancelSearch'],
    ['ui.body.click', 'click'],
    ['fetched', 'updateCount'],
    // action
    ['download', 'download'],
    ['add', 'addTrack'],
    ['remove', 'removeTrack'],

    // ['infoRendered', 'checkIfInList'],
    ['ui.upload.change', 'upload']
  ]
}

class List {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    // merge defaults wifth the given options
    this.options = Object.assign({}, defaults, options || {})

    // assign modules
    Object.assign(this, emitter, attach,
      build, request, click,
      render, search, image, filter, status, select, empty, add, remove
    )

    mediator.installTo(this)

    this.init()

    return this
  }

  init () {
    this.page = 1

    this.build()
    this.attach()
  }

  set (id) {
    // console.log('set', id)

    if (!id) return

    this.island = {
      _id: id
    }

    this.id = null

    this.request()
  }

  selectSearch (e) {
    // console.log('selectSearch', e.target)

    if (e.target === e.currentTarget) return

    const target = '[data-id]'
    let el = e.target
    let i = 0

    while (el && !el.matches(target)) {
      if (i > 7) {
        el = null
        break
      }
      el = el.parentNode
      i++
    }

    if (el && el.dataset.id) {
      this.addTrack(el.dataset.id)
    }
  }

  addTrack (id) {
    // console.log('addTrack', id, this.island._id)
    if (!id || !this.island._id) return

    fetch('/island/track', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        island_id: this.island._id,
        track_id: id
      })
    }).then(r => r.json()).then(data => {
      // console.log(data)
      if (data.error) {
        window.alert('Oops! The was a probleme when updating or creating this info. Error: ' + data.message)
      } else {
        // console.log('success', data)
        this.hideSearch()
        this.set(this.island._id)
      }
    })
  }

  removeTrack (id) {
    console.log('removeTrack', id)

    if ((!id) || !this.island._id) return

    new Dialog({
      name: 'alert',
      content: 'Delete track from this island ?',
      accept: { text: 'Delete', color: 'primary' },
      cancel: { text: 'Cancel', color: 'primary' }
    }).on('accepted', () => {
      const body = {
        island_id: this.island._id,
        track_id: id
      }

      fetch('/island/track', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'DELETE',
        body: JSON.stringify(body)
      }).then(r => r.json()).then(data => {
        // console.log(data)
        if (data.error) {
          window.alert('Oops! The was a probleme when updating or creating this info. Error: ' + data.message)
        } else {
          if (this.id === id) {
            this.selectNext()
            this.publish('play', this.id, this)
          }

          // console.log('success', data)
          this.remove(id)
        }
      })
    }).on('canceled', () => {
      // console.log('alert dialog canceled')
    }).insert(document.body).show()
  }

  download (id) {
    window.location = '/track/download/' + id
  }

  checkIfInList (info, element) {
    if (!info.islands) return

    // console.log('checkIfInList', info.islands, element)

    if (info.islands.indexOf(this.island._id) > -1) {
      element.classList.add('showDelete')
      console.log('delete', element)
    }
  }

  upload (e) {
    // console.log('upload', e)

    this.publish('file', e.target.files, this, { islands: [this.island._id] })
  }

  params () {
    // console.log('params', this.island._id)
    return 'islands=' + this.island._id + '&status=onair'
  }
}

export default List

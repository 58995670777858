import Element from 'material/src/element'
import Button from 'material/src/button'
import Player from './control/player'

import iconAccount from './icon/account.svg'
import iconApps from './icon/apps.svg'

/**
 * Application Layout
 */
const layout = [
  [Element, 'head', { class: 'head' },
    [Element, 'logo', { class: 'logo' }],
    [Element, { class: 'divider' }],
    [Element, { class: 'toolbar' },
      [Button, 'apps', { icon: iconApps, size: 'small', style: 'action' }],
      [Button, 'account', { icon: iconAccount, size: 'small', style: 'action' }]
    ]
  ],
  [Player, 'player', { }],
  [Element, 'main', { class: 'main' },
    [Element, 'menu', { class: 'menu' },
      [Element, 'headmenu', { class: 'headmenu' }],
      [Element, 'bodymenu', { class: 'bodymenu' }],
      [Element, 'footmenu', { class: 'footmenu' }]
    ],
    [Element, 'body', { class: 'body' }]
  ],
  // [Element, 'foot', { class: 'foot' }],
  [Element, 'launchpad', { class: 'launchpad' }]
]

export default layout

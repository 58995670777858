'use strict'

const defaults = {
  class: 'flag',
  tag: 'div'
}

class Flag {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    // console.log('options', options)

    this.build()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement(this.options.tag)
    this.element.classList.add(this.options.class)

    if (this.options.class !== 'flag') {
      this.element.classList.add('flag')
    }

    if (this.container) {
      this.container.appendChild(this.element)
    }

    return this
  }

  set (code) {
    // console.log('set', code)
    this.element.classList.add(code)
  }
}

export default Flag

import mediator from 'material/src/module/mediator'

export default {
  initDrop () {
    this.ui.body.addEventListener('drop', (ev) => {
      this.drop(ev)
    })

    this.ui.body.ondragover = this.allowDrop
  },

  drop (ev) {
    // console.log('drop', ev)
    ev.preventDefault()
    const id = ev.dataTransfer.getData('id')
    const point = {
      x: ev.offsetX,
      y: ev.offsetY
    }

    this.getDroppedMarkerInfo(id, point)
  },

  dropPointToPos (info, point) {
    // console.log('dropPointToPos', info.pos, point)
    const ratio = this.getRatio()
    // console.log('ratio', ratio)

    // console.log('getZoom', this.map.getZoom())
    // console.log('getSize', this.map.getSize())
    // console.log('getPixelOrigin', this.map.getPixelOrigin())
    // console.log('getPixelBounds', this.map.getPixelBounds())
    // // console.log('getBoundsZoom', this.map.getBoundsZoom())
    // console.log('getBounds', this.map.getBounds())

    info.pos = null

    const marker = this.addMarker(info)
    // this.focusMarker(marker)
  },

  getDroppedMarkerInfo (id, point) {
    // console.log('addDroppedMarker', id)
    mediator.publish('marker.dropped', id)

    fetch('/island/' + id, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    }).then((resp) => {
      // console.log('resp', resp)

      return resp.json()
    }).then((info) => {
      // console.log('info', info)
      this.dropPointToPos(info, point)
    }).catch((error) => {
      console.log('error', error)
    })
  },

  allowDrop (ev) {
    // console.log('allowDrop', e)
    ev.preventDefault()
  }
}

// module
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

// form

import build from 'material/src/form/build'
import data from 'material/src/form/data'
import controller from 'material/src/form/controller'
import render from 'material/src/form/render'
import submit from 'material/src/form/submit'
import update from 'material/src/form/update'

import cancel from 'material/src/form/cancel'

// component
import Element from 'material/src/element'
import Textfield from 'material/src/textfield'
import Button from 'material/src/button'

const defaults = {
  class: 'profile',
  sysinfo: ['_id', 'uuid'],
  action: '/contact/',
  update: {
    method: 'put'
  },
  autocomplete: 'off',
  layout: [
    [Element, { class: 'body' },
      [Element, { class: 'group contact' },
        [Textfield, 'info.email', { label: 'E-mail', type: 'email', autocomplete: 'email', required: true }],
        [Textfield, 'info.firstname', { label: 'Firstname', autocomplete: 'firstname', required: true }],
        [Textfield, 'info.lastname', { label: 'Lastname', autocomplete: 'lastname', required: true }]
      ]
    ],
    [Element, { class: 'foot' },
      [Element, { class: 'divider' }],
      [Button, 'cancel', { text: 'Cancel', type: 'reset', enabled: false }],
      [Button, 'submit', { text: 'Apply', type: 'submit', color: 'primary', enabled: false }]
    ]
  ],
  events: [
    // form
    ['form.submit', 'submit'],
    ['form.reset', 'reset'],

    // mode
    ['mode', 'changeMode']
  ]
}

class Contact {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach, controller,
      build, data, render, submit, update, cancel
    )

    this.build()
    this.attach()
    this.initController()

    return this
  }

  submit (ev) {
    // console.log('submit', ev)
    ev.preventDefault()

    const fields = this.fields
    const data = {}

    for (let i = 0; i < fields.length; i++) {
      if (this.field[fields[i]].get) {
        data[fields[i]] = this.field[fields[i]].get()
      }
    }

    this.update(data)

    return false
  }

  set (user) {
    // console.log('set', user)

    this.setMode('read')

    fetch(this.options.action + user.contact_id, {
      headers: {
        Accept: 'application/json'
      },
      method: 'GET'
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((info) => {
      this.info = info
      this.render(info)
    })
  }

  success () {
    // console.log('success')
    if (this.disableControls) {
      this.disableControls()
    }
  }

  error () {
    // console.log('success')
    if (this.disableControls) {
      this.disableControls()
    }
  }

  reset (ev) {
    ev.preventDefault()
    // console.log('cancel', this.info)
    this.render(this.info)

    if (this.disableControls) {
      this.disableControls()
    }
  }
}

export default Contact

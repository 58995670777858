// module
import system from '../module/system'
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'
import mediator from 'material/src/module/mediator'

// app
import context from '../app/context'
import display from '../app/display'
import build from '../app/build'

// ui
import Drawer from 'material/src/drawer'
import Element from 'material/src/element'
import Button from 'material/src/button'

// views
import List from './list'
import Tracks from '../list/track'
import Track from '../form/track'
import Form from './form'
import Map from '../map'
import Dialog from 'material/src/dialog'

// icons
import iconNavi from '../icon/navi.svg'
import iconPublish from '../icon/publish.svg'

const TITLE = 'Islands'

const defaults = {
  class: 'islands',
  tag: 'div',
  layout: [
    [Element, { class: 'appbar' },
      [Button, 'navi-menu', { class: 'navi', icon: iconNavi, size: 'small', style: 'action' }],
      [Element, 'title', { class: 'title', text: TITLE }]
      // [Button, 'menu-more', { icon: iconMore, type: 'action' }]
    ],
    [Drawer, 'navi', { css: 'drawer-persistent', display: 'flex', direction: 'horizontal', type: 'persistent' }],
    [List, 'list', { class: 'islands' }],
    [Tracks, 'tracks', { class: 'tracks', style: 'compact' }],
    [Form, 'form', { }],
    [Track, 'track', { }]

  ],
  events: [
    // navi
    ['ui.navi-menu.click', 'ui.navi.toggle'],

    // list list
    ['ui.list.select', 'setForm'],
    ['ui.list.select', 'ui.tracks.set'],
    ['ui.list.select', 'setSelected'],
    ['ui.list.showMap', 'showMap'],
    ['ui.list.create', 'ui.tracks.empty'],
    ['ui.list.categoryChange', 'setCategory'],
    ['ui.list.fetched', 'updateMap'],
    ['ui.list.ordered', 'update'],

    ['ui.list.set', 'ui.form.set'],

    // track list view
    ['ui.tracks.select', 'selectTrack'],

    // track form
    ['ui.track.updated', 'ui.tracks.update'],

    // form view
    ['ui.form.new', 'onNew'],
    ['ui.form.created', 'addIsland'],
    ['ui.form.created', 'ui.tracks.empty'],
    ['ui.form.created', 'update'],
    ['ui.form.updated', 'ui.list.update'],
    ['ui.form.updated', 'update'],
    ['ui.form.deleted', 'ui.list.remove'],
    ['ui.form.ui.map.click', 'showMap'],
    ['ui.form.erase', 'eraseIsland'],

    //
    ['ui.update.click', 'update']

    // ['ui.form.ui.map.ready', 'updateMap']
  ]
}

/**
 * Class representing a login dialog
 *
 * @return {parent} The class instance
 * @example new Login({
 *   container: document.body
 * });
 */
class Islands {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, attach, emitter, build, context, display)

    mediator.installTo(this)

    this.build()
    this.setup()
    this.attach()

    // console.log(map)

    return this
  }

  setup () {
    this.getContext()

    // console.log('context', this.context)

    if (this.context.selected) {
      // this.ui.list.id = this.context.selected
      this.ui.form.set(this.context.selected)
      this.ui.tracks.set(this.context.selected)
      this.ui.form.show()
    }

    if (!this.context.category) this.context.category = 'thematic'
    this.ui.list.initContext(this.context.category, this.context.selected)

    this.subscribe('island.map.show', () => {
      this.showMap()
    })
  }

  addIsland (info) {
    // console.log('addIsland', info)
    this.ui.list.add(info)
    this.ui.list.selectById(info._id)
  }

  selectTrack (id) {
    // console.log('selectItem', item)
    this.ui.form.hide()
    this.ui.track.show()
    this.ui.track.set(id)

    this.publish('play', id, this.ui.tracks)
  }

  // setTracksOnCreated (info) {
  //   console.log('setTracksOnCreated', info)

  //   this.ui.tracks.set(info)
  // }

  showMap () {
    if (!this.ui.map) {
      this.ui.map = new Map({
        container: this.container,
        id: this.ui.list.id
      }).on('select', (info) => {
        // console.log('select', info)
        this.ui.list.selectIsland(info)
      }).on('updated', (info) => {
        if (this.ui.form.info._id === info._id) {
          // console.log('addIslanupdate form', info)
          this.ui.form.updateInfo(info)
        }
      }).show()
    } else {
      this.ui.map.show()
    }
  }

  hideMap () {
    this.ui.map.hide()
  }

  setCategory (category) {
    this.setContext('category', category)
  }

  setSelected (id) {
    this.ui.form.show()
    this.ui.track.hide()
    // console.log('setSelected', this.ui.list.dataStore[id])
    this.setContext('selected', id)

    // console.log('item', this.item)

    if (this.ui.map) {
      //
      this.ui.map.selectById(id)
    }
  }

  setForm (info) {
    // console.log('info', info)
    this.ui.form.set(info)
  }

  onNew (info) {
    // console.log('onNew', this.ui.list.data)
    let last = 0

    const data = this.ui.list.data

    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i].sort > last) last = data[i].sort
    }

    // console.log('last', last)

    this.ui.form.setSort(last)
  }

  updateMap () {
    // console.log('update map', this.ui.list.data)

    this.ui.map.setList(this.ui.list.dataStore[id])
  }

  update () {
    // console.log('updateIslands')
    //
    setTimeout(() => {
      fetch('/content/publish?type=islands', {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      }).then((resp) => {
        // console.log('resp', resp)

        return resp.json()
      }).then((result) => {
        // console.log('result', result)
      // this.confirm()
      // console.log(result)
      }).catch((error) => {
        new Dialog({
          name: 'alert',
          content: 'Error: Can\'t reach API Server',
          accept: { text: 'OK', color: 'primary' }
        }).on('accepted', () => {

        }).insert(document.body).show()
        console.log('error', error)
        // Only network error comes here
      })
    }, 1000)
  }

  confirm () {
    new Dialog({
      name: 'alert',
      content: 'Publish OK',
      accept: { text: 'OK', color: 'primary' }
    }).on('accepted', () => {

    }).insert(document.body).show()
  }

  eraseIsland (info) {
    // console.log('eraseIsland', info, this.ui.tracks.data.length)

    if (this.ui.tracks.data.length > 0) {
      alert('please empty the island first!')
    } else {
      this.ui.form.del()
    }
  }

  command () {

  }
}

export default Islands

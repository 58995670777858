// search control

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Layout from '../layout/index'
import Element from 'material/src/element'

const defaults = {
  class: 'upload',
  route: '/track/',
  unknown: {
    title: 'Undefined',
    artist: 'Undefined',
    year: '2020',
    country: 'UND'
  },
  layout: [
    [Element, { class: 'body' },
      [Element, 'track', { class: 'track' },
        [Element, 'title', { class: 'title' }],
        [Element, 'divider', { text: ' - ' }],
        [Element, 'artist', { class: 'artist' }]
      ],
      [Element, { class: 'progress' },
        [Element, 'bar', { class: 'bar' }],
        [Element, 'percent', { class: 'percent' }],
        [Element, 'info', { class: 'info' }]
      ]
    ]
  ],
  events: [

  ]
}

class Upload {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach)

    this.build()
    this.attach()
    this.setup()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement('div')
    this.element.classList.add('control')
    this.element.classList.add('upload')

    if (this.options.class !== 'upload') {
      this.element.classList.add(this.options.class)
    }

    this.layout = new Layout(this.options.layout, this.element)
    this.ui = this.layout.component

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    return this
  }

  setup () {
    // console.log('submit')

    const formData = new FormData()
    formData.append('track', this.options.file)

    const success = (field) => {
      console.log('metadata', field)

      this.define(formData, field)
    }

    const error = (e) => {
      // console.log('no metatdata', e)
      this.define(formData, this.options.unknown)
    }

    parse_audio_metadata(this.options.file, success, error)
  }

  define (formData, info) {
    info = info || {}

    // console.log('define', this.options.field)
    if (this.options.field) {
      for (const name in this.options.field) {
        if (this.options.field.hasOwnProperty(name)) {
          formData.append(name, this.options.field[name])
        }
      }
    }

    if (info.year && info.year.length > 4) {
      const date = info.year.split('-')
      if (date[0].length === 4) info.year = date[0]
    }

    const fields = ['artist', 'title', 'country', 'year', 'album', 'genre']

    if (!info.title) info.title = 'title'
    if (!info.artist) info.artist = 'artist'
    if (!info.year) info.year = '3000'
    if (!info.country) info.country = 'UND'

    for (let i = fields.length - 1; i >= 0; i--) {
      // console.log('field', fields[i], data[fields[i]])
      // metadata[fields[i]].rtrim()
      formData.append(fields[i], info[fields[i]])
    }

    this.render(info)
    this.start(formData)
  }

  render (info) {
    this.ui.title.innerHTML = info.title
    this.ui.artist.innerHTML = info.artist
  }

  start (data) {
    // console.log('start', data)

    this.upload = new XMLHttpRequest()

    if (navigator.userAgent.indexOf('Firefox') > 0) {
      // for firefox
      // console.log('firefox request progress event')
      this.upload.addEventListener('progress', (e) => {
        this.updateProgress(e)
      }, false)
    } else {
      // others
      // console.log('chrome and safari request upload progress event')
      this.upload.upload.addEventListener('progress', (e) => {
        this.updateProgress(e)
      }, false)
    }

    this.upload.addEventListener('readystatechange', (e) => {
      if (this.upload.readyState === 4) {
        // console.log('response', this.upload.response)
        const info = JSON.parse(this.upload.response)
        this.success(e, info)
      }
    }, false)

    this.upload.addEventListener('error', (e) => {
      this.error(e)
    }, false)

    this.upload.open(this.options.method, this.options.route, true)
    this.upload.send(data)
  }

  updateProgress (e) {
    // console.log('... loaded total', e.loaded, e.total)
    // console.log('lengthComputable', e.lengthComputable)

    if (e.lengthComputable) {
      this.upload.progress = e.loaded / e.total
      this.displayProgress(e.loaded, e.total)
    } else if (this.upload.explicitTotal) {
      this.upload.progress = Math.min(1, e.loaded / this.upload.explicitTotal)
      this.displayProgress(e.loaded, e.total)
    } else {
      this.upload.progress = 0
    }
  }

  displayProgress (loaded, total) {
    const percent = (loaded / total) * 100

    // info

    this.ui.info.innerHTML = this.formatBytes(loaded) + ' bytes of ' + this.formatBytes(total)

    // progressbar
    this.ui.bar.style.width = percent + '%'
    this.ui.percent.innerHTML = Math.round(percent) + '%'
  }

  formatBytes (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')
  }

  success (e, info) {
    // console.log('success', e, resp)
    // var list = this.options.list

    // list.add(info)

    // var item = list.ui.body.querySelector('[data-id="' + info._id + '"]')
    // console.log('item', item)

    this.emit('success', info)
  }

  error (e) {
    this.emit('error', e)
  }

  destroy () {
    this.element.parentNode.removeChild(this.element)
  }
}

export default Upload

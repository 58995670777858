// module
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

// component
import Button from 'material/src/button'
import Element from 'material/src/element'
// import Text from 'material/src/text'
import Textfield from 'material/src/textfield'
import Switcher from '../control/switcher'

// form
import build from 'material/src/form/build'
import data from 'material/src/form/data'
import controller from 'material/src/form/controller'
import render from 'material/src/form/render'
import submit from 'material/src/form/submit'
import update from 'material/src/form/update'

const defaults = {
  class: 'user',
  sysinfo: [],
  action: '/user/',
  controls: ['submit'],
  update: {
    method: 'post'
  },
  autocomplete: 'false',
  layout: [
    [Element, 'body', { class: 'body' },
      [Element, { class: 'group' },
        [Textfield, 'info.username', { label: 'Email', autocomplete: 'false', type: 'email', required: true }],
        [Textfield, 'info.password', { label: 'Password', pattern: '.{6,}', type: 'password', autocomplete: 'new-password', required: 'required', title: '6 characters minimum' }],
        [Switcher, 'info.role', { label: 'Role', size: 'small', list: ['registered', 'press', 'premium', 'digger', 'friends', 'admin'] }]
      ]
    ],
    [Element, 'foot', { class: 'foot' },
      [Element, 'divider', { class: 'divider', flex: '1' }],
      [Button, 'close', { text: 'Cancel' }],
      [Button, 'submit', { text: 'Apply', type: 'submit', color: 'primary' }]
    ]
  ],
  events: [
    // form
    ['form.submit', 'submit'],
    ['ui.close.click', 'close'],

    // mode
    ['mode', 'changeMode']
  ]
}

class Form {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach,
      build, data, render, submit, update, controller
    )

    this.container = this.options.container
    this.info = this.options.info

    this.build()
    this.attach()
    this.initController()

    console.log('field', this)

    this.field.username.input.focus()

    return this
  }

  close () {
    console.log('close')
    this.form.reset()
    this.emit('close')
  }

  success (info) {
    console.log('success', info)
    this.emit('ok', info)
  }

  set (user) {
    console.log('set', user)

    if (!user) return

    this.info = user

    this.render(user)

    this.setMode('read')
  }
}

export default Form

import system from '../module/system'
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'
import mediator from 'material/src/module/mediator'

import Element from 'material/src/element'
import Button from 'material/src/button'
import Textfield from 'material/src/textfield'
import Switch from 'material/src/switch'
import Select from '../control/select'
import DatePicker from '../control/datepicker'

import File from '../control/file'
import Image from '../field/image'

import build from 'material/src/form/build'
import display from 'material/src/mixin/display'
import data from 'material/src/form/data'
import set from 'material/src/form/set'
import controller from 'material/src/form/controller'
import update from 'material/src/form/update'
import render from 'material/src/form/render'
import submit from 'material/src/form/submit'
import cancel from 'material/src/form/cancel'
import create from 'material/src/form/create'
import remove from 'material/src/form/delete'

import iconMap from '../icon/map.svg'

const defaults = {
  class: 'island',
  sysinfo: ['_id', 'uuid'],
  action: '/island/',
  autocomplete: 'off',
  update: {
    method: 'put'
  },
  create: {
    method: 'post',
    info: {
      name: 'New Island'
    }
  },

  layout: [
    [Element, 'head', { class: 'head' },
      [Element, 'title', { class: 'title', text: 'Island' }],
      [Element, { class: 'divider' }],
      [Button, 'new', { flex: 'none', text: 'new' }],
      [Button, 'map', { style: 'action', size: 'small', icon: iconMap }]
    ],
    [Element, 'body', { class: 'body' },
      [Element, 'group', { class: 'group' },
        // [Textfield, 'info.sort', { class: 'sort', name: 'sort', label: 'Sort' }],
        [Element, 'fieldset', { class: 'fieldset row' },
          [Textfield, 'info.name', { class: 'name', name: 'name', label: 'Name', required: true }],
          [DatePicker, 'info.published', { class: 'date', name: 'published', label: 'Published Date' }]
        ],
        [Textfield, 'info.info', { name: 'info', label: 'Info' }],
        [Element, 'fieldset', { class: 'fieldset row' },
          [Select, 'info.category', { class: 'category', name: 'category', label: 'Category', first: true }],
          [Textfield, 'info.sort', { name: 'sort', class: 'sort', label: 'Sort' }]
        ]
      ],
      [Element, { class: 'group image' },
        [Image, 'icon', { class: 'thumb', path: 'icon', format: null }],
        [Image, 'marker', { class: 'thumb', path: 'marker', format: null }],
        [Image, 'splash', { class: 'thumb', path: 'splash', format: null }]
      ],
      [Element, 'group', { class: 'group', flex: 1, display: 'flex', direction: 'horizontal' },
        [File, 'file.icon', { label: 'icon file', accept: 'image/*' }],
        [File, 'file.splash', { label: 'splash file', accept: 'image/*' }],
        [File, 'file.marker', { label: 'marker file', accept: 'image/*' }]
      ],
      [Element, 'group', { class: 'group switches' },
        [Switch, 'info.enabled', { class: 'switch', label: 'Enabled' }],
        [Switch, 'info.new', { class: 'switch', label: 'New' }],
        [Switch, 'info.free', { class: 'switch', label: 'Free' }],

        [Switch, 'info.random', { class: 'switch', label: 'Random mode' }],
        [Switch, 'info.onmap', { class: 'switch', label: 'Display on map' }]
      ]
    ],
    [Element, 'group', { class: 'group coord', flex: 1, display: 'flex', direction: 'horizontal' },
      [Textfield, 'info.pos.x', { flex: 1, label: 'x' }],
      [Textfield, 'info.pos.y', { flex: 1, label: 'y' }],
      [Textfield, 'info.size.x', { flex: 1, label: 'Size width' }],
      [Textfield, 'info.size.y', { flex: 1, label: 'height' }]
    ],
    [Element, 'foot', { class: 'foot' },
      [Button, 'delete', { text: 'Delete', style: 'outline', disabled: true }],
      [Element, { class: 'divider' }],
      [Button, 'cancel', { type: 'reset', text: 'Cancel', enabled: false }],
      [Button, 'submit', { type: 'submit', text: 'Apply', color: 'primary', enabled: false }]
    ]
  ],
  events: [
    // form
    ['setted', 'check'],
    ['setted', 'setImages'],
    ['form.submit', 'submit'],
    ['form.reset', 'cancel'],
    ['cleaned', 'cleanImages'],

    // mode
    ['mode', 'changeMode'],

    // action
    ['ui.new.click', 'create'],
    ['ui.delete.click', 'erase']
  ]
}

class Form {
  test () {
    console.log('hello')
  }

  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach, mediator, display,
      build, data, set, render,
      submit, update, cancel, create, remove, controller
    )

    this.container = this.options.container
    this.info = this.options.info

    this.build()
    this.setup()
    this.attach()

    this.initController()

    if (this.info) this.set(this.info)

    return this
  }

  setup () {
    const api = system.get('api')
    const url = api.url.endsWith('/') ? api.url.slice(0, -1) : api.url
    // console.log('api', api)
    // console.log('getArchipels', api + 'archipels?_locale=' + lang + '&_sort=sort:ASC')
    const self = this
    fetch(url + '/archipels?_locale=en&_sort=order:ASC', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((list) => {
      // console.log(list)
      if (list.error) {
        console.log('error')
      } else {
        // console.log('list', list)
        self.addOptions(list)
      }
    }).catch((error) => {
      console.log('error')
    })

    this.subscribe('category.change', (info) => {
      // console.log('category.change', info)
      this.options.create.info.category = info.category
      this.options.create.info.sort = info.sort
    })
  }

  addOptions (list) {
    // console.log('categories', list.length)

    // this.ui['info.category'].addOption('', '')

    for (let i = 0; i < list.length; i++) {
      const info = list[i]
      // console.log('option', info.name, info.category)
      this.ui['info.category'].addOption(info.name, info.category)
    }
  }

  onMapChange (value) {
    // console.log('onMapChange', value)
  }

  selectIsland (element) {
    // console.log('selectIsland', element.dataset.id)
    this.set(element.dataset.id)
  }

  check (info) {
    // console.log('check', info)
    const api = system.get('api')
    const url = api.url.endsWith('/') ? api.url.slice(0, -1) : api.url

    fetch(url + '/islands/finduid?locale=en&uid=' + info._id, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((data) => {
      // console.log(list)
      if (data.error) {
        // console.log('error', data.error)
      } else {
        // console.log('data', data)
        this.updateData(data)
      }
    }).catch((error) => {
      console.log('catch error', error)
    })
  }

  setSort (last) {
    console.log('setSort', last)
    this.ui['info.sort'].set(last + 1)
  }

  setImages (info) {
    // console.log('setImages')
    this.ui.icon.set(info.icon)
    this.ui.marker.set(info.marker)
    this.ui.splash.set(info.splash)
  }

  cleanImages () {
    // console.log('cleanImages')
    this.ui.icon.set(null)
    this.ui.marker.set(null)
    this.ui.splash.set(null)
  }

  erase (e) {
    // console.log('erase island', this.info)
    // check if island is empty

    this.emit('erase', this.info)
  }

  updateData (info) {
    // console.log('updateInfo', info)

    this.ui['info.name'].set(info.name)
    this.ui['info.info'].set(info.info)
    this.ui['info.category'].set(info.category)
  }

  success (info) {
    // console.log('success', info)
    this.disableControls()
  }
}

export default Form

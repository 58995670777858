
function toTime (seconds) {
  if (isNaN(seconds)) {
    return '00:00'
  }

  if (seconds === 'NaN') { var min = 0 }
  let sec = Math.floor(seconds)
  let time = 0

  min = Math.floor(sec / 60)
  min = min >= 10 ? min : '0' + min

  sec = Math.floor(sec % 60)
  sec = sec >= 10 ? sec : '0' + sec

  time = min + ':' + sec

  return time
}

export default toTime

export default [
  // list
  ['ui.list.create', 'ui.form.create'],
  ['ui.list.created', 'ui.list.created'],
  ['ui.list.onair', 'onair'],
  ['ui.list.inbox', 'inbox'],
  ['ui.list.validate', 'validate'],
  ['ui.list.duplicate', 'duplicate'],
  ['ui.list.reject', 'reject'],
  ['ui.list.rework', 'rework'],
  ['ui.list.broken', 'broken'],
  ['ui.list.allduplicates', 'allduplicates'],
  ['ui.list.delete', 'delete'],

  ['ui.list.download', 'download'],
  ['ui.list.confirmation', 'confirmation'],
  ['ui.list.select', 'ui.form.set'],
  ['ui.list.select', 'setHelper'],
  ['ui.list.selectItem', 'updateCover'],
  ['ui.list.select', 'selectTrack'],
  ['ui.list.toggleForm', 'toggleForm'],
  ['ui.list.newTrack', 'ui.form.create'],

  // form
  ['ui.form.create', 'ui.list.unselect'],
  ['ui.form.cancel', 'cancelForm'],
  ['ui.form.created', 'uploadDone'],
  ['ui.form.updated', 'ui.list.update'],
  ['ui.form.updated', 'updateForm'],
  ['ui.form.ui.new.click', 'setUploaded'],
  ['ui.form.toggleForm', 'toggleForm'],
  ['ui.form.helper', 'showHelper'],

  // navi
  ['ui.menu-navi.click', 'ui.navi.toggle'],
  ['ui.navi.element.click', 'onNaviSelect']
]


function archipels (cb) {
  fetch('/language/archipels/en.json', {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  }).then((resp) => {
    // console.log('resp', resp)
    return resp.json()
  }).then((list) => {
    // console.log(list)
    if (list.error) {
      console.log('error')
    } else {
      list.shift()
      // console.log('list', list)
      cb(list)
    }
  }).catch((error) => {
    console.log('error', error)
  })
}

export default archipels

// search control

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Layout from '../layout/index'
import Element from 'material/src/element'
import Button from 'material/src/button'

const defaults = {
  class: 'upload',
  route: '/track/',
  method: 'post',
  layout: [
    [Element, 'surface', { class: 'surface' },
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title' }],
        [Element, 'info', { class: 'info' }]
      ],
      [Element, { class: 'body' },
        [Element, { class: 'progress' },
          [Element, 'bar', { class: 'bar' }],
          [Element, 'percent', { class: 'percent' }]
        ],
        [Element, 'bytes', { class: 'bytes' }]
      ],
      [Element, 'foot', { class: 'foot' },
        [Element, { class: 'divider' }],
        [Button, 'cancel', { text: 'Cancel', style: 'outline' }]
      ]
    ]
  ],
  events: [
    ['ui.cancel.click', 'cancel']
  ]
}

class Upload {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    // console.log('new upload')
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach)

    this.build()
    this.attach()
    this.render()
    this.setup()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    // console.log('build')
    this.element = document.createElement('div')
    this.element.classList.add('control')
    this.element.classList.add('upload')

    if (this.options.class !== 'upload') {
      this.element.classList.add(this.options.class)
    }

    this.layout = new Layout(this.options.layout, this.element)
    this.ui = this.layout.component

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    return this
  }

  render () {
    // console.log('render')
    this.ui.title.innerHTML = this.options.title
    this.ui.info.innerHTML = this.options.data.field.title

    if (this.options.data.field.artist) {
      this.ui.info.innerHTML = this.ui.info.innerHTML + ' — ' + this.options.data.field.artist
    }
  }

  setup () {
    const data = this.options.data
    const system = data.system
    const field = data.field
    const file = data.file

    // console.log('setup', file)

    const formData = new FormData()

    if (system) {
      for (const name in system) {
        if (system.hasOwnProperty(name)) {
          if (system[name] && system[name] !== '') {
            // console.log('name', name, system[name])
            formData.append(name, system[name])
          }
        }
      }
    }

    // append fields
    for (const name in field) {
      if (field.hasOwnProperty(name)) {
        if (field[name] && field[name] !== '') {
          // console.log('name', name, typeof field[name])
          formData.append(name, field[name])
        }
      }
    }

    // append file
    for (const name in file) {
      // console.log('file', name)
      if (file.hasOwnProperty(name)) {
        if (file[name]) {
          formData.append(name, file[name])
        }
      }
    }

    this.upload(formData)
  }

  upload (formData) {
    // console.log('start', formData)

    const oReq = new XMLHttpRequest()

    // events
    oReq.addEventListener('load', (e) => {
      console.log('load status', oReq.status)
      if (oReq.status < 400) {
        this.success(e)
      } else if (oReq.status == 500) {
        this.error('Error 500: Internal Server Error')
      } else {
        this.error('Request failed: ' + req.statusText)
      }
    }, false)

    oReq.addEventListener('error', (e) => {
      this.error('Network error')
    }, false)
    // oReq.addEventListener('abort', () => this.cancel(), false)

    // upload starts
    oReq.upload.addEventListener('loadstart', (e) => {
      this.start(e)
    }, false)
    // progress
    oReq.upload.addEventListener('progress', (e) => {
      console.log('status', oReq.status)
      this.progress(e)
    }, false)

    // oReq.addEventListener('readystatechange', function (e) {
    //   self.dispatch(this.readyState, this.status)
    // }, false)

    // open
    oReq.open(this.options.method, this.options.route, true)

    // header
    oReq.setRequestHeader('Cache-Control', 'no-cache')
    oReq.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    // send
    oReq.send(formData)

    this.upload = oReq

    window.onbeforeunload = () => {
      return 'An track upload is still running... Are you sure?'
    }
  }

  dispatch (e, readyState, status) {
    // console.log('dispatch', readyState, status)
    if (readyState === 4) {
      console.log('status', status)
      switch (status) {
        case '200':
          this.success(e)
          break
        case '400':
          this.error(400)
          break
        case '406':
          this.error(406)
          break
        case '410':
          this.error(410)
          break
        default:
          console.log('default status', status)
          // this.error()
          break
      }
    }
  }

  start (e) {
    this.ui.percent.innerHTML = 'Transferring data ...'
  }

  progress (e) {
    // console.log('... loaded total', e.loaded, e.total)

    if (e.lengthComputable) {
      this.renderProgress(e.loaded, e.total)
    } else {
      console.log('length not computable')
    }
  }

  renderProgress (loaded, total) {
    // console.log('displayProgress', loaded, total)
    const percent = (loaded / total) * 100

    // info
    this.ui.bytes.innerHTML = this.formatBytes(loaded) + ' bytes of ' + this.formatBytes(total)

    // progressbar
    this.ui.bar.style.width = percent + '%'
    this.ui.percent.innerHTML = Math.round(percent) + '%'
  }

  formatBytes (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')
  }

  success (e) {
    // console.log('success', e.target.response)
    const info = JSON.parse(e.target.response)
    window.onbeforeunload = null
    this.emit('success', info)
  }

  cancel () {
    // console.log('cancel')
    window.onbeforeunload = null
    this.upload.abort()
    this.emit('cancel')
  }

  error (err) {
    console.log('error', err)
    window.onbeforeunload = null
    this.emit('error', err)
  }

  destroy () {
    this.options.container.removeChild(this.element)
  }
}

export default Upload

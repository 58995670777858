import info from '../data/ranking'

function ranking (score) {
  score = parseInt(score, 10)
  let title = ''
  if (score > 0) {
    for (let i = 0; i < info.length; i++) {
      if (info[i][0] < score) {
        title = info[i][1]
      }
    }
  }

  return title
}

export default ranking

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Element from 'material/src/element'
import Text from 'material/src/text'
import DateHour from 'material/src/datehour'
// import Ranking from '../field/ranking'

import create from 'material/src/list/create'
import add from 'material/src/list/add'
import build from 'material/src/list/build'
import status from 'material/src/list/status'
import render from 'material/src/list/render'
import select from 'material/src/list/select'
import load from 'material/src/list/load'
import request from 'material/src/list/request'
import image from 'material/src/list/image'
import search from 'material/src/list/search'
import click from 'material/src/list/click'

// import hotkeys from 'hotkeys-js'

const defaults = {
  class: 'activity',
  tag: 'div',
  pagination: true,
  // debug: true,
  list: {
    size: 25,
    page: 1
  },
  loading: 'dynamic',
  virtual: true,
  store: true,
  route: {
    list: '/activity/list',
    count: '/activity/count',
    search: '/activity'
  },
  image: {
    format: 'thumb/'
  },
  fields: ['pseudonym', 'username', 'enabled', 'created'],
  layout: {
    main: [
      // [Element, 'head', { class: 'head' },
      //   [Text, 'title', { text: 'Activity' }],
      //   [Element, 'divider', { class: 'divider' }],
      //   [Button, 'search', { icon: iconSearch, type: 'action' }]
      // ],
      // [Element, 'search-input', { tag: 'input', type: 'text', class: 'search-input' }],
      [Element, 'body', { class: 'body' }],
      [Element, 'foot', { class: 'foot' },
        [Element, 'count', { class: 'count' }]
        // [Divider, 'divider', { flex: 1 }],
        // [Button, 'load', { text: 'more...' }]
      ]
    ],
    item: [
      [Element, 'group', { class: 'group' },
        [DateHour, 'info.date', { class: 'date' }],
        [Text, 'info.name', { class: 'name' }]
      ]
    ]
  },
  events: [
    ['ui.search.click', 'toggleSearch'],
    ['ui.search-input.input', 'search'],
    ['ui.load.click', 'load'],
    ['ui.body.click', 'click']
  ]
}

class List {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    // this.options = options

    Object.assign(this, emitter, attach, create, click,
      build, request, render, add, load, select, image, search, status
    )

    this.init()

    return this
  }

  init () {
    // console.log('init')
    this.page = this.options.page

    this.build()
    this.attach()
    this.request()
  }

  set (info) {
    if (!info) return
    // console.log('set', info.user_id)

    this.user_id = info.user_id

    this.request()
  }

  params () {
    // console.log('info', this.iser_id)
    let params = ''

    if (this.user_id) {
      params = 'user_id=' + this.user_id
    }

    return params
  }
}

export default List

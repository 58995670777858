// module
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

// app
import context from '../app/context'
import display from '../app/display'
import build from '../app/build'

// components
import Element from 'material/src/element'
import Button from 'material/src/button'
import Drawer from 'material/src/drawer'

// view
import List from './list'
import Form from './form'
import User from './user'

import iconNavi from '../icon/navi.svg'
// import iconMore from '../icon/more.svg'

const TITLE = 'Awards'

const defaults = {
  name: 'Medals',
  class: 'Awards',
  tag: 'div',
  layout: [
    [Element, { class: 'appbar' },
      [Button, 'menu-navi', { class: 'navi', icon: iconNavi, size: 'small', style: 'action' }],
      [Element, { class: 'title', text: TITLE }]
    ],
    [Drawer, 'navi', { css: 'drawer-persistent', type: 'persistent' }

    ],
    [List, 'list', {}],
    [User, 'user', {}],
    [Form, 'form', {}]
  ],
  events: [
    // layout
    ['ui.menu-navi.click', 'ui.navi.toggle'],

    // list
    ['ui.list.select', 'ui.form.set'],
    ['ui.list.select', 'select'],

    // form
    ['ui.form.created', 'ui.list.add'],
    ['ui.form.updated', 'ui.list.update'],
    ['ui.form.deleted', 'ui.list.remove']
  ]
}

class App {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, attach, emitter, build, context, display)

    this.build()
    this.attach()

    return this
  }

  cancelForm () {
    // console.log('cancelForm')
    if (this.ui.form.mode === 'create') {
      // console.log('form mode create')
      this.ui.list.createCancel()
    }
  }

  select (info) {
    console.log('select', info)

    this.ui.user.medal = info
    this.ui.user.request()
  }

  image (info, layout) {
    // console.log('image icon', info, layout)
    if (info && info.uuid) {
      const image = this.options.path + 'icon/' + info.uuid + '.' + info.ext.icon
      const r = Math.floor(Math.random() * 100000) + 1
      layout.get('image').style.backgroundImage = 'url("' + image + '?' + r + '")'
    }
  }
}

export default App

'use strict'

import ranking from '../module/ranking'

const defaults = {
  class: 'ranking',
  tag: 'div'
}

class Ranking {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    this.build()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement(this.options.tag)
    this.element.classList.add(this.options.class)

    this.ui = {}

    if (this.options.isocode) {
      this.set(this.options.isocode)
    }

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    return this
  }

  set (score) {
    // console.log('set', score)

    this.element.innerHTML = score + ' / ' + ranking(score)
  }
}

export default Ranking

// module
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'
import mediator from 'material/src/module/mediator'

// list
import create from 'material/src/list/create'
import add from 'material/src/list/add'
import build from 'material/src/list/build'
import render from 'material/src/list/render'
import remove from 'material/src/list/remove'
import filter from 'material/src/list/filter'
import select from 'material/src/list/select'
import load from 'material/src/list/load'
import request from 'material/src/list/request'
import image from 'material/src/list/image'
import status from 'material/src/list/status'
import click from 'material/src/list/click'
import search from 'material/src/list/search'
import update from 'material/src/list/update'

// component
import Element from 'material/src/element'
import Text from 'material/src/text'
import Button from 'material/src/button'
import Search from 'material/src/search'
import Ranking from '../field/ranking'
import Image from '../field/image'
import Email from '../field/email'
import Badge from '../field/badge'
import Country from '../field/country'
import TimeAgo from '../field/timeago'
import File from '../field/file'
import Icon from '../control/icon'
import Countries from '../control/country'
import Year from '../control/year'

import Moods from '../control/moods'
// import Play from '../selector/play.js'

// icon
import iconForm from '../icon/form.svg'
import iconSearch from '../icon/search.svg'
import iconFilter from '../icon/filter.svg'
import iconCancel from '../icon/cancel.svg'

import iconOnAir from '../icon/onair.svg'
import iconPosted from '../icon/inbox.svg'
import iconValidate from '../icon/validate.svg'
import iconEdit from '../icon/edit.svg'
import iconConfirmation from '../icon/confirmation.svg'
import iconDuplicate from '../icon/duplicate.svg'
import iconDelete from '../icon/trash.svg'
import iconDownload from '../icon/download.svg'
import iconBroken from '../icon/problem.svg'

import iconReject from '../icon/close.svg'

// import hotkeys from 'hotkeys-js'

const defaults = {
  class: 'track',
  info: 'track',
  // debug: true,
  pagination: true,
  list: {
    size: 25,
    page: 1
  },
  count: true,
  loading: 'dynamic',
  virtual: true,
  item: {
    height: 112,
    new: {
      title: 'New Track'
    }
  },
  updatePosition: true,
  route: {
    list: '/track/list',
    count: '/track/count',
    search: '/track/'
    // validate: '/track/posted/validate',
    // reject: '/track/posted/reject'
  },
  fields: ['title', 'artist', 'album', 'year', 'country'],
  layout: {
    main: [
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title', name: 'title' }],
        [Element, 'divider', { class: 'divider' }],
        // [Button, 'new', { class: 'new', text: 'upload', size: 'small' }],
        [File, 'upload', { label: 'upload', size: 'small', color: 'primary', multiple: true, accept: 'audio/x-m4a,audio/*' }],
        [Button, 'search', { class: 'search', icon: iconSearch, size: 'small', style: 'action' }],
        [Button, 'filter', { class: 'filter', icon: iconFilter, size: 'small', style: 'action' }],
        [Button, 'form', { icon: iconForm, size: 'small', style: 'action' }]
      ],
      [Element, 'filter-input', { class: 'filter-input' },
        [Icon, { src: iconFilter }],
        [Countries, 'filter.country', { label: 'Country', class: 'country' }],
        [Year, 'filter.decade', { label: 'Decade', class: 'decade', decade: true }],
        [Moods, 'filter.mood', { label: 'Mood', class: 'moods', allowUnselect: true }],
        // [Textfield, 'info.label', { label: 'Label' }],
        [Element, { class: 'divider' }],
        [Button, 'filter-clear', { class: 'clear', icon: iconCancel }]
      ],
      [Search, 'search-input', { icon: iconSearch, minChar: 3 }],
      [Element, 'body', { class: 'body' }],
      [Element, 'foot', { class: 'foot' },
        [Element, 'count', { class: 'count' }]
      ]
    ],
    item: [
      [Element, { class: 'body' },
        [Image, 'info.cover', { class: 'image', format: 'thumb', generic: '/img/generic/nocover.svg' }],
        [Element, 'content', { class: 'content' },
          [Element, { class: 'group' },
            [Element, 'info.title', { class: 'title' }],
            [Country, 'info.country', { class: 'country' }],
            [Element, 'info.year', { class: 'year' }],
            [Element, 'info.count', { class: 'count' }]
          ],
          [Element, { class: 'group' },
            [Element, 'info.artist', { class: 'artist' }],
            [Element, { class: 'separator', text: ' — ' }],
            [Element, 'info.album', { class: 'album' }],
            [Badge, 'info.duplicate_id', { class: 'duplicate', text: 'duplicate' }]
          ],
          [Element, 'action', { class: 'action' },
            [Button, 'onair', { class: 'onair', size: 'small', style: 'action', title: 'onair', icon: iconOnAir, data: { name: 'onair' } }],
            [Button, 'validate', { class: 'validate', size: 'small', style: 'action', title: 'validate', icon: iconValidate, data: { name: 'validate' } }],
            [Button, 'rework', { class: 'rework', size: 'small', style: 'action', title: 'need rework', icon: iconEdit, data: { name: 'rework' } }],
            [Button, 'confirmation', { class: 'confirmation', size: 'small', title: 'need confirmation', style: 'action', icon: iconConfirmation, data: { name: 'confirmation' } }],
            [Button, 'reject', { class: 'reject', size: 'small', style: 'action', title: 'reject', icon: iconReject, data: { name: 'reject' } }],
            [Button, 'duplicate', { class: 'duplicate', size: 'small', title: 'duplicate', style: 'action', icon: iconDuplicate, data: { name: 'duplicate' } }],

            [Button, 'delete', { class: 'delete', size: 'small', title: 'delete', style: 'action', icon: iconDelete, data: { name: 'delete' } }],
            [Element, { class: 'divider' }],
            [Button, 'broken', { class: 'broken', size: 'small', title: 'broken', style: 'action', icon: iconBroken, data: { name: 'broken' } }],
            [Button, 'inbox', { class: 'posted', size: 'small', title: 'inbox', style: 'action', icon: iconPosted, data: { name: 'inbox' } }],
            [Button, 'download', { class: 'download', size: 'small', title: 'download', style: 'action', icon: iconDownload, data: { name: 'download' } }]
          ]
        ],
        [Element, 'profile', { class: 'profile' },
          [Image, 'info.profile.image', { class: 'image', format: 'thumb' }],
          [Element, { class: 'group' },
            [Element, 'info.profile.pseudonym', { class: 'name' }],
            [Country, 'info.profile.country', { class: 'country' }],
            [Ranking, 'info.profile.ranking', { class: 'ranking' }],
            [Email, 'info.contact.email', { class: 'email', link: true }]
          ]
        ]
      ],
      [Element, { class: 'foot' },
        [Element, { class: 'group track' },
          [Element, { text: 'bitrate ' }],
          [Element, 'info.bitrate', { class: 'bitrate' }],
          [Element, { text: ' | length ' }],
          [Element, 'info.length', { class: 'length' }],
          [Element, { text: ' | Label' }],
          [Element, 'info.label', { class: 'label' }],
          [Element, { class: 'divider' }],
          [Text, 'info.status', { class: 'status', label: 'status ' }],
          [TimeAgo, 'info.created.date', { class: 'timeago', text: 'Uploaded ' }]
        ],
        [Element, { class: 'group profile' },
          [TimeAgo, 'info.profile.created', { class: 'registered', text: 'Registered ' }]
        ]
      ]
    ]
  },
  events: [
    ['ui.new.click', 'create'],
    ['ui.body.click', 'click'],
    ['ui.upload.change', 'upload'],
    ['ui.form.click', 'toggleForm'],
    ['ui.search.click', 'toggleSearch'],
    ['ui.search-input.change', 'search'],
    ['ui.search-input.cancel', 'cancelSearch'],
    ['ui.action.click', 'actionClick']
  ]
}

class List {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    Object.assign(this, emitter, attach, create, click,
      build, request, render, add, create, filter, search, update, load, select, remove, image, status
    )

    mediator.installTo(this)

    this.page = this.options.page

    this.build()
    this.attach()
    this.initFilter()
    // this.request()

    return this
  }

  actionClick (e) {
    e.preventDefault()
  }

  selectSearch (e) {
    // console.log('selectSearch', e.target)

    if (e.target === e.currentTarget) return

    const target = '[data-id]'
    let el = e.target
    let i = 0

    while (el && !el.matches(target)) {
      if (i > 7) {
        el = null
        break
      }
      el = el.parentNode
      i++
    }

    if (el && el.dataset.id) {
      this.addTrack(el.dataset.id)
    }
  }

  setStatus (status) {
    // console.log('setScope', scope)
    this.status = status
  }

  newTrack () {
    this.emit('newTrack')
  }

  params () {
    if (this.status !== '') {
      return 'status=' + this.status
    } else {
      return null
    }
  }

  updateCount (data) {
    if (this.ui.count && data.length > 0) {
      this.ui.count.innerHTML = '<b>' + data.length + '</b> items'
    } else {
      this.ui.count.innerHTML = ''
    }
  }

  hide () {
    console.log('hide')
    this.element.classList.add('hide')
  }

  show () {
    console.log('show')
    this.element.classList.remove('hide')
  }

  upload (e) {
    console.log('upload', e)
    this.publish('file', e.target.files, this)
  }

  toggleForm () {
    this.emit('toggleForm')
  }
}

export default List

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Element from 'material/src/element'
import Button from 'material/src/button'
import Textfield from 'material/src/textfield'
import Content from '../content'

import File from '../../control/file'

import build from 'material/src/form/build'
import display from 'material/src/mixin/display'
import data from 'material/src/form/data'
import controller from 'material/src/form/controller'
import set from 'material/src/form/set'
import render from 'material/src/form/render'
import update from 'material/src/form/update'
import submit from 'material/src/form/submit'
import cancel from 'material/src/form/cancel'
import create from 'material/src/form/create'
import remove from 'material/src/form/delete'

const defaults = {
  class: 'island',
  sysinfo: ['_id'],
  action: '/island/',
  // debug: true,
  update: {
    method: 'put'
  },
  create: {
    method: 'post',
    info: {
      name: 'New Content'
    }
  },
  layout: [
    [Element, 'head', { class: 'head' },
      [Element, 'title', { class: 'title', text: 'Island' }],
      [Element, { class: 'divider' }],
      [Button, 'new', { flex: 'none', text: 'new' }]
    ],
    [Element, 'body', { class: 'body' },
      [Element, { class: 'group' },
        [Textfield, 'info.name', { label: 'Name' }],
        [Textfield, 'info.title', { label: 'Title', required: true }]
      ],
      [Element, { class: 'group' },
        [File, 'file.image', { label: 'image', accept: 'image/*' }]
      ],
      [Element, { class: 'group' },
        [Content, 'info.content', { label: 'content' }]
      ]
    ],
    [Element, 'foot', { class: 'foot' },
      [Button, 'delete', { text: 'Delete' }],
      [Element, { class: 'divider' }],
      [Button, 'cancel', { type: 'reset', text: 'Cancel' }],
      [Button, 'submit', { type: 'submit', text: 'Submit', color: 'primary' }]
    ]
  ],
  events: [
    // form
    ['form.submit', 'submit'],
    ['form.reset', 'reset'],

    // action
    ['ui.delete.click', 'del'],
    ['ui.new.click', 'create'],

    // mode
    ['mode', 'changeMode']
  ]
}

class Form {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach, display, controller, create, remove,
      build, data, set, render, submit, update, cancel
    )

    this.init()

    return this
  }

  /**
   * init method
   * @param  {Object} Options
   * @return {Object} Instance
   */
  init () {
    this.container = this.options.container
    this.info = this.options.info

    this.build()
    this.attach()

    this.initController()

    return this
  }
}

export default Form

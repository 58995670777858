import Component from '../component/component'
import Card from '../control/card'
import Text from 'material/src/text'
import Button from 'material/src/button'
import Element from 'material/src/element'
import Toolbar from 'material/src/toolbar'

/**
 * Account Layout
 */
export default [Card, 'account', { display: 'flex', direction: 'vertical' },
  [Component, 'account-info', { flex: 'none', display: 'flex', direction: 'horizontal' },
    [Component, 'image', { flex: 'none' }],
    [Component, 'side', { flex: 1, display: 'flex', direction: 'vertical' },
      [Component, 'info', { flex: '1', display: 'flex', direction: 'vertical' },
        [Text, 'name', { flex: 'none', name: 'name', type: 'type', color: 'primary' }],
        [Text, 'email', { flex: 'none', name: 'email' }]
      ],
      [Toolbar, 'foot', { flex: 'none', display: 'flex', direction: 'horizontal' },
        // [Button, 'settings', { flex: 'none', name: 'profile', icon: iconSettings }],
        [Element, { class: 'divider' }],
        [Button, 'signout', { flex: 'none', name: 'logout', type: 'raised', text: 'Sign out', color: 'primary' }]
      ]
    ]
  ]
]

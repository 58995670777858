// base class
import EventEmitter from 'material/src/mixin/emitter'
// modules
import attach from 'material/src/module/attach'
import mediator from 'material/src/module/mediator'
import build from 'material/src/view/build'
import display from 'material/src/mixin/display'

// components
import Element from 'material/src/element'
import Button from 'material/src/button'
import TabView from 'material/src/tabview'

// views
import List from '../list/tracks'

class Tracks extends EventEmitter {
  static defaults = {
    base: 'view',
    class: 'tracks',
    visible: false,
    layout: [
      [TabView, 'tabview', { },
        [Button, 'close', { class: 'close', style: 'action' }],
        [Element, 'tabs', { class: 'tabs' },
          [Button, 'uploaded-tab', { text: 'On Air', data: { view: 'uploaded' } }],
          [Button, 'liked-tab', { text: 'Uploaded', data: { view: 'liked' } }]

        ],
        [Element, 'indicator', { class: 'indicator' }],
        [Element, 'views', { class: 'views' },
          [List, 'uploaded', { route: { list: '/profile/uploaded/' }, title: 'Last Uploaded', data: { view: 'uploaded' } }],
          [List, 'liked', { route: { list: '/profile/mostliked/' }, title: 'Most Liked', data: { view: 'liked' } }]
        ]
      ]
    ],
    events: [
      ['ui.tabview.ready', 'setup'],
      ['ui.tabview.select', 'select'],
      ['ui.close.element.click', 'hide']
    ]
  }

  constructor (options) {
    super()

    this.init(options)
    this.build()
    this.attach()
    this.emit('ready')
  }

  init (options) {
    this.options = Object.assign({}, Tracks.defaults, options || {})
    Object.assign(this, attach, build, display)
    mediator.installTo(this)

    if (this.options.id) {
      this.id = this.options.id
    }
  }

  setup () {
    // console.log('setup', this.ui.tabview)
    this.view = {}
    const views = this.ui.tabview.views

    for (let i = 0; i < views.length; i++) {
      this.view[views[i]] = this.ui[views[i]]
    }

    this.current = this.view[views[0]]
  }

  select (view) {
    // console.log('select', view)

    if (!this.view) return

    this.current = this.view[view]
    if (this.current.set) {
      this.current.set(this.id)
    }
  }

  set (id) {
    // console.log('set', id, this.current)
    this.current = this.current || this.ui.uploaded
    this.id = id
    if (this.current && this.current.set) {
      this.current.set(id)
    }
  }
}

export default Tracks

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Element from 'material/src/element'
import Button from 'material/src/button'
import Text from 'material/src/text'
import Image from '../field/image.js'
import Search from 'material/src/search'

import create from 'material/src/list/create'
import add from 'material/src/list/add'
import build from 'material/src/list/build'
import render from 'material/src/list/render'
import select from 'material/src/list/select'
import load from 'material/src/list/load'
import status from 'material/src/list/status'
import request from 'material/src/list/request'
import image from 'material/src/list/image'
import search from 'material/src/list/search'
import click from 'material/src/list/click'
import update from 'material/src/list/update'

import iconSearch from '../icon/search.svg'

// import hotkeys from 'hotkeys-js'

const defaults = {
  class: 'medal',
  list: {
    size: 25,
    page: 1
  },
  // debug: true,
  pagination: true,
  count: true,
  loading: 'dynamic',
  item: {
    new: {
      name: 'New Medal'
    }
  },
  route: {
    list: '/medal/list',
    search: '/medal/',
    count: '/medal/count'
  },
  layout: {
    main: [
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title', text: 'List' }],
        [Element, 'divider', { class: 'divider' }],
        [Button, 'search', { class: 'search', icon: iconSearch, style: 'action' }]
      ],
      [Search, 'search-input', { minChar: 3 }],
      [Element, 'search-list', { class: 'body search' }],
      [Element, 'body', { class: 'body' }],
      [Element, 'foot', { class: 'foot' },
        [Element, 'status', { class: 'status' },
          [Element, 'count', { class: 'count' }]
        ]
      ]
    ],
    item: [
      [Image, 'info.image', { class: 'image', format: null }],
      [Element, 'content', { class: 'content' },
        [Element, 'info', { class: 'info' },
          [Text, 'info.name', { class: 'name' }]
        ]
      ]
    ]
  },
  events: [
    ['ui.new.click', 'create'],
    ['ui.body.click', 'click'],
    ['ui.search.click', 'toggleSearch'],
    ['ui.search-input.change', 'search'],
    ['ui.search-input.cancel', 'cancelSearch'],
    ['ui.load.element.click', 'load']
  ]
}

class List {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    // this.options = options

    Object.assign(this, emitter, attach, create, click, update,
      build, request, status, render, add, load, select, image, search
    )

    this.init()

    return this
  }

  init () {
    // console.log('init')
    this.page = this.options.page

    this.build()
    this.attach()
    this.request()
  }
}

export default List

export const plan = {
  plan_GlZx6jwCtPEMnl: {
    type: 'monthly',
    currency: 'usd',
    amount: '4.99'
  },
  plan_GuVpHZnr8fs7kR: {
    type: 'monthly',
    currency: 'eur',
    amount: '4.99'
  },
  plan_GlZx3axsNMzkrV: {
    type: 'yearly',
    currency: 'usd',
    amount: '49.90'
  },
  plan_GuVq25pgP5tTwJ: {
    type: 'yearly',
    currency: 'eur',
    amount: '49.90'
  }
}

export default [
  [10, 'Participation price'],
  [50, 'Bronze digger'],
  [150, 'Silver digger'],
  [300, 'Gold digger'],
  [400, 'Bronze miner'],
  [500, 'Silver miner'],
  [600, 'Gold miner'],
  [700, 'Treasure hunter'],
  [800, 'Archeologist'],
  [900, 'Curator'],
  [1000, 'Patron'],
  [1500, 'God']
]

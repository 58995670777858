'use strict'

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

const defaults = {
  prefix: 'material',
  class: 'content',
  type: 'control',
  tag: 'div',
  events: [
  ]
}

/**
 * Textfield class
 * @class
 */
class Content {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    this.init()
    this.build()
    this.attach()

    return this
  }

  /**
   * init
   * @param  {Object} options The class options
   * @return {Object} The class instance
   */
  init () {
    Object.assign(this, emitter, attach)

    return this
  }

  /**
   * [build description]
   * @return {Object} The class instance
   */
  build () {
    // create a new div as input element
    const tag = this.options.tag || 'div'
    this.element = document.createElement(tag)
    this.element.classList.add(this.options.prefix + '-' + this.options.class)

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }
  }

  set (value) {
    this.element.innerHTML = value

    if (!this.editor) {
      this.initEditor(this.element)
    } else {
      if (value) {
        this.editor.setData(value)
      } else {
        this.editor.setData('')
      }
    }
  }

  get () {
    return this.element.innerHTML
  }

  initEditor (content) {
    // console.log('initEditor', content)
    InlineEditor
      .create(content)
      .then(editor => {
        this.editor = editor
        editor.model.document.on('change:data', (evt, data) => {
          // console.log('change', data)
          this.emit('change')
        })
      })
      .catch(error => {
        console.error(error)
      })
  }
}

export default Content


// module
import attach from 'material/src/module/attach'
import mediator from 'material/src/module/mediator'

// methods
import init from './init'
import events from './events'
import controller from './controller'
import apps from './apps'
import user from './user'
import play from './play'
import keys from './keys'
import file from './file'
import list from './list'
import menu from './menu'

// options
import defaults from './options'

class App {
  static defaults = defaults

  constructor (options) {
    // console.log('constructor')
    // merge defaults to the given options
    this.options = [App.defaults, options].reduce(Object.assign, {})
    // assing modules

    Object.assign(
      this, init, events, attach, controller,
      apps, user, play, keys, file, list, menu
    )

    mediator.installTo(this)

    // init
    this.init()
    // bind events
    this.attach(this.events)

    return this
  }
}

export default App

import Element from 'material/src/element'
import Button from 'material/src/button'

export default {
  initList (list, apps) {
    this.appsList = document.createElement('div')
    this.appsList.classList.add('appsList')

    document.body.appendChild(this.appsList)

    // console.log('render', this.appsList)
    for (let i = 0; i < list.length; i++) {
      const item = this.renderListItem(list[i])
      // console.log('item', item)
      this.appsList.appendChild(item.element)
    }
  },

  renderListItem (info) {
    let item

    if (info.type === 'separator') {
      // console.log('render', info);
      item = new Element({ class: 'divider' })
    } else {
      const abbr = info.name.substr(0, 2)
      item = new Button({
        container: this.appsList,
        label: abbr,
        text: info.name,
        name: info.name,
        class: 'item'
      }).on('click', () => {
        this.selectApp(info.name)
      })
    }

    return item
  },

  showApplist () {
    if (this.appsList.classList.contains('show')) {
      this.appsList.classList.remove('show')
    } else {
      this.appsList.classList.add('show')
    }
  }
}

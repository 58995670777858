// import module
import system from './module/system'

// layout ui
import Layout from './layout/index.js'
import layout from './layout'

export default {

  /**
   * Init front application
   * @return {[type]} [description]
   */
  init () {
    // this.initWorker()
    this.initLayout()
    this.initApi(() => {
      this.initVars()
      this.isAuthenticated()
      this.initController()
      this.initKeys()
    })
  },

  initStart () {
    // console.log('initStart', this.options.apps, this.layout.get('body'))

    this.initApps(this.apps)
    this.initDesk()
  },

  initApi (cb) {
    fetch('/content/api', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((api) => {
      // console.log(api)
      system.set('api', api)
      cb()
    })
  },

  /**
   * Init service worker
   * @return {[type]} [description]
   */
  initWorker () {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/serviceworker.js')
      // .then(function (registration) {
      //   console.log('./serviceworker.js registration successful with scope: ', registration.scope)
      // })
    }
  },

  /**
   * [initDesk description]
   * @return {[type]} [description]
   */
  initDesk () {
    let timer
    let long = null

    this.ui.apps.element.addEventListener('mousedown', (ev) => {
      // console.log('mousedown')

      timer = window.setTimeout(() => {
        long = true
        this.appsList.classList.remove('show')
        document.body.classList.add('launchpad')
        this.showApps()
      }, 500)
    })

    this.ui.apps.element.addEventListener('mouseup', (ev) => {
      clearTimeout(timer)

      if (!long) {
        this.showApplist()
      } else {
        long = null
      }
    })
  },

  /**
   * Init front app variables
   * @return {[type]} [description]
   */
  initVars () {
    this.apps = this.options.apps
  },

  /**
   * Create layout and init views and controls
   */
  initLayout () {
    console.log('initLayout')
    document.body.classList.add('desk')
    this.layout = new Layout(layout, document.body)
    this.ui = this.layout.component

    console.log('ui', this.ui)
  }
}

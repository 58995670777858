import mediator from 'material/src/module/mediator'

export default {

  initIslands () {
    this.islandsLayer = L.layerGroup().addTo(this.map)

    this.updateObject = {}

    this.setIslands()
  },

  setIslands () {
    fetch('/island/list?page=1&size=1000&onmap=true', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'get'
    }).then((resp) => {
      return resp.json()
    }).then((list) => {
      // console.log('list', list)
      this.setMarkers(list)
    })
  },

  setMarkers (list) {
    // console.log('setIslandsMarker')
    // console.log('list', list)

    // var ratio = this.getRatio()

    this.setIslandsMarker(list)
  },

  setIslandsMarker (list) {
    // console.log('setIslandsMarker', list)
    this.removeIslandMarker()

    // console.log('setIslandsMarker', this.options.island.marker.display)
    if (this.options.island.marker.display === false) return

    this.islandList = []
    this.islandInfo = {}
    this.islandMarker = {}

    for (let i = 0; i < list.length; i++) {
      const info = list[i]
      this.setMarker(info)
    }
  },

  setMarker (info) {
    // console.log('setMarker', info)

    // if (this.islandMarker[info._id]) {
    //   this.selectMarker(this.islandMarker[info._id])
    //   return
    // }

    // console.log('info', info._id)
    this.islandInfo[info._id] = info
    this.islandList.push(this.islandInfo[info._id])

    // console.log('ratio', ratio, [info.size.y * ratio, info.size.x * ratio])
    // console.log('info', info)

    // console.log('pos', pos)
    // console.log('size', size)

    const pos = this.initPos(info.pos)
    const size = this.initSize(info.size)

    // console.log('info', info.name)

    let url = '/icon/map/marker/icon.svg'

    if (info.marker) {
      url = this.asset.url + '/' + info.marker.path + info.marker.filename
    }

    // console.log('url')

    const icon = L.icon({
      iconUrl: url,
      iconSize: [size.x, size.y],
      className: 'island'
    })

    const marker = L.marker(this.map.unproject([pos.x, pos.y], 4), {
      icon,
      draggable: true
    }).addTo(this.islandGroup)

    marker._id = info._id

    const el = marker.getElement()
    el.dataset.island = info.name
    el.dataset.id = info._id

    this.setMarkerEvents(marker, el)

    if (info._id === this.options.id) {
      this.ui.selector.setTarget(el)
    }

    this.islandMarker[info._id] = marker

    return marker
  },

  setMarkerEvents (marker, el) {
    marker.on('dragend', (e) => {
      const pos = this.map.project(marker.getLatLng(), 4)
      this.ui.selector.setTarget(el)
      this.ui.cancel.enable()
      this.ui.apply.enable()
      this.updateMarkerPosition(marker._id, pos)
    })

    marker.on('drag', (e) => {
      this.ui.selector.update()

      const pos = this.getMarkerPosition(marker)
      // console.log('pos', pos)
      this.updateSizeAndPos({
        pos
      })
    })

    marker.on('mousedown', (e) => {
      this.selectMarker(marker)
    })
  },

  getMarkerPosition (marker) {
    const pos = this.map.project(marker.getLatLng(), 4)

    return {
      x: parseInt(pos.x, 10),
      y: parseInt(pos.y, 10)
    }
  },

  addMarker (info) {
    // console.log('setMarker', info.name, this.info)

    if (this.islandMarker[info._id]) {
    // console.log('select mnarker')
      this.selectMarker(this.islandMarker[info._id])
      this.focusMarker(this.islandMarker[info._id])
      return
    } else {
      // console.log('hide selector')
      this.ui.selector.hide()
    }

    this.updateObject = this.updateObject = {}

    const id = info._id

    info.pos = this.initPos(info.pos)
    info.size = this.initSize(info.size)
    info.onmap = true

    if (!this.updateObject[id]) this.updateObject[id] = {}
    this.updateObject[id].pos = info.pos
    this.updateObject[id].size = info.size
    this.updateObject[id].uuid = info.uuid
    this.updateObject[id].enabled = info.enabled
    this.updateObject[id].onmap = info.onmap

    const marker = this.setMarker(info)
    this.selectMarker(marker)
    this.ui.apply.enable()
  },

  selectMarker (marker, mute) {
    // console.log('select', marker)
    this.marker = marker
    const el = marker.getElement()
    const info = this.islandInfo[marker._id]
    // console.log('info', info)
    this.ui.selector.setTarget(el)
    this.ui.name.innerHTML = info.name
    this.ui.remove.enable()

    this.updateSizeAndPos(info)

    if (!mute) this.emit('select', info)
  },

  updateSizeAndPos (info) {
    if (info.pos) {
      this.ui['pos.x'].set(info.pos.x)
      this.ui['pos.y'].set(info.pos.y)
    }

    if (info.size) {
      this.ui['size.x'].set(info.size.x)
      this.ui['size.y'].set(info.size.y)
    }
  },

  selectById (id) {
    const marker = this.islandMarker[id]

    if (marker) {
      this.selectMarker(marker)
      this.focusMarker(marker)
    }
  },

  updateMarkerPosition (id, pos) {
    // console.log('updateMarkerPosition', id, pos)
    if (!this.updateObject[id]) this.updateObject[id] = {}
    if (!this.updateObject[id].pos) this.updateObject[id].pos = { }
    if (!this.updateObject[id].uuid) this.updateObject[id].uuid = this.islandInfo[id].uuid
    if (!this.updateObject[id].enabled) this.updateObject[id].enabled = this.islandInfo[id].enabled

    const x = parseInt(pos.x, 10)
    const y = parseInt(pos.y, 10)

    this.ui['pos.x'].set(x)
    this.ui['pos.y'].set(y)

    this.updateObject[id].pos.x = x
    this.updateObject[id].pos.y = y
    this.updateObject[id].onmap = true

    // console.log('updateObject', this.updateObject)
  },

  updateMarkerSize (id, size) {
    // console.log('updateMarkerSize', id, size)
    if (!this.updateObject[id]) this.updateObject[id] = {}
    if (!this.updateObject[id].size) this.updateObject[id].size = { }
    if (!this.updateObject[id].uuid) this.updateObject[id].uuid = this.islandInfo[id].uuid
    if (!this.updateObject[id].enabled) this.updateObject[id].enabled = this.islandInfo[id].enabled

    const x = parseInt(size.x, 10)
    const y = parseInt(size.y, 10)

    this.ui['size.x'].set(x)
    this.ui['size.y'].set(y)

    this.updateObject[id].size.x = x
    this.updateObject[id].size.y = y
    this.updateObject[id].onmap = true
    // console.log('updateObject', this.updateObject)
  },

  cancelChanges () {
    // console.log('cancelChanges')
    this.updateObject = {}
    // this.ui.selector.hide()

    const id = this.marker._id
    this.setIslandsMarker(this.islandList)

    const el = this.islandMarker[id].getElement()
    if (el) {
      this.ui.selector.setTarget(el)
    }

    this.ui.cancel.disable()
    this.ui.apply.disable()
  },

  updateMarkerInfo (info) {
    if (!this.islandInfo[info._id]) return
    this.islandInfo[info._id].pos.x = info.pos.x
    this.islandInfo[info._id].pos.y = info.pos.y
    this.islandInfo[info._id].size.x = info.size.x
    this.islandInfo[info._id].size.y = info.size.y
  },

  unSelectMarker () {
    this.ui.selector.hide()
    this.ui.remove.disable()
    this.marker = null

    this.ui.name.innerHTML = ''
    this.ui['size.x'].set('')
    this.ui['size.y'].set('')
    this.ui['pos.x'].set('')
    this.ui['pos.y'].set('')
  },

  initPos (pos) {
    if (!pos ||
      pos.x === null ||
      pos.y === null ||
      pos === '[object Object]') {
      pos = {
        x: this.size.x / 2,
        y: this.size.y / 2
      }
    }

    return pos
  },

  initSize (size) {
    if (!size ||
      size.x === null ||
      size.y === null ||
      size === '[object Object]') {
      size = {
        x: 30,
        y: 30
      }
    }

    return size
  },

  focusMarker (marker) {
    // console.log('focusMarker', marker)
    const pos = marker.getLatLng()

    // console.log('pos', pos)
    this.map.panTo([pos.lat, pos.lng], 4, {
      duration: 1.5
    })
  },

  removeMarker () {
    // console.log('removeMarker')
    this.islandGroup.removeLayer(this.marker)

    const info = this.islandInfo[this.marker._id]

    delete this.updateObject[this.marker._id]
    delete this.islandMarker[this.marker._id]
    delete this.islandInfo[this.marker._id]

    for (let i = this.islandList.length - 1; i >= 0; i--) {
      if (this.islandList[i]._id === this.marker._id) {
        this.islandList.splice(i, 1)
      }
    }

    if (info.onmap === true) {
      // console.log('updateChange')
      info.onmap = false
      this.updateChange(info._id, info)
    }

    mediator.publish('marker.removed', this.marker._id)
    this.unSelectMarker()
  },

  removeIslandMarker () {
    this.islandGroup.clearLayers()
    this.islandMarker = {}
  },

  onMarkerResize (width, height) {
    // console.log('markerResize', width, height, this.marker)
    const icon = this.marker.options.icon
    icon.options.iconSize = [width, height]
    this.marker.setIcon(icon)
    this.ui.selector.update()

    this.ui.cancel.enable()
    this.ui.apply.enable()

    this.updateMarkerSize(this.marker._id, {
      x: width,
      y: height
    })
  }
}

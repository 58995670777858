import emitter from 'material/src/module/emitter'

const defaults = {
  class: 'switcher',
  tag: 'div',
  mode: 'unique'
}

class Switcher {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter)

    this.build()
    this.attach()

    return this
  }

  build () {
    this.element = document.createElement(this.options.tag)
    this.element.classList.add(this.options.class)

    this.label = document.createElement('label')
    this.label.innerHTML = this.options.label
    this.element.appendChild(this.label)

    if (this.options.size) {
      this.element.classList.add(this.options.size + '-size')
    }

    if (this.options.inline === true) {
      this.element.classList.add('inline')
    }

    this.buildList()

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }
  }

  buildList () {
    this.list = document.createElement('ul')
    this.element.appendChild(this.list)

    for (let i = 0; i < this.options.list.length; i++) {
      const item = document.createElement('li')
      item.classList.add('item')
      item.dataset.switcher = this.options.list[i]
      item.innerHTML = this.options.list[i]
      this.list.appendChild(item)
    }
  }

  attach () {
    this.element.addEventListener('click', (event) => {
      if (!event.target.dataset.switcher) return
      this.select(event.target)
    })
  }

  select (option) {
    if (this.options.mode === 'multiple') {
      this.multiple(option)
    } else {
      this.unique(option)
    }
  }

  unique (option) {
    if (!option.classList.contains('selected')) {
      const options = this.list.childNodes

      for (let i = 0; i < options.length; i++) {
        options[i].classList.remove('selected')
      }

      option.classList.add('selected')
      this.selected = [option.dataset.switcher]
      this.emit('change', this.selected)
    }
  }

  multiple (option) {
    if (option.classList.contains('selected')) {
      if (this.selected.length === 1) return
      const i = this.selected.indexOf(option.dataset.switcher)
      this.selected.splice(i, 1)
      option.classList.remove('selected')
    } else {
      option.classList.add('selected')
      this.selected.push(option.dataset.switcher)
    }

    this.emit('change', this.selected)
  }

  set (arr) {
    arr = arr || []
    const list = this.options.list
    this.selected = []
    for (let i = 0; i < list.length; i++) {
      this.element.querySelector('[data-switcher="' + list[i] + '"]').classList.remove('selected')
      if (arr.indexOf(list[i]) !== -1) {
        this.selected.push(list[i])
        this.element.querySelector('[data-switcher="' + list[i] + '"]').classList.add('selected')
      }
    }
  }

  get () {
    console.log('get', this.selected)
    return this.selected
  }
}

export default Switcher

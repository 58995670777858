// defaults options

// import App from './view/app'
import Tracks from './tracks/app'
import Islands from './islands/app'
import Accounts from './accounts/app'
import Medals from './medals/app'
import Strapi from './strapi/app'
import Dashboard from './dashboard/app'
import Translation from './translation/app'

const options = {
  apps: [
    {
      name: 'tracks',
      app: Tracks
    }, {
      name: 'islands',
      app: Islands
    }, {
      name: 'awards',
      app: Medals
    }, {
      name: 'accounts',
      app: Accounts
    }, {
      name: 'dashboard',
      app: Dashboard
    }, {
      name: 'translation',
      app: Translation
    }, {
      name: 'strapi',
      app: Strapi
    }
  ]
}

export default options

import cookie from './cookie'

const asset = {
  set: (asset) => {
    cookie.set('asset', JSON.stringify(asset), 365)
    return asset
  },
  get: (name) => {
    if (cookie.get('asset')) {
      return JSON.parse(cookie.get('asset'))
    } else {
      return { url: document.body.dataset.asset }
    }
  }
}

export default asset

// import modules
import create from 'material/src/mixin/create'
import insert from 'material/src/mixin/insert'
// import components
import Layout from '../layout/index'

const defaults = {
  prefix: 'material',
  class: 'card',
  tag: 'div'
}

class Card {
  constructor (options) {
    this.init(options)
    this.build()
  }

  init (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, insert)
  }

  /**
   * build the component using the super method
   * @return {Object} The class instance
   */
  build () {
    this.element = create(this.options)

    if (this.options.layout) {
      this.layout = new Layout(this.options.layout, this.element)
    }
  }
}

export default Card

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

// app
import context from '../app/context'
import display from '../app/display'
import build from '../app/build'

// components
import Element from 'material/src/element'
import Button from 'material/src/button'
import Drawer from 'material/src/drawer'

// icons
import iconNavi from '../icon/navi.svg'
// import iconMore from '../icon/more.svg'

// views

import CategoryList from './list/categories'
import ContentList from './list/content'
import CountryList from './list/countries'
import IslandList from '../list/islands'
import LabelList from './list/labels'

import LocalizationList from './list/localization'
import LanguageList from './list/languages'

// import Form from './form'
import LabelForm from './form/label'
import CategoryForm from './form/category'
import ContentForm from './form/content'
import CountryForm from './form/country'
import IslandForm from './form/island'

import LanguageForm from './form/language'

// navi icons
import iconOnAir from '../icon/onair.svg'
import iconPosted from '../icon/inbox.svg'
import iconValidated from '../icon/check.svg'
import iconConfirmation from '../icon/confirmation.svg'
import iconEdit from '../icon/edit.svg'
import iconBroken from '../icon/problem.svg'
import iconRejected from '../icon/close.svg'
import iconUploaded from '../icon/folder.svg'
import iconDeleted from '../icon/trash.svg'
import iconIsland from '../icon/islands.svg'
import iconTranslation from '../icon/app/translation.svg'

// app title
const TITLE = 'Translation'

const defaults = {
  name: 'Translations',
  class: 'translations',
  tag: 'div',
  collections: ['label', 'content', 'category', 'island', 'country', 'language'],
  forms: ['contentForm', 'categoryForm', 'labelForm'],
  layout: [
    [Element, { class: 'appbar' },
      [Button, 'menu-navi', { class: 'navi', icon: iconNavi, size: 'small', style: 'action' }],
      [Element, 'title', { class: 'title', text: TITLE }]
      // [Button, 'menu-more', { icon: iconMore, style: 'action' }]
    ],
    [Drawer, 'navi', { css: 'drawer-persistent', type: 'persistent', open: true },
      [Element, { class: 'group' },
        [Button, 'label', { icon: iconConfirmation, label: 'Label', name: 'label' }],
        [Button, 'content', { icon: iconPosted, label: 'Content', name: 'content' }],
        [Button, 'category', { icon: iconConfirmation, label: 'Categories', name: 'category' }],
        [Button, 'island', { icon: iconConfirmation, label: 'Islands', name: 'island' }],
        [Button, 'country', { icon: iconValidated, label: 'Countries', name: 'country' }],
        [Button, 'ocean', { icon: iconValidated, label: 'Oceans', name: 'ocean' }],
        [Element, { class: 'divider' }],
        [Button, 'language', { icon: iconTranslation, label: 'Laguages', name: 'language', title: 'Languages' }]
      ]
    ],
    // lists
    [Element, 'listView', { class: 'listview' },
      [LabelList, 'labelList', { class: 'label' }],
      [ContentList, 'contentList', { class: 'content' }],
      [CategoryList, 'categoryList', { class: 'category' }],
      [IslandList, 'islandList', { class: 'islands' }],
      [CountryList, 'countryList', { class: 'country' }],

      [LanguageList, 'languageList', { class: 'language' }]
    ],
    // forms
    [Element, 'editview', { class: 'editview' },
      [ContentForm, 'contentForm', { class: 'content' }],
      [CategoryForm, 'categoryForm', { class: 'category' }],
      [LabelForm, 'labelForm', { class: 'label' }],
      [CountryForm, 'countryForm', { class: 'country' }],
      [LanguageForm, 'languageForm', { class: 'language' }],
      [IslandForm, 'islandForm', { class: 'island' }],
      [LocalizationList, 'localizationList', { class: 'localization' }]
    ]
  ],
  events: [
    // layout
    ['ui.menu-navi.click', 'ui.navi.toggle'],

    // menu
    ['ui.content.click', 'showTranslation'],
    ['ui.label.click', 'showTranslation'],
    ['ui.island.click', 'showTranslation'],
    ['ui.category.click', 'showTranslation'],
    ['ui.country.click', 'showTranslation'],
    ['ui.language.click', 'showLanguageList'],

    // list
    ['ui.localizationList.select', 'setLocalizationForm'],
    ['ui.localizationList.fetched', 'OnFetchLocalizationList'],
    ['ui.localizationList.locale', 'setLocale'],

    // form
    ['ui.form.created', 'ui.list.add'],
    ['ui.form.updated', 'ui.list.update'],
    ['ui.form.deleted', 'ui.list.remove']
  ]
}

class App {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, attach, emitter, context, build, display)

    this.build()
    this.setup()
    this.attach()

    return this
  }

  setup () {
    // console.log('setup')

    this.getContext('menu')

    this.collections = this.options.collections

    this.bindEvents()

    this.hideViews()
    this.ui.localizationList.show()

    this.showList(this.context.menu || 'label')
    this.setLocale(this.context.locale || 'fr')
  }

  bindEvents () {
    for (let i = this.collections.length - 1; i >= 0; i--) {
      // console.log('list', this.options.collections[i])
      this.ui[this.collections[i] + 'List'].on('select', (id) => {
        this.selectItem(id, this.collections[i])
      })
    }
  }

  showTranslation (ev) {
    // console.log('showTranslation', ev.target)
    this.showList(ev.target.name)
  }

  showList (name) {
    // console.log('showList', name)
    this.selectMenu(name)
    this.hideViews()
    this.ui[name + 'List'].show()
    this.ui[name + 'List'].request()
    this.ui[name + 'Form'].show()

    this.form = this.ui[name + 'Form']
    this.list = this.ui[name + 'List']
  }

  showLanguageList (ev) {
    // console.log('showLaguageList', ev.target)

    this.selectMenu(ev.target.name)
    this.hideLists()
    this.ui.languageList.show()
    this.ui.languageList.request()
  }

  selectItem (id, collection) {
    // console.log('selectItem', id, collection)
    if (collection === 'language') {
      this.selectLanguage(id)
    } else {
      this.ui.localizationList.related_id = id
      this.ui.localizationList.show()
      this.ui.localizationList.request()
    }
  }

  selectLanguage (id) {
    // console.log('selectLanguage', this.ui.languageList.dataStore[id].code)

    const locale = this.ui.languageList.dataStore[id].code
    this.setLocale(locale)
  }

  setLocale (locale) {
    // console.log('setLocale', locale)

    this.locale = locale
    this.ui.localizationList.setLocale(this.locale)
    this.ui.localizationList.show()
    this.ui.localizationList.request()

    this.setContext('locale', this.locale)
  }

  selectMenu (name) {
    // console.log('selectMenu', name, this.ui.navi)
    for (let i = this.collections.length - 1; i >= 0; i--) {
      // console.log('menu', this.collections[i])
      // console.log('btn', this.ui[this.collections[i]])

      this.ui[this.collections[i]].element.classList.remove('selected')
    }

    const navi = this.ui.navi.element.querySelector('[name=' + name + ']')

    // console.log('navi', navi)

    navi.classList.add('selected')

    this.setContext('menu', name)
  }

  setLocalizationForm (id) {
    // console.log('editLocalization', id)
    this.form.set(id)
  }

  OnFetchLocalizationList (list) {
    // console.log('OnFetchLocalizationList', list, list.length)
    if (list.length === 1) {
      this.form.set(list[0]._id)
      this.ui.localizationList.selectById(list[0]._id)
    }
  }

  hideViews () {
    // console.log('hideViews', this.collections)

    this.hideLists()
    this.hideForms()
  }

  hideLists () {
    // console.log('hideViews', this.collections)

    for (let i = 0; i < this.collections.length; i++) {
      if (this.ui[this.collections[i] + 'List']) { this.ui[this.collections[i] + 'List'].hide() }
    }
  }

  hideForms () {
    // console.log('hideViews', this.collections)

    for (let i = 0; i < this.collections.length; i++) {
      if (this.ui[this.collections[i] + 'Form']) { this.ui[this.collections[i] + 'Form'].hide() }
    }
  }
}

export default App

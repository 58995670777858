import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Element from 'material/src/element'
import Button from 'material/src/button'
import Text from 'material/src/text'
import Image from '../../field/image'
import Search from 'material/src/search'
import Select from 'material/src/select'

import create from 'material/src/list/create'
import display from 'material/src/mixin/display'
import add from 'material/src/list/add'
import build from 'material/src/list/build'
import render from 'material/src/list/render'
import select from 'material/src/list/select'
import load from 'material/src/list/load'
import request from 'material/src/list/request'
import search from 'material/src/list/search'
import click from 'material/src/list/click'
import remove from 'material/src/list/remove'
import update from 'material/src/list/update'

import iconSearch from '../../icon/search.svg'

// import hotkeys from 'hotkeys-js'

const defaults = {
  class: 'localization',
  tag: 'div',
  pagination: true,
  list: {
    size: 100,
    page: 1
  },
  virtual: true,
  image: {
    format: 'thumb/'
  },
  item: {
    height: 60,
    new: {
      name: 'New translation'
    }
  },
  fields: ['name', 'content'],
  itemSwitch: 'type',
  layout: {
    main: [
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title', text: 'Locale' }],
        [Element, 'divider', { class: 'divider' }],
        [Select, 'languages', { }]
        // [Button, 'search', { class: 'search', icon: iconSearch, style: 'action' }]
      ],
      [Element, 'body', { class: 'body' }]
    ],
    item: {
      label: [
        [Element, 'group', { class: 'group' },
          [Element, 'info', { class: 'info' },
            [Text, 'info.data.value', { class: 'name' }]
          ]
        ]
      ],
      category: [
        [Element, 'group', { class: 'group' },
          [Element, 'info', { class: 'info' },
            [Text, 'info.data.name', { class: 'name' }]
          ],
          [Element, 'info', { class: 'info' },
            [Text, 'info.data.info', { class: 'info' }]
          ]
        ]
      ],
      country: [
        [Element, 'group', { class: 'group' },
          [Element, 'info', { class: 'info' },
            [Text, 'info.data.name', { class: 'name' }]
          ]
        ]
      ],
      content: [
        [Element, 'group', { class: 'group' },
          [Element, 'info', { class: 'info' },
            [Text, 'info.data.title', { class: 'name' }]
          ],
          [Element, 'info', { class: 'info' },
            [Text, 'info.data.summary', { class: 'value' }]
          ]
        ]
      ],
      island: [
        [Element, 'group', { class: 'group' },
          [Element, 'info', { class: 'info' },
            [Text, 'info.data.name', { class: 'name' }]
          ],
          [Element, 'info', { class: 'info' },
            [Text, 'info.data.info', { class: 'value' }]
          ]
        ]
      ],
      base: [
        [Element, 'base', { class: 'group' },
          [Element, 'info', { class: 'info' },
            [Text, 'info.type', { class: 'type' }]
          ],
          [Element, 'info', { class: 'info' },
            [Text, 'info.data.name', { class: 'name' }]
          ]
        ]
      ]
    }
  },
  route: {
    list: '/localization/list',
    search: '/localization/',
    count: '/localization/count'
  },
  events: [
    ['ui.body.click', 'click'],
    // ['ui.new.click', 'create'],
    ['ui.languages.change', 'onLocales'],
    ['ui.search.click', 'toggleSearch'],
    ['ui.search-input.change', 'search'],
    ['ui.search-input.cancel', 'cancelSearch']
  ]
}

class List {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    // this.options = options

    Object.assign(this, emitter, attach, create, display, click, update, remove,
      build, request, render, add, load, select, search
    )

    this.page = this.options.page

    this.build()
    this.attach()
    this.setup()

    this.request()

    return this
  }

  setup () {
    fetch('/language/list?order=1', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    }).then((resp) => {
      return resp.json()
    }).then((list) => {
      this.ui.languages.addOption('', '')
      for (let i = 0; i < list.length; i++) {
        this.ui.languages.addOption(list[i].translation, list[i].code)
      }

      if (this.locale) this.ui.languages.set(this.locale)
    })
  }

  onLocales (locale) {
    // console.log('onLocales', locale)
    this.locale = locale
    this.request()
    this.emit('locale', locale)
  }

  setLocale (locale) {
    // console.log('setLocale', locale)
    this.locale = locale
    this.ui.languages.set(locale)
  }

  params () {
    // console.log('params', this.related_id, this.locale)
    let params = ''

    if (this.related_id) { params += '&related_id=' + this.related_id }
    if (this.locale) { params += '&locale=' + this.locale }
    // console.log('params - ', params)
    return params
  }
}

export default List

import system from '../module/system'
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'
import dot from 'material/src/module/dot'
import mediator from 'material/src/module/mediator'
import abbrNum from '../module/abbrnum'

// list
import create from 'material/src/list/create'
import load from 'material/src/list/load'
import click from 'material/src/list/click'
import status from 'material/src/list/status'
import archipels from '../module/archipels'

// import scrollbar from 'material/src/module/scrollbar'
import dataset from 'material/src/module/dataset'
import Loading from 'material/src/loading'

import Layout from '../layout/index'
import Element from 'material/src/element'
import Button from 'material/src/button'
import Text from 'material/src/text'
import Image from '../field/image.js'
import Marker from '../control/marker.js'
import Search from 'material/src/search'
import Bool from '../control/boolean'
import Categories from 'material/src/switcher'
import DateHour from '../control/datehour'

import iconSearch from '../icon/search.svg'
import iconSort from '../icon/sort.svg'
import iconSortAlpha from '../icon/sortalpha.svg'

const defaults = {
  class: 'islands',
  tag: 'div',
  pagination: true,
  virtual: true,
  loading: 'dynamic',
  count: true,
  updatePosition: true,
  list: {
    size: 200,
    page: 1
  },
  image: {
    format: 'thumbs'
  },
  item: {
    height: 64,
    new: {
      name: 'New Island'
    }
  },
  itemSwitch: 'category',
  layout: {
    main: [
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title', text: 'List' }],
        [Element, { class: 'divider' }],
        [Button, 'search', { class: 'search', icon: iconSearch, size: 'small', style: 'action' }],
        [Button, 'sortalpha', { class: 'sort', icon: iconSortAlpha, size: 'small', style: 'action' }],
        [Button, 'sort', { class: 'sort', icon: iconSort, size: 'small', style: 'action' }]
      ],
      [Search, 'search-input', { minChar: 3 }],
      [Element, 'search-list', { class: 'body search' }],
      [Element, 'category', { class: 'categories' },
        [Categories, 'categories', { allowEmpty: false }]
      ],
      [Element, 'body', { class: 'body' }],
      [Element, 'foot', { class: 'foot' },
        [Element, 'count', { class: 'count' }]
      ]
    ],
    item: {
      base: [
        [Text, 'handle', { class: 'handle' }],
        [Image, 'info.icon', { class: 'image', format: null }],
        [Element, 'content', { class: 'content' },
          [Element, { class: 'group col' },
            [Text, 'info.name', { class: 'name' }],
            [Element, { class: 'segment' },
              [Text, 'info.info', { class: 'desc' }]
            ]
          ],
          [Text, 'info.plays', { class: 'plays' }],
          [Bool, 'info.enabled', { class: 'enabled' }]
        ]
      ],
      top10: [
        [Text, 'handle', { class: 'handle' }],
        [Image, 'info.icon', { class: 'image', format: null }],
        [Element, 'content', { class: 'content' },
          [Element, { class: 'group col' },
            [Text, 'info.name', { class: 'name' }],
            [Element, { class: 'segment' },
              [DateHour, 'info.published', { class: 'desc' }]
              // [Text, 'info.info', { class: 'desc' }]
            ]
          ],
          [Text, 'info.plays', { class: 'plays' }],
          [Bool, 'info.enabled', { class: 'enabled' }]
        ]
      ]
    }
  },
  route: {
    list: '/island/list',
    search: '/island'
  },
  events: [
    ['ui.new.click', 'create'],
    ['ui.load.click', 'load'],
    ['ui.search.click', 'toggleSearch'],
    ['ui.sort.click', 'sortOrder'],
    ['ui.sortalpha.click', 'sortOrderAlpha'],
    ['ui.body.click', 'click'],
    ['ui.search-input.change', 'search'],
    ['ui.search-input.cancel', 'cancelSearch'],
    ['ui.categories.change', 'setCategory']
  ]
}

class List {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    // console.log('constructor')
    // merge defaults wifth the given options
    this.options = Object.assign({}, defaults, options || {})

    // assign modules
    Object.assign(this, emitter, attach, click, create,
      load, status
    )

    this.init()

    return this
  }

  init () {
    // console.log('init islands')
    // default var
    this.page = this.options.page
    this.sort = null

    this.build()
    this.setup()
    this.attach()

    mediator.subscribe('marker.dropped', (id) => {
      // console.log('marker.dropped id', id)
      const selector = '[data-id="' + id + '"] .marker'
      // console.log('selector', selector)
      const marker = this.ui.body.querySelector(selector)
      if (marker) { marker.classList.add('hide') }
    })

    mediator.subscribe('marker.removed', (id) => {
      // console.log('marker.removed id', id)
      const selector = '[data-id="' + id + '"] .marker'
      // console.log('selector', selector)
      const marker = this.ui.body.querySelector(selector)
      if (marker) { marker.classList.remove('hide') }
    })
  }

  setup () {
    const list = []
    archipels((categories) => {
      // console.log('list', list)
      for (let i = 0; i < categories.length; i++) {
        list.push(categories[i].category)
      }

      this.ui.categories.setOptions(list)

      if (this.category) {
        this.ui.categories.set(this.category)
      }

      this.request()
    })
  }

  build (data) {
    // console.log('options', this.options.name)

    this.element = document.createElement('div')
    this.element.classList.add('list')

    if (this.options.class) {
      this.element.classList.add(this.options.class)
    }

    this.element.addEventListener('click', (e) => {
      e.stopPropagation()
    })

    if (this.options.name) {
      this.name = this.options.name
    }

    if (this.options.data) {
      dataset(this.element, this.options.data)
    }

    this.layout = new Layout(this.options.layout.main, this.element)
    this.ui = this.layout.component

    // prepare loading
    // this.ui.loading = new Loading()

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    // const scrollbarOffset = scrollbar()

    // // console.log('scrollbarOffset', scrollbarOffset)
    // if (this.ui && this.ui.body) {
    //   // console.log('body padding', 11 - scrollbarOffset + 'px')
    //   this.ui.body.style.marginRight = 11 - scrollbarOffset + 'px'
    //   this.ui.body.classList.add('offset' + scrollbarOffset)
    // }
  }

  selectIsland (info) {
    // console.log('selectIsland', info._id, this.id)
    const category = info.category
    this.category = category
    this.ui.categories.set(category)
    // console.log('category', this.category)

    if (this.id === info._id) return
    this.request(null, null, null, () => {
      // console.log('---- after fetch')

      this.selectItemById(info._id)
      this.emit('set', info._id)
    })
  }

  initContext (category, id) {
    // console.log('initContext', category, id)
    this.category = category
    this.id = id
  }

  setCategory (category) {
    // console.log('setCategory', category)
    if (this.category === category) return
    if (category) {
      this.category = category
    } else this.category = null

    this.emit('categoryChange', category)

    // console.log('category', this.category)
    this.request()
  }

  request (cb) {
    // console.log('request', this.search)

    let route = this.options.route.list + '?'

    if (this.mode === 'search') {
      route = this.options.route.search + '?'
    }

    const params = this.params()
    route += params + '&size=1000'

    // console.log('route', route)

    fetch(route, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    }).then((resp) => {
      return resp.json()
    }).then((list) => {
      this.data = list
      // console.log('list', list.length)
      this.render(list)
      mediator.publish('category.change', {
        category: this.category,
        sort: list.length + 1
      })

      this.statusDisplay('count', list.length)
      if (cb) cb()
    })
  }

  search (keywords) {
    // console.log('search', keywords)

    this.ui.body.innerHTML = ''

    this.emit('searching')

    this.keywords = keywords

    this.request()
  }

  cancelSearch () {
    // console.log('cancel search')
    this.ui.body.innerHTML = ''
  }

  /**
   * [toggleSearch description]
   * @return {[type]} [description]
   */
  toggleSearch (e) {
    e.stopPropagation()
    // console.log('toggle search', this.ui.body)
    if (!this.ui['search-input'].element.classList.contains('show')) {
      this.showSearch()
    } else {
      this.hideSearch()
    }
  }

  /**
   * [showSearch description]
   * @return {[type]} [description]
   */
  showSearch () {
    if (this.hideFilter) {
      this.hideFilter()
    }

    // console.log('showSearch')
    this.mode = 'search'
    this.element.classList.add('search-mode')

    if (this.ui.search) {
      this.ui.search.element.classList.add('selected')
    }

    this.ui.category.classList.add('hide')

    if (this.ui['search-input']) {
      this.ui['search-input'].input.value = ''
      this.ui['search-input'].element.classList.add('show')
      this.ui['search-input'].input.focus()
    }

    this.ui.body.innerHTML = ''

    // this.ui['search-list'].classList.add('show')
    // this.ui.body.classList.add('hide')
  }

  /**
   * [hideSearch description]
   * @return {[type]} [description]
   */
  hideSearch (notfetch) {
    // console.log('hideSearch')
    this.mode = 'standard'
    this.keywords = null
    this.element.classList.remove('search-mode')

    if (this.ui.search) {
      this.ui.search.element.classList.remove('selected')
    }
    if (this.ui['search-input']) {
      this.ui['search-input'].element.classList.remove('show')
      this.ui['search-input'].input.value = ''
    }

    this.ui.category.classList.remove('hide')

    // this.ui['search-list'].classList.remove('show')
    // this.ui.body.classList.remove('hide')
    if (!notfetch) {
      this.request()
    }
  }

  render (list) {
    // sortable('.item', 'destroy')

    this.ui.body.innerHTML = ''

    for (let i = 0; i < list.length; i++) {
      this.renderItem(list[i])
    }

    // console.log('sort', this.sort, this.sortAlpha, this.mode)

    if (((this.sort === null && this.sortAlpha !== 'name') ||
      (this.sortAlpha === null && this.sort !== 'plays')) &&
      this.mode !== 'search') {
      // console.log('init sortable')
      this.ui.body.classList.add('sortable')
      this.sortable = new Sortable(this.ui.body, {
        handle: '.handle',
        animation: 150,
        easing: 'cubic-bezier(1, 0, 0, 1)',
        onEnd: (ev) => {
          const item = ev.item // dragged HTMLElement
          const items = Array.prototype.slice.call(this.ui.body.childNodes)
          const index = items.indexOf(item)
          const sort = items.length - index
          this.updateSort(item.dataset.id, sort)
        }
      })
    } else {
      this.ui.body.classList.remove('sortable')
      if (this.sortable) {
        this.sortable.destroy()
        this.sortable = null
      }
    }
  }

  renderItem (info, context) {
    // console.log('renderItem', info)
    info = info || {}

    const id = this.options.dataId || '_id'

    const container = this.ui.body

    if (context === 'create') {
      container.scrolltop = 0
    }

    // if (context === 'search') {
    //   container = this.ui['search-list']
    // }

    const element = new Element({
      tag: 'li',
      class: 'item'
    })

    // console.log('---', this.id)

    if (this.id && this.id === info[id]) {
      element.classList.add('selected')
    }

    element.dataset.id = info[id]
    element.dataset.info = this.options.info

    let layout = null

    info.plays = abbrNum(info.plays, 1)

    if (this.options.itemSwitch) {
      const item = info[this.options.itemSwitch]

      if (this.options.layout.item[item]) {
        layout = new Layout(this.options.layout.item[item], element)
      } else {
        layout = new Layout(this.options.layout.item.base, element)
      }
    } else {
      const itemName = this.options.itemName || 'item'
      layout = new Layout(this.options.layout[itemName], element)
    }

    // console.log('translate', layout)

    if (this.translate && this.options.item && this.options.item.translate) {
      // console.log('----')
      this.translate(layout)
    }

    this.renderInfo(layout, info)

    if (context === 'top' || context === 'create') {
      container.insertBefore(element, container.firstChild)
      this.selectCreated(info, element)
    } else {
      container.appendChild(element)
    }

    this.emit('infoRendered', info, element)

    return element
  }

  /**
   * [renderInfo description]
   * @param  {[type]} layout [description]
   * @param  {[type]} info   [description]
   * @return {[type]}        [description]
   */
  renderInfo (layout, object) {
    const info = dot(object)

    const fields = this.extractInfo(layout.component)

    Object.entries(fields).forEach(([field, fieldObj]) => {
      if (fieldObj?.set) {
        fieldObj.set(this.objectValueByDotKey(object, field))
      } else {
        fieldObj.innerHTML = info[field]
      }
    })
  }

  update (info) {
    console.log('update', info)

    const item = this.ui.body.querySelector('[data-id="' + info._id + '"]')
    if (item) {
      item.innerHTML = ''
      const layout = new Layout(this.options.layout.item.base, item)

      this.renderInfo(layout, info)
    }

    return info
  }

  add (info) {
    console.log('list add', info)

    const container = this.ui.body

    const element = new Element({
      tag: 'li',
      class: 'item'
    })

    element.dataset.id = info._id
    const layout = new Layout(this.options.layout.item.base, element)

    if (this.translate && this.options.item && this.options.item.translate) {
      // console.log('----')
      this.translate(layout)
    }

    this.renderInfo(layout, info)

    container.insertBefore(element, container.firstChild)

    return element
  }

  remove (id) {
    // console.log('remove', id, this.dataList)
    // console.log('datalist', this.dataList)
    if (!id) return

    const item = this.ui.body.querySelector('[data-id="' + id + '"]')

    this.ui.body.removeChild(item)
  }

  objectValueByDotKey (object, dotkey) {
    const keys = dotkey.split(/\./)

    let value = Object.assign({}, object)

    for (let i = 0; i < keys.length; i++) {
      if (value !== undefined) {
        value = value[keys[i]]
      }
    }

    return value
  }

  extractInfo (object) {
    // console.log('extractField', object)

    const field = {}

    for (const property in object) {
      if (object.hasOwnProperty(property)) {
        const infos = property.split(/\./)

        if (infos[0] === 'info' && infos[1] !== undefined) {
          const name = property.substr(5, property.length)
          // console.log('field', name, property)

          field[name] = object[property]
        }
      }
    }

    return field
  }

  select (item, silent) {
    // console.log('select', item.dataset.id)
    if (!item || !item.dataset.id) return

    const id = item.dataset.id

    if (this.options.preventSelectAgain && this.id === id) {
      return
    }

    const selected = this.ui.body.querySelector('.selected')
    if (selected) selected.classList.remove('selected')

    if (id) {
      this.highlight(item)
      this.item = item
      this.id = id

      if (silent !== true) {
        this.emit('select', id)
        this.emit('selectItem', item)
      }
    } else {
      this.id = null
      this.item = null
    }
  }

  updateSort (id, sort) {
    // console.log('updateOrder', id, sort)
    const formData = new FormData()

    formData.append('id', id)
    formData.append('category', this.category)
    formData.append('sort', sort)
    // formData.append('sort', null)

    fetch('/order/island', {
      method: 'PUT',
      body: new URLSearchParams(formData),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then(info => {
      console.log('info', info)
      this.emit('ordered')
    })
  }

  sortOrder () {
    // console.log('show sort menu')
    this.sortAlpha = null

    if (this.sort) {
      this.sort = null
      this.order = 1
      this.request()
      this.ui.sort.element.classList.remove('selected')
    } else {
      this.order = -1
      this.sort = 'plays'
      this.ui.sortalpha.element.classList.remove('selected')
      this.ui.sort.element.classList.add('selected')
      this.request()
    }
  }

  sortOrderAlpha () {
    // console.log('show sort menu')
    this.sort = null

    if (this.sortAlpha) {
      this.sortAlpha = null
      this.order = 1
      this.request()
      this.ui.sortalpha.element.classList.remove('selected')
    } else {
      this.sortAlpha = 'name'
      this.order = 1
      this.ui.sort.element.classList.remove('selected')
      this.ui.sortalpha.element.classList.add('selected')
      this.request()
    }
  }

  highlight (item) {
    if (this.item) {
      this.item.classList.remove('selected')
    }
    item.classList.add('selected')
  }

  params () {
    // console.log('params', this.keywords, this.sort, this.category)
    let c = ''

    if (this.keywords) {
      return c + 'page=1&search=' + this.keywords
    } else {
      if (this.category) {
        c = 'category=' + this.category + '&'
      }
      if (this.sort) {
        return c + 'sort=' + this.sort + '&sort=' + this.order
      } else if (this.sortAlpha) {
        return c + 'sort=' + this.sortAlpha + '&order=' + this.order
      } else {
        return c + 'sort=sort&order=-1'
      }
    }

    // console.log('c', c)
  }
}

export default List

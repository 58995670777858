import cookie from './module/cookie'

import Element from 'material/src/element'
import Button from 'material/src/button'

import iconStrapi from './icon/app/strapi.svg'
import iconIslands from './icon/app/islands.svg'
import iconTracks from './icon/app/tracks.svg'
import iconAccounts from './icon/app/accounts.svg'
import iconContent from './icon/app/content.svg'
import iconTranslation from './icon/app/translation.svg'
import iconAwards from './icon/app/awards.svg'
import iconDashboard from './icon/app/dashboard.svg'
import iconApps from './icon/app/grid.svg'

const iconList = {
  strapi: iconStrapi,
  tracks: iconTracks,
  islands: iconIslands,
  accounts: iconAccounts,
  awards: iconAwards,
  content: iconContent,
  translation: iconTranslation,
  dashboard: iconDashboard
}

export default {
  initMenu (list, apps) {
    // console.log('render', this.appsList)

    this.initAppsMenu()

    // console.log('menu cookie', cookie.get('menu'))

    if (cookie.get('menu') === 'open' || !cookie.get('menu')) {
      this.ui.menu.classList.add('open')
      this.ui.menu.classList.add('first')
    }

    this.ui.menu.addEventListener('click', () => {
      if (this.ui.menu.classList.contains('open')) {
        this.ui.menu.classList.remove('open')
        this.ui.menu.classList.remove('first')
        cookie.set('menu', 'close')
      } else {
        this.ui.menu.classList.add('open')
        cookie.set('menu', 'open')
      }
    })

    for (let i = 0; i < list.length; i++) {
      const item = this.renderMenuItem(list[i])
      // console.log('item', item)
      this.ui.bodymenu.appendChild(item.element)
    }
  },

  initAppsMenu () {
    const menu = new Button({
      container: this.ui.headmenu,
      // label: 'Apps',
      icon: iconApps,
      name: 'apps',
      class: 'apps',
      stopPropagation: true
    }).on('click', (ev) => {
      this.updateMenu(null)
      this.showApps()
    })
  },

  renderMenuItem (info) {
    // console.log('renderMenuItem', info.name)

    if (info.type === 'separator') {
      // console.log('render', info);
      return new Element({ class: 'divider' })
    } else {
      // console.log('name', info.name)
      const icon = iconList[info.name]

      // console.log('icon', icon)

      const menu = new Button({
        container: this.ui.bodymenu,
        label: info.name,
        icon,
        name: info.name,
        class: 'item',
        stopPropagation: true
      }).on('click', (ev) => {
        // console.log('ev', menu, ev.target)
        if (this.selectedMenu) this.selectedMenu.classList.remove('selected')
        ev.target.classList.add('selected')
        this.selectApp(info.name)
        this.selectedMenu = ev.target
      })

      return menu
    }
  },

  updateMenu (name) {
    const menus = this.ui.bodymenu.childNodes
    // console.log('menus', name, menus)
    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i]
      if (menu.name === name) {
        menu.classList.add('selected')
      } else {
        menu.classList.remove('selected')
      }
    }
  }
}

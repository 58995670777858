import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Element from 'material/src/element'
import Button from 'material/src/button'
import Text from 'material/src/text'
import DateHour from 'material/src/datehour'
import Image from '../field/image'
import Email from '../field/email'
import Country from '../field/country'
import TimeAgo from '../field/timeago.js'
import Search from 'material/src/search'
// import Ranking from '../field/ranking'

import create from 'material/src/list/create'

import display from 'material/src/mixin/display'
import add from 'material/src/list/add'
import build from 'material/src/list/build'
import render from 'material/src/list/render'
import update from 'material/src/list/update'
import select from 'material/src/list/select'
import status from 'material/src/list/status'
import load from 'material/src/list/load'
import request from 'material/src/list/request'
import image from 'material/src/list/image'
import search from 'material/src/list/search'
import click from 'material/src/list/click'

import iconSearch from '../icon/search.svg'

// import hotkeys from 'hotkeys-js'

const defaults = {
  class: 'users',
  tag: 'div',
  pagination: true,
  // debug: true,
  count: true,
  list: {
    size: 100,
    page: 1
  },
  loading: 'dynamic',
  virtual: true,
  store: true,
  route: {
    list: '/user/list',
    count: '/user/count',
    search: '/user'
  },
  image: {
    format: 'thumb/'
  },
  fields: ['pseudonym', 'username', 'enabled', 'created'],
  layout: {
    main: [
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title', text: 'List' }],
        [Element, 'divider', { class: 'divider' }],
        [Button, 'add', { text: 'add' }],
        [Button, 'search', { icon: iconSearch, style: 'action' }]
      ],
      [Search, 'search-input', { minChar: 3 }],
      [Element, 'search-list', { class: 'body search' }],
      [Element, 'body', { class: 'body' }],
      [Element, 'foot', { class: 'foot' },
        [Element, 'count', { class: 'count' }]
        // [Divider, 'divider', { flex: 1 }],
        // [Button, 'load', { text: 'more...' }]
      ]
    ],
    item: [
      [Image, 'info.image', { format: 'thumb', class: 'image' }],
      [Element, 'content', { class: 'content' },
        [Element, 'info', { class: 'info' },
          [Element, { class: 'group' },
            [Text, 'info.pseudonym', { class: 'pseudonym' }],
            [DateHour, 'info.created', { class: 'date' }]
          ],

          // [Ranking, 'info.ranking', { css: 'ranking' }]
          [Email, 'info.email', { class: 'email', link: false }],
          [Country, 'info.country', { class: 'country' }]

        ]
        // [Element, 'info', { class: 'info' },
        //   // [TimeAgo, 'info.created', { css: 'date', text: 'Registered' }],
        //   // [DateHour, 'info.created', { class: 'date' }],
        //   [Text, 'info.enabled', { class: 'enabled' }]
        // ]
      ]
    ]
  },
  events: [
    ['ui.search.click', 'toggleSearch'],
    ['ui.add.click', 'addUser'],
    ['ui.search-input.change', 'search'],
    ['ui.load.click', 'load'],
    ['ui.body.click', 'click'],
    ['ui.search-list.click', 'click']
  ]
}

class List {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    // this.options = options

    Object.assign(this, emitter, attach, create, click,
      build, request, render, update, add, display, load, select, status, image, search
    )

    this.init()

    return this
  }

  init () {
    // console.log('init')
    this.page = this.options.page

    this.build()
    this.attach()
    this.request()
  }

  addUser () {
    console.log('adduser', this.role)
    this.emit('addUser', this.role)
  }

  setRole (role) {
    this.role = role
  }

  params () {
    if (this.role && this.mode !== 'search') {
      return 'role=' + this.role
    }
  }
}

export default List

import system from '../module/system'
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

// app related method
import context from '../app/context'
import display from '../app/display'
import build from '../app/build'

import Element from 'material/src/element'
import Button from 'material/src/button'
import Dialog from 'material/src/dialog'

// appbar icons
import iconNavi from '../icon/navi.svg'

const defaults = {
  class: 'strapi',
  layout: [
    [Element, { class: 'appbar', color: 'primary' },
      [Button, 'menu-navi', { size: 'small', class: 'navi', icon: iconNavi, style: 'action' }],
      [Element, 'title', { class: 'title', text: 'Content' }],
      [Element, 'divider', { class: 'divider' }],
      [Button, 'publish', { size: 'small', label: 'publish' }]
    ]
  ],
  events: [
    ['ui.publish.click', 'publish']
  ]
}

class App {
  static isComponent () {
    return true
  }

  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach, context, build, display)
    // console.log('options', options)

    this.build()
    this.iframe()
    this.attach()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  iframe () {
    this.iframe = document.createElement('iframe')

    this.options.container.appendChild(this.iframe)

    const api = system.get('api')
    this.set(api)

    return this
  }

  publish () {
    // console.log('updateInfo')
    fetch('/strapi/publish', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }

    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((result) => {
      // console.log('result', result)
      // this.confirm()
      this.confirm()
    }).catch((error) => {
      new Dialog({
        name: 'alert',
        content: 'Error: Can\'t reach API Server',
        accept: { text: 'OK', color: 'primary' }
      }).on('accepted', () => {

      }).insert(document.body).show()
      // console.log('error', error)
      // Only network error comes here
    })
  }

  confirm () {
    new Dialog({
      name: 'alert',
      content: 'Publish OK',
      accept: { text: 'OK', color: 'primary' }
    }).on('accepted', () => {

    }).insert(document.body).show()
  }

  set (api) {
    this.url = api.url
    // console.log('url', this.url)
    this.iframe.src = api.url + '/admin'
  }

  hide () {
    this.ui.element.classList.remove('show')
  }

  show () {
    console.log('show', this.element)
    this.element.classList.add('show')
  }

  destroy () {
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element)
    }
  }
}

export default App

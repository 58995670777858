export default {
  toggle () {
    this.visible ? this.hide() : this.show()
  },

  show () {
    // console.log('show', this.display)

    // clearTimeout(this.displayTimeout)
    // this.element.style.display = this._display

    this.options.container.parentNode.classList.add('show')
    this.visible = true

    this.emit('show')
    return this
  },

  hide () {
    // console.log('hide', this.element.style.display)

    this.options.container.parentNode.classList.remove('show')
    this.visible = false

    this.emit('hide')
    return this
  }
}

'use strict'

import { country } from '../data/country'

const defaults = {
  class: 'country',
  tag: 'div'
}

class Country {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    this.build()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement(this.options.tag)
    this.element.classList.add(this.options.class)

    if (this.options.isocode) {
      this.set(this.options.isocode)
    }

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    return this
  }

  set (isocode) {
    // console.log('set', isocode)
    if (country[isocode]) {
      this.element.innerHTML = country[isocode]
    } else if (isocode) {
      this.element.innerHTML = isocode
    } else {

    }
  }
}

export default Country

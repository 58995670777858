import asset from '../module/asset'

const defaults = {
  class: 'images'
}

class Images {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    if (asset.get()) {
      this.asset = asset.get()
    }

    this.format = this.options.format || 'thumb'

    this.build()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement(this.options.tag || 'div')
    this.element.classList.add(this.options.class)

    if (this.options.class !== 'images') {
      this.element.classList.add('images')
    }

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    return this
  }

  addImage (v, info) {
    // console.log('addImage', this.asset.url, this.options.path, info.cover.path)
    const image = document.createElement('img')

    let url = this.asset.url + '/' + info.cover.path + this.format + '/' + info.cover.filename

    if (v > 0) {
      url = this.asset.url + '/' + info.cover.path + this.format + '/' + info.cover.filename
    }

    // image.onerror = () => {
    //   image.src = '/img/generic/nocover.svg'
    // }

    // console.log('src', url)
    image.src = url
    // console.log('imabge', image.src)
    this.element.appendChild(image)
  }

  setInfo (info) {
    // console.log('setInfo', info.image)

    this.element.innerHTML = ''

    let version = null

    if (info.cover) {
      // console.log('addImage')
      this.addImage(0, info)
      version = info.cover.filename.split('_')[1]
    } else return

    // console.log('version', version)

    if (version !== undefined) {
      for (let i = 1; i <= version; i++) {
        this.addImage(i, info)
      }
    }
  }

  set (image) {
    // console.log('set', image)
  }
}

export default Images

// base class
import EventEmitter from 'material/src/mixin/emitter'
// modules
import mediator from 'material/src/module/mediator'
import attach from 'material/src/module/attach'
import get from '../data/get'
import build from 'material/src/module/build'
import display from 'material/src/mixin/display'
// components
import Element from 'material/src/element'
import Button from 'material/src/button'
import Text from 'material/src/text'
// icons
import iconDownload from '../../icon/download.svg'

class Submissions extends EventEmitter {
  static defaults = {
    base: 'widget',
    class: 'subsmissions',
    tag: 'div',
    layout: [
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title', text: 'Submissions' }],
        [Element, 'divider', { class: 'divider' }],
        [Element, { class: 'text', text: 'Total: ' }],
        [Text, 'total', { class: 'text' }],
        [Element, 'divider', { class: 'divider' }],
        [Button, 'download', { icon: iconDownload, class: 'download', label: 'Download', title: 'Admin', type: 'button' }]
      ],
      [Element, 'body', { class: 'body' },
        [Element, { class: 'group' },
          [Element, 'bars', { class: 'chart bars' }],
          [Element, 'divider', { class: 'divider' }],
          [Element, 'donut', { class: 'chart donut' }]
        ],
        [Element, { class: 'group' },
          [Element, 'lines', { class: 'chart lines' }]
        ]
      ]
    ],
    events: [
      ['ui.download.click', 'download']
    ]
  }

  constructor (options) {
    super()

    this.init(options)
    this.build()
    this.attach()
  }

  init (options) {
    this.options = Object.assign({}, Submissions.defaults, options || {})
    Object.assign(this, attach, build, display)

    mediator.installTo(this)
  }

  update (from, to) {
    this.from = from
    this.getSubscriptions(from, to, () => {
      this.processSubscriptions()
    })
  }

  getSubscriptions (from, to, cb) {
    // console.log('get', id)

    get('/dashboard/listi?c=Track&q=date&from=' + from + '&to=' + to, (list) => {
      // console.log('list', list.length)

      this.list = list
      // console.log(role + 'subscriptions ', this.user[role])
      if (cb) cb()
    })
  }

  processSubscriptions () {
    // console.log('processSubscriptions')
    let next
    let count = {
      total: 0,
      onair: 0,
      posted: 0,
      confirmation: 0,
      rework: 0,
      rejected: 0,
      deleted: 0
    }
    const premium = 0

    const countrybydate = {}

    const bar = {
      type: 'bar',
      x: 'x',
      columns: [
        ['x'],
        // ['subscriptions'],
        ['onair'],
        ['posted'],
        ['confirmation'],
        ['rework'],
        ['rejected'],
        ['deleted']
      ],
      groups: [
        ['onair', 'posted', 'confirmation', 'rework', 'rejected', 'deleted']
        // ['canceled', 'deleted']
      ],
      colors: {
        active: '#2F77B0',
        pending: '#4A9C3E',
        canceled: '#F58533',
        deleted: '#CB3B31'
      },
      xFormat: '%Y-%m-%d'
    }

    const line = {
      x: 'x',
      columns: [
        ['x']
      ],
      colors: {
        USA: '#2F77B0',
        FRA: '#4A9C3E',
        RUS: '#CB3B31',
        GRB: '#F58533'
      },
      xFormat: '%Y-%m-%d'
    }

    const country = {}
    const donut = {
      columns: [],
      type: 'donut'
      // onclick: function (d, i) { console.log("onclick", d, i); },
      // onmouseover: function (d, i) { console.log("onmouseover", d, i); },
      // onmouseout: function (d, i) { console.log("onmouseout", d, i); }
    }

    const list = this.list

    this.ui.total.set(list.length)

    for (var i = 0; i < list.length; i++) {
      count.total++

      // console.log('premium', premium[i].created)
      const info = list[i]
      // console.log('info', info)
      const current = dayjs(info.created.date).format('YYYY-MM-DD')

      count[info.status]++

      // console.log('info', info);
      if (!countrybydate[current]) countrybydate[current] = {}

      // prepare country for donut
      if (info.country) {
        if (!countrybydate[current][info.country]) {
          countrybydate[current][info.country] = 1
        } else {
          countrybydate[current][info.country]++
        }

        if (!country[info.country]) {
          country[info.country] = 1
        } else {
          country[info.country]++
        }
      }

      if (list[i + 1]) {
        next = dayjs(list[i + 1].created.date).format('YYYY-MM-DD')
      } else {
        next = null
      }

      if (current !== next) {
        // console.log('count', count)

        bar.columns[0].push(current)
        // bar.columns[1].push(count.total)
        bar.columns[1].push(count.onair)
        bar.columns[2].push(count.posted)
        bar.columns[3].push(count.confirmation)
        bar.columns[4].push(count.rework)
        bar.columns[4].push(count.rejected)
        bar.columns[4].push(count.deleted)
        count = {
          total: 0,
          onair: 0,
          posted: 0,
          rework: 0,
          rejected: 0,
          deleted: 0,
          confirmation: 0
        }
      }

      // console.log(date)
    }
    // console.log('bar', bar)
    // console.log('countrybydate', countrybydate)

    // prepare donuts data

    const sortable = []
    for (const name in country) {
      sortable.push([name, country[name]])
    }

    sortable.sort(function (a, b) {
      return b[1] - a[1]
    })

    let others = 0
    let othersCount = 0
    for (var i = 0; i < sortable.length; i++) {
      const item = sortable[i]
      // console.log('item', item);
      if (i < 10) {
        donut.columns.push(item)
      } else {
        others++
        othersCount = othersCount + item[1]
      }
    }

    donut.columns.push(['others (' + others + ')', othersCount])

    this.drawBar(bar)
    this.drawDonut(donut)
  }

  drawBar (data) {
    if (!data) return
    this.barsData = data

    this.bars = c3.generate({
      bindto: this.ui.bars,
      size: {
        height: '100px'
      },
      data,
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d'
          }
        }
      },
      bar: {
        width: {
          ratio: 0.85 // this makes bar width 50% of length between ticks
        }
        // or
        // width: 100 // this makes bar width 100px
      }

    })
  }

  drawDonut (data) {
    if (!data) return
    this.donutData = data

    this.ui.donut.innerHTML = ''

    this.donut = c3.generate({
      bindto: this.ui.donut,
      data,
      donut: {
        title: 'Country'
      }
    })
  }

  resize () {
    console.log('resize')

    this.ui.donut.innerHTML = ''
    this.ui.bars.innerHTML = ''

    setTimeout(() => {
      this.drawDonut(this.donutData)
      this.drawBar(this.barsData)
    }, 10)

    // this.donut.show()
    // this.bars.show()

    // this.donut.resize({
    //   width: donutWidth
    // })
    // this.bars.resize({
    //   width: barWidth
    // })
  }

  download () {
    let csv = 'Date,Type,Country,Status,Plan,Amount,Currency\n'

    for (let i = 0; i < this.list.length; i++) {
      const info = this.list[i]
      console.log('info', info)

      let currency = info.currency

      console.log('currency', currency)

      if (currency && currency !== undefined) {
        currency = currency.toUpperCase()
      }

      csv += info.date + ',' + info.type + ',' + info.country + ',' + info.status + ',' + info.plan + ',' + info.amount + ',' + currency + '\n'
    }

    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    hiddenElement.target = '_blank'
    hiddenElement.download = 'subscription_' + this.from + '.csv'
    hiddenElement.click()
  }
}

export default Submissions


export default {
  play (id, list) {
    this.list = list || this.list
    // console.log('play', id, list)
    if (!id) return

    this.playlist = {
      ids: list.dataList,
      id,
      view: list
    }

    this.getTrackInfo(this.playlist.id)
  },

  getTrackInfo (id) {
    fetch('/track/' + id, {
      headers: {
        Accept: 'application/json'
      },
      method: 'GET'
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((info) => {
      // console.log('info', info)
      this.playlist.info = info
      this.playTrack(info)
    })
  },

  playTrack (info) {
    this.ui.player.setInfo(info)

    const id = info._id

    if (!id) return

    fetch('/track/play/' + id, {
      headers: {
        Accept: 'application/json'
      },
      method: 'GET'
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((link) => {
      // console.log('link', link)

      this.ui.player.set(link)
      // console.log('set status', this.ui.player.status)
      if (this.ui.player.status === 'play') {
        this.ui.player.play()
      }
    })
  },

  playNext (e) {
    // console.log('playNext')

    if (!this.playlist) return

    const idx = this.playlist.ids.indexOf(this.playlist.id) + 1

    if (this.playlist.ids[idx]) {
      this.playlist.id = this.playlist.ids[idx]
      this.getTrackInfo(this.playlist.id)
      this.playlist.view.selectById(this.playlist.id, true, 'down')
    }
  },

  playPrevious (e) {
    // console.log('playPrevous')

    if (!this.playlist) return

    const idx = this.playlist.ids.indexOf(this.playlist.id) - 1

    if (this.playlist.ids[idx]) {
      this.playlist.id = this.playlist.ids[idx]
      this.getTrackInfo(this.playlist.id)
      this.playlist.view.selectById(this.playlist.id, true, 'up')
    }
  }

}

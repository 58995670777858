import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Element from 'material/src/element'

import Text from 'material/src/text'
import DateHour from 'material/src/datehour'
import Button from 'material/src/button'
// import Ranking from '../field/ranking'
// import Icon from '../control/icon'
// import Switcher from '../control/switcher'

import Country from '../field/country'
import Plan from '../field/plan'

import display from 'material/src/mixin/display'
import create from 'material/src/list/create'
import add from 'material/src/list/add'
import build from 'material/src/list/build'
import status from 'material/src/list/status'
import render from 'material/src/list/render'
import select from 'material/src/list/select'
// import filter from 'material/src/list/filter'
import load from 'material/src/list/load'
import request from 'material/src/list/request'
import image from 'material/src/list/image'
import click from 'material/src/list/click'

// import iconFilter from '../icon/filter.svg'
// import iconCancel from '../icon/cancel.svg'

// import hotkeys from 'hotkeys-js'

const defaults = {
  class: 'subscription',
  pagination: true,
  // debug: true,
  list: {
    size: 100,
    page: 1
  },
  count: true,
  loading: 'dynamic',
  virtual: true,
  store: true,
  route: {
    list: '/subscription/list'
  },
  image: {
    format: 'thumb/'
  },
  itemSwitch: 'type',
  fields: ['pseudonym', 'username', 'enabled', 'created'],
  layout: {
    main: [
      [Element, 'head', { class: 'head' },
        [Text, 'title', { class: 'title', text: 'Subscriptions' }]
        // [Button, 'filter', { class: 'filter', icon: iconFilter, size: 'small', style: 'action' }]
      ],
      // [Element, 'filter-input', { class: 'filter-input' },
      //   [Icon, { src: iconFilter }],
      //   [Switcher, 'info.status', { label: 'S', size: 'small', inline: true, list: ['active', 'canceled', 'deleted'] }],
      //   [Element, { class: 'divider' }],
      //   [Button, 'filter-clear', { class: 'clear', icon: iconCancel }]
      // ],
      [Element, 'body', { class: 'body' }],
      [Element, 'foot', { class: 'foot' },
        [Element, 'count', { class: 'count' }]
        // [Divider, 'divider', { flex: 1 }],
        // [Button, 'load', { text: 'more...' }]
      ]
    ],
    item: {
      stripe: [
        [Element, 'user', { class: 'group user' },
          [Text, 'info.email', { class: 'user_id' }],
          [Text, 'info.subscription.status', { class: 'status' }]
        ],
        [Element, 'group', { class: 'group datetype' },
          [Country, 'info.country', { class: 'brand' }],
          [DateHour, 'info.subscription.date', { class: 'date' }]
        ],
        [Element, 'group', { class: 'group amount' },
          [Element, 'info.type', { class: 'type' }],
          [Plan, 'info.subscription.plan', { class: 'plan' }]
        ]
      ],
      ios: [
        [Element, 'user', { class: 'group user' },
          [Text, 'info.email', { class: 'user_id' }],
          [Text, 'info.subscription.status', { class: 'status' }]
          // [Country, 'info.country', { class: 'country' }]
        ],
        [Element, 'group', { class: 'group datetype' },

          [Country, 'info.country', { class: 'brand' }],
          [DateHour, 'info.subscription.date', { class: 'date' }]
        ],
        [Element, 'group', { class: 'group amount' },
          [Element, 'info.type', { class: 'type ios' }],
          [Text, 'info.subscription.currency', { class: 'currency' }],
          [Text, 'info.subscription.amount', { class: 'amount' }]
        ]
      ],
      promotion: [
        [Element, 'user', { class: 'group user' },
          [Text, 'info.email', { class: 'user_id' }],
          [Text, 'info.subscription.status', { class: 'status' }]
        ],
        [Element, 'group', { class: 'group datetype' },
          [Element, 'info.type', { class: 'type' }],
          [DateHour, 'info.subscription.date', { class: 'date' }]

        ],

        [Element, 'group', { class: 'group amount' },
          [Country, 'info.country', { class: 'brand' }],
          [Plan, 'info.subscription.plan', { class: 'plan' }]
        ]
      ],
      giftcard: [
        [Element, 'user', { class: 'group user' },
          [Text, 'info.email', { class: 'user_id' }],
          [Text, 'info.subscription.status', { class: 'status' }]
        ],
        [Element, 'group', { class: 'group datetype' },
          [Element, 'info.type', { class: 'type' }],
          [DateHour, 'info.subscription.date', { class: 'date' }]

        ],

        [Element, 'group', { class: 'group amount' },
          [Country, 'info.country', { class: 'brand' }],
          [Plan, 'info.subscription.plan', { class: 'plan' }]
        ]
      ]
    }
  },
  events: [
    ['ui.load.click', 'load'],
    ['ui.body.click', 'click'],
    ['fetched', 'onFetched']
  ]
}

class List {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    console.log('constructor')
    this.options = Object.assign({}, defaults, options || {})
    // this.options = options

    Object.assign(this, emitter, attach, create, click,
      build, request, render, add, load, display, select, image, status // filter
    )

    this.init()

    return this
  }

  init () {
    // console.log('init')
    this.page = this.options.page

    this.build()
    this.attach()
    // this.initFilter()
    this.request()
  }

  set (info) {
    if (!info) return
    console.log('set', info.user_id)

    this.user_id = info.user_id

    this.request()
  }

  setStatus (status) {
    this.status = status
  }

  onFetched (list) {
    console.log('list', list)
  }

  params () {
    console.log('info', this.user_id, this.status)
    let params = ''

    if (this.user_id) {
      params = 'user_id=' + this.user_id
    }

    if (this.status) {
      params = params + '&status=' + this.status
    }

    return params
  }
}

export default List

export default {
  initLeaflet (decades, isocode) {
    if (this.loadingLeaflet) return
    this.loadingLeaflet = true

    const styles = document.createElement('link')
    styles.setAttribute('rel', 'stylesheet')
    styles.setAttribute('type', 'text/css')
    styles.setAttribute('href', 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.css')
    styles.setAttribute('integrity', '')

    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.js')
    styles.setAttribute('integrity', '')
    script.addEventListener('load', () => {
      // console.log('leaflet loaded')
      this.leafletLoaded = true
      // console.log('setmap', decades, isocode)
      this.initMap()
      this.initEvents()
      this.initIslands()
      this.ui.container.classList.add('show')
    })

    document.getElementsByTagName('head')[0].appendChild(script)
    document.getElementsByTagName('head')[0].appendChild(styles)
  }
}

import emitter from 'material/src/module/emitter'
import Layout from '../layout/index'

import layout from './layout'

const defaults = {
  prefix: 'material',
  class: 'account',
  tag: 'div',
  image: {
    path: '/asset/image/user/small/'
  }
}

class Account {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.init(options)

    return this
  }

  /**
   * init method
   * @param  {Object} Options
   * @return {Object} Instance
   */
  init (options) {
    this.options = Object.assign({}, defaults, options || {})

    Object.assign(this, emitter)

    this.build()

    return this
  }

  build () {
    this.element = document.createElement('div')
    this.element.classList.add('material-account')

    this.layout = new Layout(layout, this.element)

    this.attach()
  }

  attach () {
    this.element.addEventListener('click', (ev) => {
      if (ev.target === this.element) this.hide()
    })

    this.layout.get('signout').element.addEventListener('click', () => {
      // console.log('click')
      this.signOut()
    })

    // this.layout.get('settings').element.addEventListener('click', () => {
    //   this.emit('settings')
    //   this.hide()
    // })
  }

  signOut () {
    // console.log('signout')
    fetch('/account/logout', {
      method: 'GET'
    }).then(r => r.json()).then(data => {
      // console.log(data)
      location.reload()
    })

    return this
  }

  set (user) {
    // console.log('set account info', user)

    this.show()

    let image = this.options.image.path + user.image
    if (!user.image) image = '/img/nocontributor.png'

    this.layout.get('image').element.style.backgroundImage = 'url("' + image + '")'
    this.layout.get('name').set(user.pseudonym)
    this.layout.get('email').set(user.email)

    return this
  }

  hide () {
    // console.log('hide', this.element)
    document.body.removeChild(this.element)
  }

  show () {
    document.body.appendChild(this.element)
  }
}

export default Account

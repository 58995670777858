import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

// app
import context from '../app/context'
import display from '../app/display'
import build from '../app/build'

// icons

// components
// import components

import Element from 'material/src/element'
import Button from 'material/src/button'
import Drawer from 'material/src/drawer'

import Islands from '../menu/islands'
// import Inbox from '../control/inbox'

// views
import Accounts from './accounts'
import Tracks from './tracks'
// import Reports from './reports'

// icons
import iconNavi from '../icon/navi.svg'
import iconHome from '../icon/home.svg'
import iconPeople from '../icon/people.svg'

// app title
const TITLE = 'Dashboard'

const defaults = {
  name: TITLE,
  class: 'dashboard',
  views: ['accounts', 'tracks'],
  view: {
    accounts: Accounts,
    tracks: Tracks
    // reports: Reports
  },
  layout: [
    [Element, { class: 'appbar', color: 'primary' },
      [Button, 'menu-navi', { size: 'small', class: 'navi', icon: iconNavi, style: 'action' }],
      [Element, 'title', { class: 'title', text: TITLE }],
      [Element, 'divider', { class: 'divider' }]
    ],
    [Drawer, 'navi', { css: 'drawer-persistent', type: 'persistent', open: true },
      [Element, { class: 'group workflow' },
        [Button, 'overview', { icon: iconHome, label: 'Overview', name: 'overview', title: 'Overview' }],
        [Element, { class: 'divider' }],
        [Element, 'naviview', { class: 'naviview' }]

      ]
    ],
    [Element, 'views', { class: 'views' }]
  ],
  events: [
    // navi
    ['ui.menu-navi.click', 'ui.navi.toggle'],
    ['ui.navi.element.click', 'onNaviSelect']
  ]

}

class App {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    // c onsole.log('ini dashbord')
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, attach, emitter, context, build, display)

    this.init()

    return this
  }

  init () {
    this.build()

    this.views = []

    for (let i = 0; i < this.options.views.length; i++) {
      const name = this.options.views[i]
      const button = new Button({
        class: 'menu',
        text: name,
        name
      })

      this.ui.naviview.appendChild(button.element)
    }

    this.attach()
  }

  selectView (name) {
    console.log('selectNavi', name)
    if (this.view) this.view.hide()

    if (!this.ui[name]) {
      this.ui[name] = new this.options.view[name]({
        container: this.ui.views
      }).show()
      this.view = this.ui[name]
    } else {
      this.view = this.ui[name].show()
    }

    // for (let i = this.views.length - 1; i >= 0; i--) {
    //   const view = this.views[i]
    //   this.ui[view].show()
    //   this.view = view
    // }
  }

  onNaviSelect (e) {
    // console.log('naviSelet', e.target)
    if (e.target.matches('BUTTON')) {
      const name = e.target.name
      // console.log('name')
      this.selectView(name)
    }
  }
}

export default App

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Element from 'material/src/element'
import Button from 'material/src/button'
import Text from 'material/src/text'
import Image from '../../field/image'
import Search from 'material/src/search'
import Select from 'material/src/select'

import create from 'material/src/list/create'
import display from 'material/src/mixin/display'
import add from 'material/src/list/add'
import build from 'material/src/list/build'
import render from 'material/src/list/render'
import select from 'material/src/list/select'
import load from 'material/src/list/load'
import search from 'material/src/list/search'
import request from 'material/src/list/request'
import click from 'material/src/list/click'
import remove from 'material/src/list/remove'
import update from 'material/src/list/update'

import iconSearch from '../../icon/search.svg'

// import hotkeys from 'hotkeys-js'

const defaults = {
  class: 'label',
  tag: 'div',
  pagination: true,
  loading: 'dynamic',
  list: {
    size: 100,
    page: 1
  },
  count: true,
  virtual: true,
  image: {
    format: 'thumb/'
  },
  item: {
    height: 60,
    new: {
      name: 'New Label'
    }
  },
  // fields: ['name', 'key', 'value'],
  layout: {
    main: [
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title', text: 'Labels' }],
        [Element, 'divider', { class: 'divider' }],
        [Select, 'scopes', { }],
        [Element, 'divider', { class: 'divider' }],
        [Button, 'search', { class: 'search', icon: iconSearch, style: 'action' }]
      ],
      [Search, 'search-input', { minChar: 3 }],
      [Element, 'search-list', { class: 'body search' }],
      [Element, 'body', { class: 'body' }],
      [Element, 'foot', { class: 'foot' },
        [Element, 'count', { class: 'count' }]
      ]
    ],
    item: [
      [Element, 'content', { class: 'content' },
        [Element, 'info', { class: 'info' },
          [Text, 'info.key', { class: 'key' }],
          [Text, 'info.scope', { class: 'scope' }]
        ],
        [Element, 'info', { class: 'info text' },

          [Text, 'info.value', { class: 'value' }]
        ]
      ]
    ]
  },
  route: {
    list: '/label/list',
    search: '/label/',
    scopes: '/label/scopes',
    count: '/label/count'
  },
  events: [
    ['ui.body.click', 'click'],
    ['ui.scopes.change', 'onScope'],
    // ['ui.new.click', 'create'],
    ['ui.search.click', 'toggleSearch'],
    ['ui.search-input.change', 'search'],
    ['ui.search-input.cancel', 'cancelSearch']
    // ['ui.load.element.click', 'load']
  ]
}

class List {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    // this.options = options

    Object.assign(this, emitter, attach, create, display, click, update, remove,
      build, request, render, add, load, select, search
    )

    this.page = this.options.page

    this.build()
    this.attach()

    this.getScopes()

    return this
  }

  getScopes () {
    fetch(this.options.route.scopes).then((resp) => {
      return resp.json()
    }).then((scopes) => {
      // console.log('scopes', scopes)
      this.scopes = scopes.sort()
      this.addScopes(this.scopes)
    }).catch(function (e) {
      console.log('error', e.message)
    })
  }

  addScopes (scopes) {
    // console.log('addScopes', scopes)
    this.ui.scopes.addOption('', '')
    for (let i = 0; i < scopes.length; i++) {
      this.ui.scopes.addOption(scopes[i], scopes[i])
    }
  }

  onScope (scope) {
    // console.log('onScope', scope)
    this.scope = scope
    this.request()
  }

  params () {
    let params = '&sort=scope&order=1'
    // console.log('params - ', this.rand, this.profile_id)
    if (this.scope) params = params + '&scope=' + this.scope
    return params
  }
}

export default List

'use strict'

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'
import toTime from '../module/totime'

import Layout from '../layout/index'
import Element from 'material/src/element'
import Button from 'material/src/button'
import Image from '../field/image.js'
import Play from '../control/play'

import iconNext from '../icon/control/next.svg'
import iconPrev from '../icon/control/prev.svg'

const defaults = {
  class: 'player',
  audio: {
    autoplay: false
  },
  layout: [
    [Element, 'controls', { class: 'controls' },
      [Button, 'previous', { icon: iconPrev }],
      [Play, 'play', { }],
      [Button, 'next', { icon: iconNext }]
    ],
    [Element, 'body', { class: 'body' },
      [Image, 'image', { format: 'thumb' }],
      [Element, 'group', { class: 'group' },
        [Element, 'progress', { class: 'progress' },
          [Element, 'buffer', { class: 'buffer' }],
          [Element, 'indicator', { class: 'indicator' }]
        ],
        [Element, 'bitrate', { class: 'bitrate' }],
        [Element, 'length', { class: 'length' }],
        [Element, 'current', { class: 'current time' }],
        [Element, 'info', { class: 'info' },
          [Element, 'title', { class: 'title' }],
          [Element, 'artist', { class: 'artist' }]
        ],
        [Element, 'duration', { class: 'time duration' }]
      ]

    ],
    [Element, 'audio', { tag: 'audio', class: 'audio' },
      [Element, 'mpg', { tag: 'source' }],
      [Element, 'ogg', { tag: 'source' }]
    ]
  ],
  events: [
    ['audio.timeupdate', 'updateProgress'],
    ['audio.play', 'onPlay'],
    ['audio.pause', 'onPause'],
    ['audio.ended', 'audioEnded'],
    ['audio.progress', 'updateBuffer'],
    ['ui.progress.click', 'progressClick'],
    ['ui.play.play', 'play'],
    ['ui.play.pause', 'pause'],
    ['ui.next.click', 'next'],
    ['ui.previous.click', 'previous']
  ]
}

/**
 * Class representing an Audio Player.
 *
 * @extends None
 * @return {parent} The class instance
 * @example new Player({
 *   container: document.body
 * });
 */
class Player {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach)

    this.status = 'pause'

    this.build()
    this.attach()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement('div')
    this.element.classList.add(this.options.class)

    this.layout = new Layout(this.options.layout, this.element)
    this.ui = this.layout.component
    this.audio = this.ui.audio

    if (this.options.autoplay) {
      this.audio.setAttribute('autoplay', 'autoplay')
    }

    this.audio.setAttribute('type', 'audio/mpeg')

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    return this
  }

  updateProgress (e) {
    // console.log('audioTimeUpdate', this.audio.currentTime + '/' + this.audio.duration)

    this.ui.current.innerHTML = toTime(this.audio.currentTime)
    this.ui.duration.innerHTML = toTime(this.audio.duration)

    const current = (this.audio.currentTime / this.audio.duration) * 100
    this.ui.indicator.style.width = current + '%'
  }

  updateBuffer () {
    // console.log('updateProgress')

    if (this.audio.buffered.length > 0) {
      const buffer = (this.audio.buffered.end(0) * 100) / this.audio.duration
      this.ui.buffer.style.width = buffer + '%'
    }
  }

  resetProgress () {
    this.ui.current.innerHTML = '00:00'
    this.ui.duration.innerHTML = '00:00'
    this.ui.indicator.style.width = 0
    this.ui.buffer.style.width = 0
  }

  audioEnded (e) {
    // console.log('audioEnded', e)
    this.emit('ended')
  }

  progressClick (e) {
    // console.log('progressClick', this.audio.currentTime)

    if (isNaN(this.audio.currentTime)) return

    const ratio = (e.offsetX / this.ui.progress.offsetWidth) * 100
    // console.log('ratio %', ratio)

    const current = (this.audio.duration / 100) * ratio
    // console.log('current sec', current)

    if (isNaN(current)) return

    this.audio.currentTime = current
  }

  // events

  onPlay (e) {
    // console.log('event audio play', this.audio.paused)
    this.ui.play.show('pause')
  }

  onPause (e) {
    // console.log('event audio pause', this.audio.paused)
    this.ui.play.show('play')
  }

  play () {
    // console.log('play')
    this.audio.play().catch((error) => {
      // console.log('Error: ', error)
      this.ui.play.show('play')
    })

    this.status = 'play'
  }

  pause () {
    // console.log('pause')
    this.audio.pause()
    this.status = 'pause'
  }

  toggle () {
    if (this.status === 'play') {
      this.pause()
    } else {
      this.play()
    }
  }

  forward (s) {
    s = s || 2
    this.audio.currentTime += 5
  }

  backward (s) {
    s = s || 2
    this.audio.currentTime -= 5
  }

  next () {
    // console.log('next')
    this.emit('next')
  }

  previous () {
    // console.log('previous')
    this.emit('previous')
  }

  set (object) {
    // console.log('set', object)
    this.resetProgress()

    // clearTimeout(this.timeout)

    // this.timeout = setTimeout(() => {
    //   this.ui.mpg.src = object.mpeg
    //   if (this.ui.ogg) this.ui.ogg.src = object.ogg
    //   this.audio.load()
    // }, 200)

    this.ui.mpg.src = object.mpeg
    if (this.ui.ogg) this.ui.ogg.src = object.ogg
    this.audio.load()
  }

  setInfo (info) {
    // console.log('setInfo', info, this.asset)
    this.ui.title.innerHTML = info.title
    this.ui.artist.innerHTML = info.artist

    this.ui.bitrate.innerHTML = info.bitrate + ' kbs'
    this.ui.length.innerHTML = info.length + ' sec'

    if (info.album) {
      this.ui.artist.innerHTML = this.ui.artist.innerHTML + ' &mdash; ' + info.album
    }

    if (info.cover) {
      // console.log('info.image', info.cover)
      this.ui.image.set(info.cover)
    } else {
      this.ui.image.element.style.backgroundImage = ''
    }
  }
}

export default Player

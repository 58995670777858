import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import countries from '../data/countries'

const defaults = {
  class: 'select',
  type: 'control',
  tag: 'div',
  events: [
    ['input.change', '_onChange'],
    ['input.focus', 'focus'],
    ['input.blur', 'blur'],
    // ['input.keypress', '_handleInputKeyPress',
    ['input.keyup', '_handleInputKeyPress']
    // ['input.mouseover', 'mouseover']
    // ['input.change', '_onChange']
    // 'input.keydown': '_handleInputKeyPress'

  ]
}

class Country {
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    this.init()
    this.build()
    this.attach()

    return this
  }

  init () {
    Object.assign(this, emitter, attach)

    this.value = this.options.value

    return this
  }

  mouseover () {
    // console.log('mouseover')
  }

  /**
   * [build description]
   * @return {Object} The class instance
   */
  build () {
    // console.log('build')
    // create a new div as input element
    const tag = this.options.tag || 'div'
    this.element = document.createElement(tag)
    this.element.classList.add('select')

    if (this.options.class !== 'select') {
      this.element.classList.add(this.options.class)
    }

    this.buildLabel()
    this.buildInput()
    this.buildCountry()

    if (this.disabled) {
      this.element.classList.add('is-disabled')
    }

    // insert if container this.options is given
    if (this.options.container) {
      // console.log(this.name, opts.container);
      this.options.container.appendChild(this.element)
    }
  }

  buildLabel () {
    this.label = document.createElement('label')
    this.label.classList.add('label')
    this.element.appendChild(this.label)

    if (this.options.label !== false) {
      this.setLabel()
    }
  }

  buildInput () {
    this.input = document.createElement('select')
    this.input.classList.add('input')
    // this.input.setAttribute('type', 'text')
    this.element.appendChild(this.input)

    // if (!this.options.value) {
    //   this.element.classList.add('is-empty')
    // }

    if (this.readonly) {
      this.input.setAttribute('readonly', 'readonly')
      this.input.setAttribute('tabindex', '-1')
    }

    return this.input
  }

  buildCountry (component) {
    // console.log('buildCountry', country)

    this.add('', '')
    for (let i = 0; i < countries.length; i++) {
      this.add(countries[i][0], countries[i][1])
    }
  }

  set (prop, value) {
    switch (prop) {
      case 'value':
        this.setValue(value)
        break
      case 'label':
        this.setLabel(value)
        break
      case 'disabled':
        if (value === true) {
          this.disable()
        } else if (value === false) {
          this.enable()
        }
        break
      default:
        this.setValue(prop)
    }

    return this
  }

  setLabel (label) {
    label = label || this.options.label
    let text

    if (label === null || label === false) {
      text = ''
    } else if (this.options.label) {
      text = label
    } else {
      text = this.options.name
    }

    this.label.textContent = text
  }

  disable () {
    this.disabled = true

    this.input.setAttribute('disabled', 'disabled')
    this.element.classList.add('is-disabled')
    return this
  }

  enable () {
    this.disabled = false

    this.element.input.removeAttribute('disabled')
    this.element.classList.remove('is-disabled')
    return this
  }

  get (prop) {
    let value

    switch (prop) {
      case 'value':
        value = this.getValue()
        break
      case 'name':
        value = this.name
        break
      default:
        return this.getValue()
    }

    return value
  }

  getValue () {
    // console.log('getValue', typeof this.input.value, this.input.value)

    return this.input.value
  }

  setValue (value, silent) {
    this.input.value = value

    if (value) {
      this.element.classList.remove('is-empty')
    } else {
      this.element.classList.add('is-empty')
    }
  }

  _onChange (ev) {
    // console.log('change', ev)

    this.emit('change', ev.target.value)
  }

  _initValue () {
    const opts = this.options

    // create a new div as input element
    if (opts.value) {
      this.setValue(opts.value)
    }
  }

  _handleInputKeyPress (e) {
    // console.log('_handleInputKeyPress', e);

    if (this.get('value') === '') {
      this.element.classList.add('is-empty')
    } else {
      this.element.classList.remove('is-empty')
    }

    this.emit('change', this.getValue())
  }

  add (key, value, selected) {
    selected = selected || false
    const select = this.input
    select.options[select.options.length] = new Option(value, key, selected, selected)
  }

  focus () {
    if (this.disabled === true) return this

    this.element.classList.add('is-focused')
    if (this.input !== document.activeElement) { this.input.focus() }
    return this
  }

  blur () {
    this.element.classList.remove('is-focused')
    return this
  }

  setError (error) {
    if (error) {
      this.addClass('field-error')
      if (this.error) { this.error.set('html', error) }
    } else {
      if (this.error) { this.removeClass('field-error') }
      if (this.error) { this.error.set('html', '') }
    }
  }
}

export default Country

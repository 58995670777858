import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'
import dataset from 'material/src/module/dataset'
import attributes from 'material/src/module/attributes'
import flatpickr from 'flatpickr'

const defaults = {
  class: 'textfield',
  attributes: ['type', 'name', 'title', 'maxlength', 'pattern', 'min', 'max', 'placeholder', 'readonly', 'autocomplete', 'required', 'disabled'],
  events: [
    ['input.input', 'onInput'],
    ['input.focus', 'onFocus'],
    ['input.blur', 'onBlur'],
    ['input.click', 'onClick']
  ]
}

class DatePicker {
  static isComponent () {
    return true
  }

  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach)
    // console.log('options', options)

    this.build()
    this.attach()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    const tag = this.options.tag || 'div'

    this.element = document.createElement(tag)
    this.element.classList.add('textfield')

    if (this.options.class !== 'textfield') {
      this.element.classList.add(this.options.class)
    }

    this.buildLabel()
    this.buildInput()

    if (this.options.value) {
      this.set(this.options.value)
    }

    if (this.options.data) {
      dataset(this.element, this.options.data)
    }

    if (this.container) {
      this.container.appendChild(this.element)
    }

    // console.log('init DatePicker', this.input)

    this.instance = flatpickr(this.input, {
      enableTime: true,
      dateFormat: 'Y-m-dTH:i:S\\Z',
      altInput: true,
      altFormat: 'D d/m/Y H:i'
    })

    return this
  }

  buildLabel () {
    if (this.options.label) {
      this.label = document.createElement('label')
      this.label.classList.add('label')
      this.label.innerHTML = this.options.label
      this.element.appendChild(this.label)
    }
  }

  buildInput () {
    let tag = 'input'
    if (this.options.type === 'multiline') {
      tag = 'textarea'
    }
    this.input = document.createElement(tag)
    this.input.classList.add('input')
    this.element.appendChild(this.input)

    attributes(this.input, this.options)

    if (this.options.focus) {
      this.input.focus()
    }
  }

  onInput (ev) {
    console.log('onInput', this.value, this.input.value)

    this.emit('change', ev)
  }

  onFocus (ev) {
    this.element.classList.add('focused')
    this.emit('focus', ev)
  }

  onBlur (ev) {
    this.element.classList.remove('focused')
    this.emit('blur', ev)
  }

  onClick (ev) {
    // console.log('click')
    this.emit('click', ev)
  }

  /**
   * Setter
   * @param {string} prop
   * @param {string} value
   * @return {Object} The class instance
   */
  set (prop, value) {
    // console.log('set', this.element, prop, value)
    switch (prop) {
      case 'value':
        this.setValue(value)
        break
      case 'label':
        this.setLabel(value)
        break
      default:
        // console.log('prop', prop)
        this.setValue(prop)
    }

    return this
  }

  setValue (value) {
    console.trace('setValue', value)

    if (value && value !== 'undefined') {
      this.value = value
      this.instance.setDate(new Date(value))
    } else {
      this.instance.clear()
    }
  }

  setLabel (value) {
    // console.log('setLabel', value)
    if (this.label) {
      this.label.innerHTML = value
    }
  }

  setText (value) {
    this.setLabel(value)

    if (this.options.placeholder) {
      this.input.placeholder = value
    }
  }

  hide () {
    this.element.classList.add('hide')
  }

  show () {
    this.element.classList.remove('hide')
  }

  get () {
    return this.input.value
  }
}

export default DatePicker

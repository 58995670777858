'use strict'

const defaults = {
  class: 'boolean',
  tag: 'span'
}

class Bool {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    // console.log('options', options)

    this.build()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement(this.options.tag)
    this.element.classList.add(this.options.class)

    if (this.options.class !== 'boolean') {
      this.element.classList.add('boolean')
    }

    if (this.container) {
      this.container.appendChild(this.element)
    }

    return this
  }

  set (value) {
    if (value) {
      this.element.classList.remove('false')
    } else {
      this.element.classList.remove('true')
    }

    this.element.classList.add(value)
  }
}

export default Bool

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Element from 'material/src/element'
import Button from 'material/src/button'
import Textfield from 'material/src/textfield'
import Text from 'material/src/text'
// import Select from 'material/src/select'
import Country from '../control/country'
// import File from '../control/file'
import Moods from '../control/moods'
import Islands from '../control/islands'
import Year from '../field/year'
import File from '../field/file'
import Upload from '../control/upload'
import Switch from 'material/src/switch'
import Images from '../field/images'

import build from 'material/src/form/build'
import display from 'material/src/mixin/display'
import controller from 'material/src/form/controller'
import set from 'material/src/form/set'
import render from 'material/src/form/render'
import cancel from 'material/src/form/cancel'
import create from 'material/src/form/create'

import iconForm from '../icon/form.svg'
import iconMeta from '../icon/meta.svg'

String.prototype.rtrim = function () {
  return this.replace(/((\s*\S+)*)\s*/, '$1')
}

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

const defaults = {
  class: 'track',
  sysinfo: ['_id', 'uuid'],
  action: '/track/',
  autocomplete: 'off',
  // debug: true,
  update: {
    method: 'put'
  },
  create: {
    method: 'post',
    info: {
      title: 'New Track'
    }
  },
  preFillFields: ['title', 'artist', 'album', 'year'],
  layout: [
    [Element, 'head', { class: 'head' },
      [Element, 'title', { class: 'title', text: 'Track' }],
      [Element, { class: 'divider' }],
      [Button, 'helper', { icon: iconMeta, style: 'action', size: 'small' }],
      [Button, 'form', { icon: iconForm, style: 'action', size: 'small' }]
    ],
    [Element, 'body', { class: 'body' },
      [Element, { class: 'group files' },
        [File, 'track', { class: 'track', label: 'Track Audio Upload*', name: 'track', accept: 'audio/x-m4a,audio/*' }],
        [File, 'cover', { class: 'cover', label: 'Image Cover', name: 'cover', accept: 'image/*' }]
      ],
      [Element, { class: 'group version' },
        [Images, 'images', { class: 'cover', name: 'cover', path: 'cover' }]
      ],
      [Element, { class: 'group' },
        [Textfield, 'info.title', { label: 'Title', required: true }],
        [Textfield, 'info.artist', { label: 'Artist', required: true }],
        [Element, { class: 'fieldset' },
          [Country, 'info.country', { label: 'Country', class: 'country', required: true }],
          [Year, 'info.year', { label: 'Year', class: 'year', additional: 2070, required: true }]
        ],
        [Moods, 'info.mood', { label: 'Moods' }]
      ],
      [Element, { class: 'group' },
        [Textfield, 'info.album', { label: 'Album' }],
        [Textfield, 'info.label', { label: 'Label' }],
        [Textfield, 'info.songwriter', { label: 'Author / Composer' }],
        [Textfield, 'info.info', { type: 'multiline', label: 'Info' }]
      ],
      [Element, { class: 'group' },
        [Switch, 'info.isIslandOnly', { label: 'Island Only' }],
        [Islands, 'info.islands', { label: 'Islands' }]
      ]
    ],
    [Element, 'system', { class: 'system' },
      [Element, { class: 'group' },
        [Text, 'info._id', { }],
        [Element, { class: 'divider' }],
        [Text, 'info.uuid', { }]
      ]
    ],
    [Element, 'foot', { class: 'foot' },
      // [Button, 'delete', { text: 'Delete', style: 'outline' }],
      [Element, { class: 'divider' }],
      [Button, 'cancel', { type: 'reset', text: 'Cancel', style: 'dense' }],
      [Button, 'submit', { type: 'submit', text: 'Apply', color: 'primary' }]
    ]
  ],
  events: [
    // form
    ['form.submit', 'submit'],
    ['form.reset', 'cancel'],
    ['ui.cover.change', 'coverChange'],
    ['ui.track.change', 'trackChange'],

    ['setted', 'updateImages'],

    // ui
    ['ui.form.click', 'toggleForm'],

    ['ui.helper.click', 'showHelper'],

    // mode
    ['mode', 'changeMode'],

    // action
    ['ui.new.click', 'create'],

    // set create
    ['cleaned', 'cleanFile']
  ]
}

class Form {
  setted (info) {
    console.log('setted', info)
  }

  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach,
      build, set, render, create, display, /* submit, update, */ cancel, controller
    )

    this.container = this.options.container
    this.info = this.options.info

    this.build()
    this.attach()

    this.initController()

    console.log('ui', this.ui)

    if (this.info) this.set(this.info)

    return this
  }

  submit (ev) {
    //  console.log('submit')
    ev.preventDefault()

    // console.log('ui', this.ui)

    const field = {}
    const file = {}

    const fields = ['artist', 'country', 'title', 'year', 'album', 'songwriter', 'label', 'info', 'mood', 'islands', 'isIslandOnly']

    for (let i = fields.length - 1; i >= 0; i--) {
      const name = fields[i]
      // console.log('field', name, typeof this.ui['info.' + name].get())
      field[name] = this.ui['info.' + name].get()
    }

    if (this.ui.track.input.files[0]) {
      file.track = this.ui.track.input.files[0]
    }

    if (this.ui.cover.input.files[0]) {
      file.cover = this.ui.cover.input.files[0]
    }

    // console.log('file, field', file, field)

    this.upload(file, field)

    return false
  }

  upload (file, field) {
    // console.log('update', file, field)

    let method = 'put'

    if (this.mode === 'create') {
      method = 'post'
    }

    var upload = new Upload({
      container: this.element,
      title: 'Uploading track...',
      method,
      data: {
        file,
        field,
        system: {
          id: this.info._id,
          uuid: this.info.uuid
        }
      }
    }).on('success', (resp) => {
      // console.log('success', resp)
      upload.destroy()
      this.updated(resp)
    }).on('error', (resp) => {
      // console.log('error', resp)
      upload.destroy()
      this.error(resp)
      this.cleanTrack()
    }).on('cancel', () => {
      upload.destroy()
    })
  }

  trackChange (e) {
    console.log('trackChange', this.mode, this.ui)
    this.ui.track.field.classList.add('ready')
    if (this.mode !== 'create') {
      this.setMode('update')
      return
    }

    const success = (metadata) => {
      console.log(metadata)
      this.preFillFromMeta(metadata)
    }
    const error = (e) => {
      console.log(e)
    }

    parse_audio_metadata(this.ui.track.input.files[0], success, error)
  }

  preFillFromMeta (metadata) {
    // console.log('preFillFromMeta', metadata)
    const list = this.options.preFillFields
    const cover = this.ui.cover.field

    for (let i = 0; i < list.length; i++) {
      // console.log('field', list[i])
      this.field[list[i]].set('')
      if (metadata[list[i]]) {
        metadata[list[i]].rtrim()
        this.field[list[i]].set(metadata[list[i]])
      }
    }

    if (metadata.picture) {
      // console.log('--- set cover', metadata.picture)
      cover.style.backgroundImage = 'url("' + window.URL.createObjectURL(metadata.picture) + '")'
    }
  }

  coverChange (e) {
    // console.log('coverChange', this.ui.cover.input.files[0])

    this.setMode('update')

    const input = this.ui.cover.input
    const field = this.ui.cover.field

    if (input && input) {
      const reader = new FileReader()

      if (field) {
        reader.onload = (e) => {
          field.style.backgroundImage = 'url("' + window.URL.createObjectURL(input.files[0]) + '")'
        }
      }

      reader.readAsDataURL(input.files[0])
    }
  }

  updateImages (info) {
    console.log('updateImages', info)
    this.ui.images.setInfo(info)
  }

  cleanFile () {
    // console.log('cleanFile', this.ui)

    // console.log('cover input', this.ui.cover.input.files)
    // console.log('track input', this.ui.track.input.files)

    this.ui.cover.input.value = ''
    this.ui.track.input.value = ''

    this.ui.cover.field.style.backgroundImage = ''
    this.ui.track.field.classList.remove('ready')
  }

  cleanTrack () {
    this.ui.track.input.value = ''
    this.ui.track.field.classList.remove('ready')
  }

  showHelper () {
    // console.log('showMeta')
    if (this.ui.helper.element.classList.contains('selected')) {
      this.ui.helper.element.classList.remove('selected')
    } else {
      this.ui.helper.element.classList.add('selected')
    }

    this.emit('helper', this.info)
  }

  updated (info) {
    // console.log('updated', info)
    if (this.mode === 'create') {
      this.emit('created', info)
      this.setMode('read')
    } else {
      this.emit('updated', info)
      this.setMode('read')
    }
  }

  error (err) {
    alert(err)
    console.log('error', err)
  }

  toggleForm () {
    this.emit('toggleForm')
  }
}

export default Form

import { format } from 'timeago.js'

const defaults = {
  class: 'date',
  tag: 'span'
}

/**
 * Class representing an image.
 *
 * @extends Date
 * @return {parent} The class instance
 * @example new Date({
 *   date: date,
 *   container: document.body
 * });
 */
class TimeAgo {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    this.build()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement(this.options.tag)
    this.element.classList.add(this.options.class)

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    return this
  }

  set (date) {
    // console.log('set', date)
    const d = new Date(date)
    const formatted = format(d)

    if (this.options.text) {
      this.element.innerHTML = this.options.text + ' ' + formatted
    } else {
      this.element.innerHTML = formatted
    }
  }
}

export default TimeAgo

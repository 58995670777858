import emitter from 'material/src/module/emitter'
import archipels from '../module/archipels'

import Categories from 'material/src/switcher'

const defaults = {
  class: 'islands-control',
  label: 'Islands',
  tag: 'div',
  mode: 'multiple'
}

class Islands {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    // console.log('----')
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter)

    this.selected = []
    this.build()
    this.setup()
    this.init()

    return this
  }

  /**
   * init method
   * @param  {Object} Options
   * @return {Object} Instance
   */
  init (options) {
    fetch('/island/list?&page=1&size=500').then((resp) => {
      return resp.json()
    }).then((data) => {
      this.data = data
      this.buildList()
      this.attach()
    })
  }

  orderData (data) {
    // console.log('data', data)

    const sorted = data.sort(function (a, b) {
      return a.name - b.name
    })

    this.sortedData = sorted

    // console.log('sorted', sorted)

    return sorted
  }

  build () {
    this.element = document.createElement(this.options.tag)
    this.element.classList.add(this.options.class)

    this.label = document.createElement('label')
    this.label.innerHTML = this.options.label
    this.element.appendChild(this.label)

    // this.selected = document.createElement('label')
    // this.element.appendChild(this.selected)

    this.category = document.createElement('div')
    this.category.classList.add('categories')
    this.element.appendChild(this.category)

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }
  }

  buildList () {
    this.list = document.createElement('ul')
    this.list.classList.add('list')
    this.element.appendChild(this.list)

    for (let i = 0; i < this.data.length; i++) {
      const island = document.createElement('li')
      island.classList.add('item')
      island.dataset.id = this.data[i]._id
      island.dataset.category = this.data[i].category

      const label = document.createElement('label')
      label.classList.add('item')
      label.innerHTML = this.data[i].name
      island.appendChild(label)

      // island.innerHTML = this.data[i].name
      this.list.appendChild(island)
    }
  }

  renderCategory (name) {
    // console.log('renderCategory', name)
    const list = this.list.childNodes
    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      // console.log('itme', item.dataset.category)
      item.classList.remove('show')
      if (item.dataset.category === name) {
        // console.log('itme', item.dataset.category)
        item.classList.add('show')
      }
    }
  }

  setup () {
    this.categories = new Categories({
      container: this.category
    }).on('change', (name) => {
      // console.log('change', name)
      this.renderCategory(name)
    })

    // console.log('setup')
    const list = []
    archipels((categories) => {
      // console.log('list', list)
      for (let i = 0; i < categories.length; i++) {
        list.push(categories[i].category)
      }

      this.categories.setOptions(list)
    })

    this.categories.selectByName('thematic')
  }

  attach () {
    this.element.addEventListener('click', (event) => {
      if (!event.target.dataset.id) return
      this.select(event.target)
    })
    this.label.addEventListener('click', (event) => {
      if (this.status === 'open') {
        this.element.classList.remove('open')
        this.status = 'close'
      } else {
        this.status = 'open'
        this.element.classList.add('open')
      }
    })
  }

  select (option) {
    if (this.options.mode === 'multiple') {
      this.multiple(option)
    } else {
      this.unique(option)
    }
  }

  unique (option) {
    if (!option.classList.contains('selected')) {
      const options = this.list.childNodes

      for (let i = 0; i < options.length; i++) {
        options[i].classList.remove('selected')
      }

      option.classList.add('selected')
      this.selected = [option.dataset.id]
      this.emit('change', this.selected)
    }
  }

  multiple (option) {
    if (option.classList.contains('selected')) {
      // if (this.selected.length === 1) return

      const id = this.selected.indexOf(option.dataset.id)
      this.selected.splice(id, 1)
      option.classList.remove('selected')
    } else {
      option.classList.add('selected')
      this.selected.push(option.dataset.id)
    }

    this.emit('change', this.selected)
  }

  set (ids) {
    // console.log('set', ids)
    ids = ids || []
    const data = this.data
    this.selected = []
    if (!data) return

    this.categories.preSelectByName(null)

    for (let i = 0; i < data.length; i++) {
      const id = data[i]._id
      // console.log('--', data[i])
      this.element.querySelector('[data-id="' + id + '"]').classList.remove('selected')

      if (ids.indexOf(id) !== -1) {
        this.selected.push(id)
        // console.log('push', id)
        this.element.querySelector('[data-id="' + id + '"]').classList.add('selected')
        this.categories.selectByName(data[i].category)
        this.categories.preSelectByName(data[i].category)
      }
    }
  }

  get () {
    // console.log('get', typeof this.selected, this.selected)

    this.selected = this.selected || []

    if (this.selected && this.selected.length > 0) {
      return this.selected
    } else {
      return []
    }
  }
}

export default Islands

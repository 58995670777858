import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Element from 'material/src/element'
import Button from 'material/src/button'
import Text from 'material/src/text'
import DateHour from 'material/src/datehour'
import Image from '../field/image'
import Email from '../field/email'
import Country from '../field/country'
import Search from 'material/src/search'
import Dialog from 'material/src/dialog'
// import Ranking from '../field/ranking'

import create from 'material/src/list/create'
import add from 'material/src/list/add'
import build from 'material/src/list/build'
import render from 'material/src/list/render'
import update from 'material/src/list/update'
import select from 'material/src/list/select'
import remove from 'material/src/list/remove'
import status from 'material/src/list/status'
import load from 'material/src/list/load'
import request from 'material/src/list/request'
import image from 'material/src/list/image'
import search from 'material/src/list/search'
import click from 'material/src/list/click'

import iconSearch from '../icon/search.svg'
import iconRemove from '../icon/remove.svg'
import iconAdd from '../icon/add.svg'

// import hotkeys from 'hotkeys-js'

const defaults = {
  class: 'users',
  tag: 'div',
  pagination: true,
  // debug: true,
  count: true,
  list: {
    size: 25,
    page: 1
  },
  loading: 'dynamic',
  virtual: true,
  store: true,
  route: {
    list: '/profile/list',
    search: '/user'
  },
  image: {
    format: 'thumb/'
  },
  fields: ['pseudonym', 'username', 'enabled', 'created'],
  layout: {
    main: [
      [Element, 'head', { class: 'head' },
        [Element, 'title', { class: 'title', text: 'List' }],
        [Element, 'divider', { class: 'divider' }],
        [Button, 'search', { icon: iconSearch, type: 'action' }]
      ],
      [Search, 'search-input', { minChar: 3 }],
      [Element, 'search-list', { class: 'body search' }],
      [Element, 'body', { class: 'body' }],
      [Element, 'foot', { class: 'foot' },
        [Element, 'count', { class: 'count' }]
        // [Divider, 'divider', { flex: 1 }],
        // [Button, 'load', { text: 'more...' }]
      ]
    ],
    item: [
      [Image, 'info.image', { format: 'thumb', class: 'image' }],
      [Element, 'content', { class: 'content' },
        [Element, 'info', { class: 'info' },
          [Text, 'info.pseudonym', { css: 'pseudonym' }],
          [Country, 'info.country', { css: 'country' }],
          // [Ranking, 'info.ranking', { css: 'ranking' }]
          [Email, 'info.email', { class: 'email', link: false }]

        ],
        [Element, 'info', { class: 'info' },
          // [TimeAgo, 'info.created', { css: 'date', text: 'Registered' }],
          [DateHour, 'info.created', { css: 'date' }],
          [Text, 'info.enabled', { css: 'enabled' }]
        ]
      ],
      [Element, 'action', { class: 'action' },
        [Button, 'add', { class: 'add', icon: iconAdd, style: 'action', data: { name: 'add' } }],
        [Button, 'remove', { class: 'remove', icon: iconRemove, style: 'action', data: { name: 'remove' } }]
      ]
    ]
  },
  events: [
    ['ui.search.click', 'toggleSearch'],
    ['ui.search-input.change', 'search'],
    ['ui.load.click', 'load'],
    ['ui.body.click', 'click'],
    ['ui.search-list.click', 'click'],

    ['add', 'addUser'],
    ['remove', 'confirmRemoveUser']
  ]
}

class List {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    // this.options = options

    Object.assign(this, emitter, attach, create, click, remove,
      build, request, render, update, add, load, select, status, image, search
    )

    this.init()

    return this
  }

  init () {
    // console.log('init')
    this.page = this.options.page

    this.build()
    this.attach()
    this.request()
  }

  selectSearch (e) {
    // console.log('selectSearch', e.target)

    if (e.target === e.currentTarget) return

    const target = '[data-id]'
    let el = e.target
    let i = 0

    while (el && !el.matches(target)) {
      if (i > 7) {
        el = null
        break
      }
      el = el.parentNode
      i++
    }

    if (el && el.dataset.id) {
      this.addUser(el.dataset.id)
    }
  }

  addUser (id) {
    // console.log('addUser', id, this.medal)
    if (!id || !this.medal) return

    fetch('/profile/medal', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify({
        medal_id: this.medal,
        profile_id: id
      })
    }).then(r => r.json()).then(data => {
      // console.log(data)
      if (data.error) {
        window.alert('Oops! The was a probleme when updating or creating this info. Error: ' + data.message)
      } else {
        // console.log('success', data)
        this.hideSearch()
      }
    })
  }

  confirmRemoveUser (id) {
    // console.log('removeUser', id)

    if (!id || !this.medal) return

    new Dialog({
      name: 'alert',
      content: 'Delete user medal ?',
      accept: { text: 'Delete', color: 'primary' },
      cancel: { text: 'Cancel', color: 'primary' }
    }).on('accepted', () => {
      this.removeUser(id)
    }).on('canceled', () => {
      // console.log('alert dialog canceled')
    }).insert(document.body).show()
  }

  removeUser (id) {
    fetch('/profile/medal', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'DELETE',
      body: JSON.stringify({
        medal_id: this.medal,
        profile_id: id
      })
    }).then(r => r.json()).then(data => {
      // console.log(data)
      if (data.error) {
        window.alert('Oops! The was a probleme when updating or creating this info. Error: ' + data.message)
      } else {
        // console.log('success', data)
        this.remove(id)
      }
    })
  }

  setRole (role) {
    this.role = role
  }

  params () {
    if (this.medal) {
      return 'medals=' + this.medal
    }
  }
}

export default List

function get (route, cb) {
  // console.log('get', id)
  fetch(route, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'GET'
  }).then((resp) => {
    // console.log('resp', resp)
    return resp.json()
  }).then((list) => {
    // console.log('list', list.length)
    if (cb) cb(list)
  })
}

export default get

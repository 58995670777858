import emitter from 'material/src/module/emitter'

const defaults = {
  class: 'moods',
  label: 'Moods',
  tag: 'div',
  mode: 'unique',
  list: ['SLOW', 'FAST', 'WEIRD']
}

class Moods {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter)

    this.moods = []

    this.build()
    this.attach()

    return this
  }

  build () {
    this.element = document.createElement(this.options.tag)
    this.element.classList.add(this.options.class)

    this.label = document.createElement('label')
    this.label.classList.add('label')
    this.label.innerHTML = this.options.label
    this.element.appendChild(this.label)

    this.buildList()

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }
  }

  buildList () {
    this.list = document.createElement('ul')
    this.element.appendChild(this.list)

    for (let i = 0; i < this.options.list.length; i++) {
      const mood = document.createElement('li')
      mood.classList.add('item')
      mood.dataset.mood = this.options.list[i]
      mood.innerHTML = this.options.list[i]
      this.list.appendChild(mood)
    }
  }

  attach () {
    this.element.addEventListener('click', (event) => {
      // console.log('select', event.target.dataset.mood)
      if (!event.target.dataset.mood) return
      this.select(event.target)
    })
  }

  select (option) {
    if (this.options.mode === 'multiple') {
      this.multiple(option)
    } else {
      this.unique(option)
    }
  }

  unique (option) {
    if (!option.classList.contains('selected')) {
      const options = this.list.childNodes

      for (let i = 0; i < options.length; i++) {
        options[i].classList.remove('selected')
      }

      option.classList.add('selected')
      this.moods = [option.dataset.mood]
      this.emit('change', this.moods)
    } else {
      if (this.options.allowUnselect) {
        option.classList.remove('selected')
        this.moods = []
        this.emit('change', this.moods)
      }
    }
  }

  multiple (option) {
    if (option.classList.contains('selected')) {
      if (this.moods.length === 1) return
      const i = this.moods.indexOf(option.dataset.mood)
      this.moods.splice(i, 1)
      option.classList.remove('selected')
    } else {
      option.classList.add('selected')
      this.moods.push(option.dataset.mood)
    }

    this.emit('change', this.moods)
  }

  set (arr) {
    arr = arr || []
    const list = this.options.list
    this.moods = []
    for (let i = 0; i < list.length; i++) {
      this.element.querySelector('[data-mood="' + list[i] + '"]').classList.remove('selected')
      if (arr.indexOf(list[i]) !== -1) {
        this.moods.push(list[i])
        this.element.querySelector('[data-mood="' + list[i] + '"]').classList.add('selected')
      }
    }
  }

  get () {
    return this.moods
  }
}

export default Moods

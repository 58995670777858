// import advancedFormat from 'dayjs/plugin/advancedFormat'
import dayjs from 'dayjs' // load on demand

const defaults = {
  class: 'date',
  tag: 'span',
  format: 'MMMM D, YYYY h:mm A'
}

/**
 * Class representing an image.
 *
 * @extends Date
 * @return {parent} The class instance
 * @example new Date({
 *   date: date,
 *   container: document.body
 * });
 */
class DateHour {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    // dayjs.extend(advancedFormat)

    this.build()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement(this.options.tag)
    this.element.classList.add(this.options.class)

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    return this
  }

  set (date) {
    // console.log('set', src)
    const d = new Date(date)

    const formatted = dayjs(d).format(this.options.format)
    this.element.innerHTML = formatted
  }
}

export default DateHour

// module
import emitter from 'material/src/module/emitter'
import mediator from 'material/src/module/mediator'
import dataset from 'material/src/module/dataset'
import display from 'material/src/mixin/display'
import asset from '../module/asset'

const defaults = {
  class: 'awards',
  title: 'Awards',
  tag: 'div',
  url: '/profile/medal'
}

class Awards {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    Object.assign(this, emitter, display)

    mediator.installTo(this)
    // this.subscribe('playerStart', this.set)
    this.id = null

    this.asset = asset.get()

    this.build()

    return this
  }

  build () {
    this.element = document.createElement('div')
    this.element.classList.add(this.options.class)
    this.element.classList.add('list')

    this.head = document.createElement('div')
    this.head.classList.add('head')
    this.element.appendChild(this.head)

    this.head.innerHTML = this.options.title

    this.body = document.createElement('div')
    this.body.classList.add('body')
    this.element.appendChild(this.body)

    if (this.options.data) {
      dataset(this.element, this.options.data)
    }

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }
  }

  set (id) {
    // console.log('set awards', typeof id, id)

    if (!id) return

    fetch(this.options.url + '?id=' + id, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET'
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((list) => {
      // console.log('list', list)
      this.render(list[0].medals)
    })
  }

  render (list) {
    console.log('render', list, this.asset)

    this.body.innerHTML = ''

    if (!list || list.length < 1) {
      return
    }

    for (let i = 0; i < list.length; i++) {
      // console.log('list', list[i].image)
      const info = document.createElement('div')

      info.classList.add('award')

      const image = document.createElement('img')
      image.src = this.asset.url + 'medal/' + list[i].filename
      image.alt = list[i].name

      info.appendChild(image)
      const name = document.createElement('span')
      name.classList.add('name')
      name.innerHTML = list[i].name
      info.appendChild(name)
      this.body.appendChild(info)
    }
  }
}
export default Awards

import Upload from './module/upload'

export default {
  file (files, list, field) {
    console.log('file', files, list, field)
    // var files = e.target.files

    this.uploadFile(files, list, field)
  },

  uploadFile (files, list, field, i) {
    console.log('uploadFile', files, list, field, i)

    i = i || 0

    if (i >= files.length) return

    var upload = new Upload({
      field,
      file: files[i],
      route: '/track/',
      method: 'post',
      container: this.ui.foot
    }).on('success', (info) => {
      console.log('success', info)
      upload.destroy()
      this.uploadSuccess(list, info)
      this.uploadFile(files, list, field, i)
    }).on('error', (file) => {
      this.uploadFile(files, list, field, i)
      console.log('there was an error uploading the track')
    })

    i++

    return this
  },

  uploadSuccess (list, info) {
    list.request()
  }
}

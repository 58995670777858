export default {

  setMap (cb) {
    // console.log('setMap', this.map)
    // object used to cache map by decade

    // fetch map for the corresponding decade
    const url = '/dist/map/2020.svg'
    // console.log(url)
    const self = this
    // console.log('load map', url)
    const req = new XMLHttpRequest()
    req.onload = function (resp) {
      const xml = this.responseXML

      if (!xml) return

      const svg = document.importNode(xml.documentElement, true)

      self.updateMap(svg)

      // execute callback
      if (cb) cb()
    }
    req.open('GET', url, true)
    req.send()
  },

  updateMap (svg) {
    // console.log('updateMap', svg)

    if (this.svg) { this.svg.parentNode.removeChild(this.svg) }

    L.svgOverlay(svg, this.svgBounds).addTo(this.map)

    this.svg = svg
  },

  getPosition (coord) {
    // console.log('getPosition', coord, this.bounds[1][1], this.bounds[1][0])
    // get x
    const x = (coord.lat + 180) * (this.bounds[1][1] / 360)
    // convert from degrees to radians
    const latRad = coord.lng * Math.PI / 180
    // get y value
    const mercN = Math.log(Math.tan((Math.PI / 4) + (latRad / 2)))
    const y = (this.bounds[1][0] / 2) - (this.bounds[1][0] * mercN / (2 * Math.PI))

    const position = [y, x]

    return position
  },

  getLatLng (coord) {
    // console.log('getLatLng', coord, this.bounds[1][1], this.bounds[1][0])

    const lat = (coord.lat / (this.bounds[1][0] / 180) - 90) / -1
    const lng = coord.lng / (this.bounds[1][1] / 360) - 180

    return {
      lat,
      lng
    }
  },

  onZoom () {
    this.islandsLayer.clearLayers()
    this.setIslandsMarker()
  },

  updatePosition () {
    // console.log('updatePosition')
    this.screenSize()

    this.offsetX = this.screen.width - this.inner.offsetWidth
    this.offsetY = this.screen.height - this.inner.offsetHeight

    // console.log('--', this.offsetX / 2 + 'px')

    this.inner.style.left = (this.offsetX / 2) + 'px'
  },

  getRatio () {
    if (!this.svg) return

    const size = this.size.x - (this.offset.x * 2)
    const width = this.svg.getBoundingClientRect().width

    return width / size
  },

  applyChanges () {
    // console.log('applyChanges', this.updateObject)
    const obj = this.updateObject

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // console.log(`${key}: ${obj[key]}`)
        this.updateChange(key, obj[key])
      }
    }

    this.updateObject = {}

    this.ui.cancel.disable()
    this.ui.apply.disable()
  },

  updateChange (id, info) {
    // console.log('updateChange', id, info)
    const formData = new FormData()

    // console.log('info', info)

    formData.append('id', id)
    formData.append('uuid', info.uuid)
    formData.append('enabled', info.enabled)
    formData.append('onmap', info.onmap)

    if (info.pos) {
      formData.append('pos.x', info.pos.x)
      formData.append('pos.y', info.pos.y)
    }

    if (info.size) {
      formData.append('size.x', info.size.x)
      formData.append('size.y', info.size.y)
    }

    fetch('/island/', {
      method: 'PUT',
      body: formData
    }).then((resp) => {
      return resp.json()
    }).then((info) => {
      // console.log('info', info)
      this.updateMarkerInfo(info)
      this.emit('updated', info)
    })
  },

  refresh () {
    // console.log('refresh')
    this.screenSize()

    if (this.map) this.map.invalidateSize()
  },

  screenSize () {
    // console.log('screensize', this.element.getBoundingClientRect())
    const info = this.element.getBoundingClientRect()

    const screen = {
      width: info.width,
      height: info.height
    }

    this.screen = screen

    // console.log('screen', screen)

    return screen
  }
}

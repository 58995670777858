import system from '../module/system'
import asset from '../module/asset'
import attach from 'material/src/module/attach'

const defaults = {
  class: 'image',
  tag: 'div',
  format: 'thumb',
  background: true,
  loading: 'lazy',
  events: [
    ['img.load', 'loaded']
  ]
}

/**
 * Class representing an image.
 *
 * @extends Image
 * @return {parent} The class instance
 * @example new Image({
 *   src: {
 *     filename: 'image.png',
 *     url: 'exmaple.com/image/'
 *     format: ['thumbs/', 'medium/', 'large/'],
 *   },
 *   container: document.body
 * });
 */
class Image {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    Object.assign(this, attach)

    this.init()
    this.build()
    this.attach()

    return this
  }

  init () {
    if (system.get('asset')) {
      this.asset = asset.get()
    }
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement(this.options.tag)
    this.element.classList.add(this.options.class)
    if (this.options.class !== 'image') {
      this.element.classList.add('image')
    }

    if (this.options.background === false) {
      this.img = document.createElement('img')
      this.element.appendChild(this.img)

      if (this.options.loading) {
        this.img.loading = this.options.loading
      }
    }

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    return this
  }

  loaded (e) {
    // console.log('loaded')
    this.img.classList.add('loaded')
  }

  set (src, props) {
    // console.log('set', src, props)
    if (!this.asset) {
      this.asset = asset.get()
    }

    if (!src || src === undefined) {
      // console.log('-> undefined')
      if (!this.options.background) {
        this.img.src = this.options.generic
      } else {
        if (this.options.generic) {
          this.element.style.backgroundImage = 'url(' + this.options.generic + ')'
        } else {
          this.element.style.backgroundImage = ''
        }
      }
    } else {
      let url = null
      this.element.style.backgroundImage = ''
      if (this.options.format) {
        url = this.asset.url + '/' + src.path + this.options.format + '/' + src.filename
      } else if (src.path) {
        url = this.asset.url + '/' + src.path + src.filename
      } else {
        // console.log(this.asset.url + src)
        url = this.asset.url + '/' + src
      }

      // console.log('url', url)

      url = url.replace('/island/icon/thumb', '/island/marker')

      if (!this.options.background) {
        this.img.classList.remove('loaded')
        this.img.src = url
      } else {
        this.element.style.backgroundImage = 'url(' + url + ')'
      }

      // if (src.color) {
      //   this.element.style.backgroundImage = 'url("/asset/' + src.path + 'medium/' + src.filename
      // }

      if (src.color) {
        // console.log('color', src.color)
        this.element.style.backgroundColor = src.color
      } else this.element.style.backgroundColor = ''
    }

    if (props && props.alt && this.img) {
      this.img.alt = props.alt
    }
  }
}

export default Image

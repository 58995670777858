const info = {}

const system = {
  set: (prop, value) => {
    info[prop] = value

    return info
  },
  get: (prop) => {
    if (info[prop]) {
      return info[prop]
    } else {
      return null
    }
  }
}

export default system


export default {
  init () {
    this.ui.cancel.disable()
    this.ui.apply.disable()

    this.initDrop()

    const self = this
  },

  initMap () {
    // console.log('initMap', this.options.size)
    window.L_DISABLE_3D = true

    const size = this.size = this.options.size
    const offset = this.offset = {
      x: 427,
      y: 432
    }

    this.screenSize()
    // this.updatePosition()

    // zoom
    const zoom = 0
    const minZoom = this.minZoom = 1.5
    const maxZoom = 5
    const zoomPosition = 'bottomright'

    this.map = new L.Map(this.ui.container, {
      crs: L.CRS.Simple,
      // renderer: L.canvas()
      center: [0, 0],
      zoom,
      minZoom,
      maxZoom,
      maxBoundsViscosity: 0.85,
      zoomControl: true,
      zoomAnimation: false,
      zoomDelta: 0.5,
      zoomSnap: 0.1
    })

    this.initBounds()
    this.initBackground()

    // console.log('size', this.size)

    this.map.fitBounds(this.bounds, {
      animate: false,
      duration: 0
    })

    this.map.setMaxBounds(this.bounds)

    // console.log('sertView', this.size.x / 2, this.size.y / 2)

    // this.map.setView(this.map.unproject([this.size.x / 2, this.size.y / 2]), zoom, {
    //   animate: false,
    //   duration: 0
    // })

    this.initGroups()

    this.setMap()
  },

  initGroups () {
    // console.log('initGroups')
    this.countryGroup = L.layerGroup()
    // this.countryLayer = L.layerGroup().addTo(this.map)
    this.islandGroup = L.layerGroup()

    if (this.options.country.label.display === true) {
      this.countryGroup.addTo(this.map)
    }

    if (this.options.island.marker.display === true) {
      this.islandGroup.addTo(this.map)
    }

    const groups = {
      Islands: this.islandGroup,
      Countries: this.countryGroup
    }

    L.control.layers(this.backgroundLayers, groups, {
      position: 'topright'
    }).addTo(this.map)
  },

  initBackground () {
    // console.log('initBackground')
    this.backgroundLayers = {}
    // console.log('initBackground', this.options.background)
    if (this.options.background.method === 'tiles') {
      this.initTileLayer(this.options.background.theme)
    } else {
      this.initImageLayer()
    }
  },

  initTilesLayer () {
    // console.log('initTileLayer', this.options.background.theme)
    const themes = this.options.bgthemes

    for (let i = 0; i < themes.length; i++) {
      this.initTileLayer(themes[i])
    }
  },

  initTileLayer () {
    // console.log('initTileLayer', this.options.background.theme)
    const themes = this.options.bgthemes
    let path = 'https://desk.radioo.ooo/theme/common/img/map/tiles/compressed/'

    if (this.darkmode) {
      path += 'dark/'
    }

    // console.log('path', path)

    for (let i = 0; i < themes.length; i++) {
      const theme = themes[i]
      const base = L.tileLayer(path + theme + '/{z}/{x}/{y}.png', {
        noWrap: true,
        bounds: this.bounds,
        crs: L.CRS.Simple,
        continuousWorld: false
      })

      this.backgroundLayers[theme] = base

      if (this.options.background.theme === theme) {
        base.addTo(this.map)
        this.backgroundLayer = base
      }
    }

    // console.log('initTileLayer', this.options.background.theme)
  },

  initBounds () {
    this.bounds = new L.LatLngBounds(
      this.map.unproject([0, this.size.y], 4),
      this.map.unproject([this.size.x, 0], 4)
    )

    this.svgBounds = new L.LatLngBounds(
      this.map.unproject([this.offset.x, this.offset.y], 4),
      this.map.unproject([this.size.x - this.offset.x, this.size.y - this.offset.y], 4)
    )
  },

  initZoom (zoomPosition) {
    // console.log('initZoom', zoomPosition)

    L.control.zoom({
      position: zoomPosition
    }).addTo(this.map)
  }

}

'use strict'

import { plan } from '../data/plan'

const defaults = {
  class: 'plan'
}

class Plan {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    this.build()

    return this
  }

  /**
   * Build Method
   * @return {Object} This class instance
   */
  build () {
    this.element = document.createElement(this.options.tag || 'span')
    this.element.classList.add(this.options.class)

    if (this.options.value) {
      this.set(this.options.value)
    }

    if (this.options.container) {
      this.options.container.appendChild(this.element)
    }

    return this
  }

  set (value) {
    // console.log('set', value)
    if (plan[value]) {
      this.element.innerHTML = plan[value].type + ' ' + plan[value].currency + ' <b>' + plan[value].amount + '</b>'
    } else {
      this.element.innerHTML = value
    }
  }
}

export default Plan

import Text from 'material/src/text'
import Button from 'material/src/button'

import Textfield from 'material/src/textfield'
import Element from 'material/src/element'

export default [
  [Element, 'head', { class: 'head' },
    [Text, 'title', { flex: 1, text: 'Login', color: 'primary' }],
    [Element, { class: 'divider' }]
  ],
  [Element, 'inner', { class: 'body' },
    [Textfield, 'email', { flex: 1, name: 'email', text: 'email' }],
    [Textfield, 'password', { flex: 1, type: 'password', name: 'password', text: 'password' }]
  ],
  [Element, 'message', { flex: 'none' },
    [Text, 'message', { flex: 1 }]
  ],
  [Element, 'foot', { class: 'foot' },
    [Element, { class: 'divider' }],
    [Button, 'submit', { flex: 'none', name: 'signin', type: 'raised', text: 'Sign In', color: 'primary' }]
  ]
]

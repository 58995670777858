import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import Element from 'material/src/element'

import Text from 'material/src/text'
import Link from 'material/src/link'
import DateHour from 'material/src/datehour'
import Button from 'material/src/button'
// import Ranking from '../field/ranking'
// import Icon from '../control/icon'
// import Switcher from '../control/switcher'

import Country from '../field/country'
import Plan from '../field/plan'
import Giftcards from '../form/giftcards'

import display from 'material/src/mixin/display'
import create from 'material/src/list/create'
import add from 'material/src/list/add'
import build from 'material/src/list/build'
import status from 'material/src/list/status'
import render from 'material/src/list/render'
import select from 'material/src/list/select'
// import filter from 'material/src/list/filter'
import load from 'material/src/list/load'
import request from 'material/src/list/request'
import image from 'material/src/list/image'
import click from 'material/src/list/click'

import iconAdd from '../icon/add.svg'
// import iconCancel from '../icon/cancel.svg'

// import hotkeys from 'hotkeys-js'

const defaults = {
  class: 'subscription',
  pagination: true,
  // debug: true,
  list: {
    size: 100,
    page: 1
  },
  count: true,
  loading: 'dynamic',
  virtual: true,
  store: true,
  route: {
    list: '/giftcard/list'
  },
  image: {
    format: 'thumb/'
  },
  fields: ['pseudonym', 'username', 'enabled', 'created'],
  layout: {
    main: [
      [Element, 'head', { class: 'head' },
        [Text, 'title', { class: 'title', text: 'Gift Cards' }],
        // [Button, 'filter', { class: 'filter', icon: iconFilter, size: 'small', style: 'action' }]
        [Button, 'add', { class: 'add', icon: iconAdd, size: 'small', style: 'action' }]
      ],
      // [Element, 'filter-input', { class: 'filter-input' },
      //   [Icon, { src: iconFilter }],
      //   [Switcher, 'info.status', { label: 'S', size: 'small', inline: true, list: ['active', 'canceled', 'deleted'] }],
      //   [Element, { class: 'divider' }],
      //   [Button, 'filter-clear', { class: 'clear', icon: iconCancel }]
      // ],
      [Element, 'body', { class: 'body' }],
      [Element, 'foot', { class: 'foot' },
        [Element, 'count', { class: 'count' }]
        // [Divider, 'divider', { flex: 1 }],
        // [Button, 'load', { text: 'more...' }]
      ]
    ],
    item: [
      [Element, 'group', { class: 'group datetype' },
        [Link, 'info.url', { class: 'link', target: '_blank' },
          [Text, 'info.code', { class: 'code' }]
        ],
        [DateHour, 'info.date', { class: 'date' }],
        [Text, 'info.redeemed', { class: 'status' }]
      ],
      [Element, 'user', { class: 'group user' },
        [Text, 'info.email', { class: 'user_id' }]
      ],
      [Element, 'group', { class: 'group amount' },
        [Country, 'info.country', { class: 'brand' }],
        [Plan, 'info.plan', { class: 'plan' }]
      ]
    ]
  },
  events: [
    ['ui.load.click', 'load'],
    ['ui.body.click', 'click'],
    ['ui.add.click', 'giftcards']
  ]
}

class List {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    // this.options = options

    Object.assign(this, emitter, attach, create, click,
      build, request, render, add, load, display, select, image, status // filter
    )

    this.init()

    return this
  }

  init () {
    // console.log('init')
    this.page = this.options.page

    this.build()
    this.attach()
    // this.initFilter()
    this.request()
  }

  set (info) {
    if (!info) return
    // console.log('set', info.user_id)

    this.user_id = info.user_id

    this.request()
  }

  setStatus (status) {
    this.status = status
  }

  giftcards () {
    // console.log('gift')

    if (this.ui.giftcards) return

    this.ui.giftcards = new Giftcards().on('success', () => {
      this.ui.giftcards = null
      this.request()
    }).on('close', () => {
      this.ui.giftcards = null
    })

    this.ui.giftcards.show()
  }

  params () {
    // console.log('info', this.user_id, this.type)
    let params = ''

    if (this.user_id) {
      params = 'user_id=' + this.user_id
    }

    if (this.type) {
      params = params + '&type=' + this.type
    }

    return params
  }
}

export default List

import observer from 'material/src/module/observer'
import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'
import touch from '../module/touch'
import asset from '../module/asset'

// view
import build from 'material/src/module/build'
import display from 'material/src/mixin/display'

import Element from 'material/src/element'
import Button from 'material/src/button'
import Selector from 'material/src/selector'
import Textfield from 'material/src/textfield'

import init from './init'
import api from './api'
import controller from './controller'
import marker from './marker'
import drop from './drop'
import library from './library'

// import Zoom from '../control/zoom'

import iconMap from '../icon/map.svg'

const defaults = {
  base: 'view',
  class: 'map',
  zoom: 0.5,
  url: '',
  size: {
    x: 8192,
    y: 6200
  },
  background: {
    method: 'tiles',
    theme: 'classic'
  },
  bgthemes: ['classic', 'vintage', 'waves'/*, 'legacy' */],
  country: {
    label: {
      display: true
    },
    color: 'colorized',
    focus: {
      method: 'fly',
      options: {
        animate: true,
        duration: 1.5
      }
    }
  },
  island: {
    marker: {
      display: true
    }
  },
  overlays: ['islands', 'countries'],
  layout: [
    [Element, 'head', { class: 'head' },
      [Element, 'title', { class: 'title', text: 'Island' }],
      [Element, 'name', { class: 'island', text: '' }],
      [Element, { class: 'divider' }],
      [Button, 'map', { class: 'selected', style: 'action', size: 'small', icon: iconMap }]
    ],
    [Element, 'body', { class: 'body' },
      [Selector, 'selector', { class: 'selector', modules: ['resizer', 'border'] }],
      [Element, 'container', { class: 'container' }]
    ],
    [Element, 'foot', { class: 'foot' },
      [Button, 'remove', { text: 'remove', style: 'outline', disabled: true }],
      [Element, { class: 'divider' }],
      [Element, 'group', { class: 'group coord', flex: 1, display: 'flex', direction: 'horizontal' },
        [Textfield, 'size.x', { flex: 1, label: 'Size width', disabled: true }],
        [Textfield, 'size.y', { flex: 1, label: 'height', disabled: true }],
        [Textfield, 'pos.x', { flex: 1, label: 'Position x', disabled: true }],
        [Textfield, 'pos.y', { flex: 1, label: 'y', disabled: true }]

      ],
      [Element, { class: 'divider' }],
      [Button, 'cancel', { type: 'reset', text: 'Cancel', enabled: false }],
      [Button, 'apply', { type: 'submit', text: 'Apply', color: 'primary', enabled: false }]
    ]
  ],
  events: [
    ['show', 'refresh'],
    ['ui.map.click', 'hide'],
    ['ui.selector.resize', 'onMarkerResize'],
    ['ui.cancel.click', 'cancelChanges'],
    ['ui.apply.click', 'applyChanges'],
    ['ui.remove.click', 'removeMarker']
  ]
}

class Map {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach, init, drop,
      build, controller, api, display, library, marker
    )

    this.asset = asset.get() || {}

    this.build()
    this.init()

    // this.initSelector()

    this.initLeaflet()
    this.attach()

    return this
  }
}

export default Map

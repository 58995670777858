import hotkeys from 'hotkeys-js'

export default {
  initKeys () {
    // hotkeys('f5', (event, handler) => {
    // // Prevent the default refresh event under WINDOWS system
    //   event.preventDefault()
    //   console.log('you pressed F5!')
    // })

    // hotkeys('ctrl+r, command+r', () => {
    //   console.log('stopped reload!')
    //   return false
    // })

    hotkeys('v', () => {
      // console.log('go down')
      this.active.command('v')
      return false
    })

    hotkeys('s', () => {
      // console.log('go down')
      this.active.command('s')
      return false
    })

    hotkeys('d', () => {
      // console.log('go down')
      this.active.command('d')
      return false
    })

    hotkeys('c', () => {
      // console.log('go down')
      this.active.command('c')
      return false
    })

    hotkeys('down', () => {
      // console.log('go down')
      this.active.command('down')
      return false
    })

    hotkeys('up', () => {
      // console.log('go down')
      this.active.command('up')
      return false
    })

    hotkeys('backspace', () => {
      // console.log('backspace')
      this.active.command('delete')
      return false
    })

    hotkeys('enter', () => {
      // console.log('go down')
      this.active.command('enter')
      return false
    })

    hotkeys('left', () => {
      // console.log('go down')
      this.ui.player.backward(5)
      return false
    })

    hotkeys('right', () => {
      // console.log('go down')
      this.ui.player.forward(5)
      return false
    })

    hotkeys('shift+down', () => {
      // console.log('shift+down')
      this.active.nextSection()
      return false
    })

    hotkeys('shift+up', () => {
      // console.log('shift+down')
      this.active.previousSection()
      return false
    })

    hotkeys('shift+right', () => {
      // console.log('shift+down')
      this.active.command('nextView')
      return false
    })

    hotkeys('space', () => {
      // console.log('space')

      this.ui.player.toggle()
      return false
    })
  }
}

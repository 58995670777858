import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'

import mediator from 'material/src/module/mediator'

// app related method
import context from '../app/context'
import display from '../app/display'
import build from '../app/build'

import Dialog from 'material/src/dialog'
import Helper from './helper'
import Element from 'material/src/element'
import Button from 'material/src/button'
import Text from 'material/src/text'

import layout from './layout'
import events from './events'

const defaults = {
  class: 'tracks',
  tag: 'div',
  routes: [
    'onair',
    'posted',
    'rework',
    'validated',
    'uploaded',
    'confirmation',
    'duplicate',
    'rejected',
    'broken',
    'salvaged',
    'correction',
    'allduplicates',
    'deleted',
    'listties',
    'island'
  ],
  status: {
    edit: ['rework', 'validated', 'uploaded', 'correction', 'listties']
  },
  route: '/track/',
  layout,
  events

}

/**
 * Class representing a login dialog
 *
 * @return {parent} The class instance
 * @example new Login({
 *   container: document.body
 * });
 */
class Tracks {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    this.options = Object.assign({}, defaults, options || {})

    Object.assign(this, attach, emitter, context, build, display)
    mediator.installTo(this)

    this.build()
    this.setup()
    this.attach()

    // console.log(map)

    return this
  }

  setup () {
    // get count

    this.getCount()

    this.getContext()

    console.log('context selected', this.context.selected)

    if (this.context.selected) {
      this.ui.list.id = this.context.selected
    }

    if (this.context.navi) {
      this.selectNavi(this.context.navi)
    }
  }

  selectTrack (id) {
    // console.log('selectItem', item)
    this.publish('play', id, this.ui.list)

    this.view = 'list'

    this.setContext('selected', id)
  }

  onNaviSelect (e) {
    this.hideHelper()
    // console.log('naviSelet', e.target)
    if (e.target.matches('BUTTON')) {
      const name = e.target.name
      // console.log('name')
      this.selectNavi(name)
    }
  }

  selectNavi (name) {
    // console.log('selectNavi', name)
    this.section = name

    if (!this.ui[name]) return

    if (this.ui[name].element.classList.contains('selected')) {
      this.ui[name].element.blur()
      this.selectedMenu.classList.remove('selected')
      name = 'all'
      this.ui.list.ui.title.innerHTML = 'All Tracks'
    } else {
      if (this.selectedMenu) {
        this.selectedMenu.classList.remove('selected')
        this.ui.list.element.classList.remove(this.selectedMenu.name)
      }

      this.selectedMenu = this.ui[name].element

      this.selectedMenu.classList.add('selected')
      this.ui.list.ui.title.innerHTML = this.ui[name].element.title
    }

    this.updateList(name)
  }

  uploadDone (info) {
    // console.log('uploadDone', info)
    this.ui.list.request()
    this.getCount()
  }

  setUploaded () {
    this.selectNavi('uploaded')
  }

  updateList (name) {
    this.ui.list.element.classList.add(name)
    const route = '/track/'

    this.navi = name

    this.setContext('navi', name)

    if (name === 'all') {
      this.ui.list.setStatus('')
    } else {
      this.ui.list.setStatus(name)
    }

    // console.log('route', route)

    if (this.options.status.edit.includes(name)) {
      this.ui.form.element.classList.remove('hide')
      this.ui.list.ui.form.element.classList.add('hide')
    } else {
      this.ui.form.element.classList.add('hide')
      this.ui.list.ui.form.element.classList.remove('hide')
    }

    this.ui.list.page = 1

    // this.ui.list.body.innerHTML = ''
    // this.ui.list.hideSearch()
    // this.ui.list.options.route.list = route

    if (this.ui.list.mode === 'search') {
      this.ui.list.search(this.ui.list.ui['search-input'].input.value)
    } else {
      this.ui.list.request()
    }
  }

  cancelForm () {
    // console.log('cancelForm')
    if (this.ui.form.mode === 'create') {
      // console.log('form mode create')
      this.ui.list.createCancel()
    }
  }

  getCount (name, route) {
    // console.log('setCountRoute', name, route)

    fetch('track/count/', {
      headers: {
        Accept: 'application/json'
      },
      method: 'GET'
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((data) => {
      // console.log('count', data)
      this.count = data
      this.updateCount()
    })
  }

  updateCount () {
    const routes = this.options.routes

    for (let i = 0; i < routes.length; i++) {
      this.updateNaviCount(routes[i], this.count[routes[i]])
    }

    // get count for correction

    fetch('track/list?status=correction&count=1', {
      headers: {
        Accept: 'application/json'
      },
      method: 'GET'
    }).then((resp) => {
      // console.log('resp', resp)
      return resp.json()
    }).then((data) => {
      // console.log('count', data)
      this.updateNaviCount('correction', data.count)
    })
  }

  updateNaviCount (name, count) {
    // console.log('count', name, count, typeof count, this.ui[name])

    if (!this.ui[name]) return

    if (count === undefined) {
      count = 0
    }

    let info = this.ui[name].element.querySelector('.count')

    if (!info) {
      info = document.createElement('span')
      info.classList.add('count')
    }

    info.innerHTML = count
    if (this.ui[name].element.appendChild) {
      this.ui[name].element.appendChild(info)
    }
  }

  updateForm (info) {
    this.ui.form.set(info._id)
  }

  updateStatusCount (status) {
    // console.log('updateStatusCount', status)

    let selectedCount = this.selectedMenu.querySelector('.count').innerHTML || 0
    selectedCount--
    // console.log('count selected', selectedCount)

    this.selectedMenu.querySelector('.count').innerHTML = selectedCount

    const container = this.ui[status].element.querySelector('.count')
    // console.log('count', container)
    let count = container.innerHTML
    count++
    container.innerHTML = count
  }

  onair (id) {
    // console.log('validate', id)
    this.updateStatus(id, 'onair')
  }

  inbox (id) {
    // console.log('inbox', id)
    this.updateStatus(id, 'posted')
  }

  validate (id) {
    // console.log('validate', id)
    this.updateStatus(id, 'validated')
  }

  reject (id) {
    // console.log('delete', id)
    this.updateStatus(id, 'rejected')
  }

  duplicate (id) {
    // console.log('validate', id)
    this.updateStatus(id, 'duplicate')
  }

  rework (id) {
    const list = this.ui.list
    const info = list.dataStore[id]

    if (this.section === 'onair') {
      const dialog = new Dialog({
        class: 'delete',
        container: document.body,
        content: 'Rework ' + info.title + ' ?',
        display: 'show',
        layout: [
          [Element, 'head', { class: 'head' },
            [Text, 'title', { class: 'title' }],
            [Button, 'close', { class: 'close' }]
          ],
          [Element, 'body', { class: 'body' },
            [Text, 'content', { class: 'content' }]
          ],
          [Element, 'foot', { class: 'foot' },
            [Button, 'cancel', { class: 'cancel', text: 'Cancel' }],
            [Element, { class: 'divider' }],
            [Button, 'ok', { class: 'ok', text: 'Rework', color: 'primary' }]

          ]
        ]
      }).on('ok', () => {
        this.updateStatus(id, 'rework')
      }).on('cancel', () => {
        dialog.close()
      })
    } else {
      this.updateStatus(id, 'rework')
    }
  }

  broken (id) {
    // console.log('validate', id)
    this.updateStatus(id, 'broken')
  }

  correction (id) {
    // console.log('validate', id)
    this.updateStatus(id, 'correction')
  }

  delete (id) {
    // console.log('delete', id, this.section)

    const info = this.ui.list.dataStore[id]

    if (this.section === 'duplicate' || this.section === 'rejected') {
      this.updateStatus(id, 'deleted')
    } else {
      const dialog = new Dialog({
        class: 'delete',
        container: document.body,
        content: 'Delete ' + info.title + '?',
        display: 'show',
        layout: [
          [Element, 'head', { class: 'head' },
            [Text, 'title', { class: 'title' }],
            [Button, 'close', { class: 'close' }]
          ],
          [Element, 'body', { class: 'body' },
            [Text, 'content', { class: 'content' }]
          ],
          [Element, 'foot', { class: 'foot' },
            [Button, 'cancel', { class: 'cancel', text: 'Cancel' }],
            [Element, { class: 'divider' }],
            [Button, 'ok', { class: 'delete', text: 'Delete', color: 'primary' }]
          ]
        ]
      }).on('ok', () => {
        this.updateStatus(id, 'deleted')
      }).on('cancel', () => {
        dialog.close()
      })
    }
  }

  confirmation (id) {
    // console.log('confirmation', id)
    this.updateStatus(id, 'confirmation')
  }

  download (id) {
    window.location = '/track/download/' + id
  }

  updateStatus (id, status) {
    this.ui.list.selectById(id, true)
    this.next()

    this.ui.list.remove(id)

    fetch('/track/status', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify({
        id,
        status
      })
    }).then((resp) => {
      return resp.json()
    }).then((data) => {
      this.updateStatusCount(status)
    })
  }

  updateCover (item) {
    // console.log('updateCover', item)
    const image = item.querySelector('.image')
    // console.log('image', image.style)
  }

  command (name) {
    // console.log('command', name)
    if (this.view === 'list') {
      this.dispatchCommand(name)
    }

    if (name === 'nextView') {
      this.view = 'list'
    }
  }

  dispatchCommand (name) {
    // console.log('commandList', name)
    if (name === 'down') {
      this.next()
    }

    if (name === 'up') {
      this.previous()
    }

    if ((name === 'delete' || name === 's') && (this.section === 'posted' || this.section === 'validated')) {
      this.reject(this.ui.list.id)
    } else if (name === 'delete' || name === 's') {
      this.delete(this.ui.list.id)
    }

    if ((name === 'enter' || name === 'v') && this.section === 'posted') {
      this.validate(this.ui.list.id)
    } else if ((name === 'enter' || name === 'v') && this.section === 'validated') {
      this.rework(this.ui.list.id)
    }

    if (name === 'c' && (this.section === 'posted' || this.section === 'validated')) {
      this.confirmation(this.ui.list.id)
    }

    if (name === 'd' && (this.section === 'posted' || this.section === 'validated')) {
      this.duplicate(this.ui.list.id)
    }
  }

  nextSection () {
    // console.log('next section', this.selectedMenu)
    const next = this.selectedMenu.nextSibling

    if (next) {
      this.selectNavi(next.name)
    }
  }

  previousSection () {
    // console.log('previous section', this.selectedMenu)
    const section = this.selectedMenu.previousSibling

    if (section) {
      this.selectNavi(section.name)
    }
  }

  next () {
    // console.log('next')
    if (this.ui.list.selectNext() === false) {
      this.ui.list.selectPrevious()
    }
    // this.publishthis.ui.list.selectNext()
  }

  previous () {
    // console.log('previous')
    this.ui.list.selectPrevious()
  }

  toggleForm () {
    if (this.ui.form.element.classList.contains('hide')) {
      this.ui.form.element.classList.remove('hide')
      this.ui.list.ui.form.element.classList.add('hide')
    } else {
      this.ui.form.element.classList.add('hide')
      this.ui.list.ui.form.element.classList.remove('hide')
    }
  }

  setHelper (id) {
    // console.log('setHelper', id)
    if (this.ui.helper) {
      this.ui.helper.set(id)
    }
  }

  showHelper (info) {
    // console.log('showMeta', info)
    if (!this.ui.helper) {
      this.ui.helper = new Helper({
        container: this.element,
        id: info._id
      }).on('close', () => {
        this.hideHelper()
      })

      // this.helper.set(info)
      // console.log('metalist', this.helper)
      this.ui.list.element.classList.add('helper')
      this.ui.list.element.parentNode.insertBefore(this.ui.helper.element, this.ui.list.element.nextSibling)
      // this.ui.list.hide()
    } else {
      this.hideHelper()
    }
  }

  hideHelper () {
    if (this.ui.helper) {
      this.ui.form.ui.helper.element.classList.remove('selected')
      this.ui.helper.destroy()
      this.ui.helper = null
      this.ui.list.element.classList.remove('helper')
    }
  }
}

export default Tracks

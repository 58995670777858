export default {
  initEvents () {
    this.ui.container.addEventListener('click', (e) => {
      // console.log('click', e.target)

      if (e.target.classList.contains('container')) {
        if (this.dragging === null) this.unSelectMarker()
      }
    })

    this.map.on('overlayadd', (e) => {
      console.log('overlay add', e.name.toLowerCase())
      const name = e.name.toLowerCase()

      // if (name === 'countries') {
      //   this.publish('setting.change', ['info.map.country.label.display', true])
      //   this.options.country.label.display = true
      //   this.updateViewport('setting')
      // } else if (name === 'islands') {
      //   this.options.island.marker.display = true
      //   this.publish('setting.change', ['info.map.island.marker.display', true])
      //   this.setIslandsMarker(this.getRatio())
      // }
    })

    this.map.on('overlayremove', (e) => {
      console.log('overlay remove', e.name)
      const name = e.name.toLowerCase()

      // if (name === 'countries') {
      //   this.options.country.label.display = false
      //   this.publish('setting.change', ['info.map.country.label.display', false])
      // } else if (name === 'islands') {
      //   this.options.island.marker.display = false
      //   this.publish('setting.change', ['info.map.island.marker.display', false])
      // }
    })

    this.map.on('zoomend', () => {

    })

    this.map.on('predrag', (e) => {
      // console.log('movestart', e.target)
      this.dragging = true
      this.element.classList.add('dragging')
    })

    this.map.on('movestart', (e) => {
      // console.log('movestart', e.target)
      this.dragging = true
      this.element.classList.add('dragging')
    })

    this.map.on('zoom', (e) => {
      this.ui.selector.update()
    })

    this.map.on('move', (e) => {
      this.ui.selector.update()
    })

    this.map.on('moveend', (e) => {
      clearTimeout(this.draggingTimeout)
      this.draggingTimeout = setTimeout(() => {
        this.dragging = null
        this.element.classList.remove('dragging')
      }, 50)
    })

    this.map.on('zoomend', () => {
      // this.onZoom()
    })
  },

  initMapEvents () {

  }
}

import emitter from 'material/src/module/emitter'
import request from 'material/src/module/request'

import Dialog from 'material/src/dialog'

import Layout from '../layout/index'
import layout from './layout'

class Login {
  static defaults = {
    class: 'login',
    tag: 'div'
  }

  constructor (options) {
    this.init(options)

    this.build()
    this.setup()
    this.attach()

    // console.log(map)

    return this
  }

  init (options) {
    this.options = Object.assign({}, Login.defaults, options || {})

    Object.assign(this, emitter)

    return this
  }

  build () {
    // console.log('build')
    this.element = document.createElement(this.options.tag)
    document.body.appendChild(this.element)
    this.element.classList.add('login')
    this.suface = document.createElement('div')
    this.suface.classList.add('surface')
    this.element.appendChild(this.suface)

    // console.log('show dialog')
    this.layout = new Layout(layout, this.suface)

    // this.submit.element.setAttribute('type', 'submit')
  }

  setup () {
    this.message = this.layout.get('message')

    this.email = this.layout.get('email')
    this.email.input.setAttribute('name', 'email')
    this.email.input.focus()

    this.password = this.layout.get('password')
    this.password.input.setAttribute('type', 'password')
    this.password.input.setAttribute('name', 'password')

    this.layout.get('submit').element.setAttribute('type', 'submit')

    // this.cancel = this.layout.get('cancel')
  }

  attach () {
    this.email.input.addEventListener('keypress', (e) => {
      const code = (e.keyCode ? e.keyCode : e.which)
      if (code === 13) this.login()
    })

    this.password.input.addEventListener('keypress', (e) => {
      const code = (e.keyCode ? e.keyCode : e.which)
      if (code === 13) this.login()
    })

    this.layout.get('submit').element.addEventListener('click', (ev) => {
      this.login()
    })

    // this.layout.get('close').element.addEventListener('click', (ev) => {
    //   ev.preventDefault()
    //   this.destroy()
    // })
  }

  error () {

  }

  async login () {
    console.log('login')

    const body = {
      email: this.email.input.value,
      password: this.password.input.value
    }

    const data = await request('/account/login', 'POST', body)
    // console.log('data user', data)
    if (data.user) {
      this.user = data.user
      this.emit('success', data.user)
      this.destroy()
    } else {
      this.message.set('Login error! Check your email or password')
    }
  }

  destroy () {
    document.body.removeChild(this.element)
    this.emit('destroy')
  }
}

export default Login

import cookie from '../module/cookie'

export default {
  setContext (name, value) {
    this.context[name] = value

    cookie.set('app-' + this.options.class, JSON.stringify(this.context), 360)
  },

  getContext () {
    // console.log('getContext', 'app-' + this.options.class)
    if (cookie.get('app-' + this.options.class)) {
      // console.log('context', cookie.get('app-' + this.options.class))
      try {
        this.context = JSON.parse(cookie.get('app-' + this.options.class))
      } catch (e) {
        this.context = {}
      }
    } else {
      this.context = {}
    }
  }
}

import emitter from 'material/src/module/emitter'
import attach from 'material/src/module/attach'
import mediator from 'material/src/module/mediator'

import Element from 'material/src/element'
import Text from 'material/src/text'
import Image from '../field/image'
import Like from '../field/like'
import Button from 'material/src/button'

import create from 'material/src/list/create'
import add from 'material/src/list/add'
import build from 'material/src/list/build'
import render from 'material/src/list/render'
import select from 'material/src/list/select'
import load from 'material/src/list/load'
import request from 'material/src/list/request'
import status from 'material/src/list/status'
// import request from 'material/src/list/request'
// import click from 'material/src/list/click'
import display from 'material/src/mixin/display'

// import hotkeys from 'hotkeys-js'

const defaults = {
  class: 'tracks',
  tag: 'div',
  pagination: true,
  type: 'track',
  list: {
    size: 100,
    page: 1
  },
  updatePosition: true,
  loading: 'dynamic',
  preventSelectAgain: true,
  item: {
    target: '[data-id]',
    height: 60,
    dataset: 'id'
  },
  virtual: true,
  store: true,
  route: {
    list: '/profile/likes/',
    count: '/user/count'
  },
  layout: {
    main: [
      [Element, 'body', { class: 'body' }],
      [Element, 'foot', { class: 'foot' },
        [Element, 'count', { class: 'count' }]
      ]
    ],
    item: [
      [Image, 'info.cover', { class: 'image', background: true, format: 'thumb', generic: '/img/generic/nocover.svg' },
        [Element, 'play', { class: 'play' }]
      ],
      [Element, 'content', { class: 'content' },
        [Element, 'info.title', { class: 'title' }],
        [Element, 'info.artist', { class: 'artist' }]
      ],
      [Element, { class: 'info' },
        [Element, 'info.year', { class: 'year' }],
        [Element, 'info.country', { class: 'country' }]
      ]
    ]
  },
  events: [
    ['ui.body.click', 'onClick'],
    ['select', 'play'],
    ['fetched', 'updateCount']
    // ['ui.body.click', 'select'],
    // ['select', 'play']
  ]
}

class List {
  /**
   * Constructor
   * @param  {Object} options - Component options
   * @return {Object} Class instance
   */
  constructor (options) {
    // console.log('options', options)
    this.options = Object.assign({}, defaults, options || {})
    Object.assign(this, emitter, attach, create,
      select, // click,
      build, request, render, add, load, display, status
    )

    mediator.installTo(this)

    this.page = this.options.page

    this.build()
    // this.setup()
    this.attach()

    return this
  }

  set (id) {
    // console.log('set', id)

    if (id) {
      this.id = id
    } else {
      // console.log('id null ')
      this.id = null
    }

    this.request()
  }

  onClick (e) {
    // console.log('play', e.target)
    e.preventDefault()

    const target = '[data-id]'
    let item = e.target
    let out = 0
    let like = null
    let control = null

    // like
    if (item.classList.contains('icon') || item.classList.contains('count')) {
      // console.log('like')
      like = item.parentNode
    }

    // control play / pause
    if (item.classList.contains('control')) {
      // console.log('like')
      control = item.parentNode
    }

    while (item && item.matches && !item.matches(target)) {
      if (out > 5) {
        item = null
        break
      }
      item = item.parentNode
      out++
    }

    if (!item || !item.dataset || !item.dataset.id) {
      return false
    }

    // console.log('play', el)

    if (like) {
      this.like(like, item.dataset.id)
    } else if (control) {
      if (item.classList.contains('selected')) {
        mediator.publish('playlistStop', this)
      } else {
        this.select(item)
        mediator.publish('playlistStart', this)
      }
    }
  }

  like (like, id) {
    // console.log('like/unlike', like, id)
    const icon = like.firstChild
    const count = icon.nextSibling
    let n = 0
    if (count.innerHTML !== '') {
      n = parseInt(count.innerHTML, 10)
    }
    // console.log('n', n)
    if (icon.classList.contains('liked')) {
      icon.classList.remove('liked')
      n = n - 1
      if (n === 0) n = ''
      count.innerHTML = n
      this.publish('unlike', id)
      this.dataStore[id].liked = 0
    } else {
      icon.classList.add('liked')
      n = n + 1
      count.innerHTML = n
      this.publish('like', id)
      this.dataStore[id].liked = 1
    }
    this.dataStore[id].likes = n
  }

  play (id) {
    // console.log('play', item)
    this.publish('play', id, this)
  }

  params () {
    // console.log('info', this.id)
    let params = ''

    if (this.id) {
      params = 'id=' + this.id
    }

    return params
  }
}

export default List
